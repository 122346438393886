<template>
    <div class="component message-history">
        <el-dialog
            :title="title"
            :visible="visible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            append-to-body
            custom-class="point-form-dialog"
            :destroy-on-close="true"
            width="900px"
            @close="close()">
        </el-dialog>

        <NotificationPopup @show-details="handleCallBack" ref="notificationPopupRef" />
    </div>
</template>

<script>
import vm from "@/plugin/eventUtil";
import NotificationPopup from "./NotificationPopup";

export default {
    name: "user-manage",
    components: { NotificationPopup },
    data () {
        return {
            title: '任务记录',
            visible: false,
        }
    },
    mounted() {
        vm.$on('socket-message', (data) => {

            const {bucket, regionName, statTime, key, regionId, type} = data

            let displayName = key.substr(0, key.length - 1)

            let dirList = displayName.split('/'), parent = '', keyword= ''

            if (dirList.length === 1) {
                keyword = displayName
            } else if (dirList.length > 1) {
                keyword = dirList[dirList.length - 1]
                parent = dirList.slice(0, dirList.length - 1).join('/')
            }


            let message = `${regionName}-${bucket}-${key}已完成统计`

            switch (type) {
                case 'FOLDER_STAT':
                    message = `${regionName}/${bucket}/${key}已完成统计`
                    break;
            }

            this.$refs.notificationPopupRef.showPopup(message, {...data})
        })
    },
    methods: {
        saveMessage () {

        },
        getMessage () {},
        handleCallBack(d) {
            const {type, bucket, key, regionId} = d
            let displayName = key.substr(0, key.length - 1)

            let dirList = displayName.split('/'), parent = '', keyword = ''

            if (dirList.length === 1) {
                keyword = displayName
            } else if (dirList.length > 1) {
                keyword = dirList[dirList.length - 1]
                parent = dirList.slice(0, dirList.length - 1).join('/')
            }
            switch (type) {
                case 'FOLDER_STAT':
                    this.$router.push({
                        name: 'File',
                        query: {
                            bucket,
                            keyword,
                            regionId,
                            parent
                        }
                    })
                    break;
            }
        }
    }

}
</script>

<style scoped>

</style>
