import Vue from 'vue';
import DropdownC from './Dropdown'
let DropdownConstructor = Vue.extend(DropdownC);

let instance = null

const Dropdown = {}

Dropdown.init = () => {
    if (instance) {
        return instance
    }
    instance = new DropdownConstructor({})
    instance.id = 'ehl-dropdown'
    instance.$mount();
    document.body.appendChild(instance.$el)
    return instance
}

Dropdown.toggle = (top, left, id, list) => {
    if (instance) {
        instance.toggle(top, left, id, list)
    }
}

export default Dropdown

