<template>
    <div class="set-tag">
        <div>
            <el-button class="addTagBtn" @click="showInput()" size="small" type="primary" v-if="!inputVisible">添加标签</el-button>
            <el-select class="addTagBtn" v-model="tags"  size="small"
                       filterable multiple allow-create default-first-option  collapse-tags
                       maxlength="10" placeholder="请输入" ref="createTag"
                       @change="handleChange"
                       v-if="inputVisible">
                <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"></el-option>
            </el-select>

            <el-tag size="small" class="tag-checked-item" v-for="(item, index) in tags" :key="index" closable @close="delTag(index)">
                {{ item }}
            </el-tag>
        </div>
        <div class="oftenTag">
            <span v-if="oftenTagList.length != 0">常用标签：</span>
            <el-tag
                size="small"
                v-for="(item, index) in oftenTagList"
                :key="index"
                style="cursor: pointer;"
                @click="addOftenTag(item)">
                {{ item }}
            </el-tag>
        </div>
    </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
    name: 'editTag',
    props: {
        tagList: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            inputVisible: false,
            tags: [],
            selectTag: [],
            options: [],
            oftenTagList: [],
        }
    },
    watch: {
        tagList(val) {
            if (this.tags.length === 0) {
                this.tags = val
            }
        },
    },
    created() {
        this.init()
    },
    methods: {
        init () {

            this.oftenTagList = []
            this.options = []
            this.tags = [...this.tagList]
            this.$emit('tagList', this.tags)
            this.getTagList()
        },

        reset (){
            this.tags = []
        },

        getTagList() {
            this.$postJson('/tag/list', {}).then((res) => {
                if (res.code === '200') {
                    res.data.forEach(item => {
                        if (item.commonUse === 'true') {
                            this.oftenTagList.push(item.name)
                        } else {
                            this.options.push(item.name)
                        }
                    })
                }
            })
        },
        showInput() {
            this.inputVisible = true
            this.$nextTick((_) => {
                this.$refs.createTag.$refs.input.focus()
            })
        },
        handleChange(val) {
            var value = val[val.length - 1]
            value = value != undefined ? value.replace(/\s*/g, '') : undefined
            if (value != undefined && !/^[0-9a-zA-Z\u4e00-\u9fa5]{1,10}$/.test(value)) {
                this.$message.error('标签为1-10个字符的中文、英文和数字组合。')
                val.pop()
                return
            } else {
                val[val.length - 1] = val[val.length - 1].replace(/\s*/g, '')
            }

            if (value != undefined && !this.options.includes(value)) {
                this.options.push(value)
            }
            setTimeout(() => {
                this.$refs.createTag.blur()
                this.inputVisible = false
            })
            this.$emit('tagList', this.tags)
        },
        delTag(index) {
            this.tags.splice(index, 1)
            this.$emit('tagList', this.tags)
        },
        addOftenTag(val) {
            if (this.tags.filter((item) => item == val).length == 0) {
                this.tags.push(val)
                this.$emit('tagList', this.tags)
            }
        },
    },
}
</script>

<style lang='scss' scoped>
.addTagBtn {
    width: 200px;
}

.oftenTag {
    margin-top: 10px;
}

.addTagBtn .el-tag--info {
    display: none !important;
}

.setTag .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
}

.setTag .el-tag--small {
    cursor: pointer;
}
</style>
