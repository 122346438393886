<template>
    <div class="error-page">
        <img class="img-error img-top-space" src="../assets/404_2.png" style="height: 219px;width: 300px;"/>
        <div class="error-msg">抱歉，您访问的页面不存在</div>
        <div class="btn-back" @click="$router.back(-1)">返回上一页</div>
    </div>
</template>
<script>
export default {
    name: 'NotFound'
}
</script>
<style lang="scss">
.error-page {
    width: 100%;
    height: 100%;
    background: white;
    text-align: center;
    z-index: 1;
}

.img-error {
    width: 390px;
    height: 167px;
}

.img-top-space {
    margin-top: 145px;
}

.error-msg {
    font-size: 18px;
    color: #333;
    font-weight: 600
}

.btn-back {
    width: 90px;
    height: 32px;
    margin: 20px auto;
    line-height: 32px;
    color: white;
    cursor: pointer;
    background: #337dff;
    border-radius: 2px;
}
</style>
