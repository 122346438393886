<template>
    <el-dialog title="使用设置" custom-class="set-up" :visible.sync="visible" append-to-body
               :close-on-click-modal="false" width="484px">
        <el-form :model="form" ref="form" @submit.native.prevent
                 v-loading="initLoading"
                 label-width="100px" style="">
            <el-form-item label="解冻完成提醒">
                <el-radio-group v-model="form.restoreRemindStatus" @change="handleStatusChange">
                    <el-radio label="1">打开</el-radio>
                    <el-radio label="2">关闭</el-radio>
                </el-radio-group>
                <p class="tips">说明：若关闭后，文件解冻完将无法收到短信或邮件提醒，建议开启。</p>
            </el-form-item>
            <el-form-item label="提醒方式" prop="mail" :rules="[{pattern: /^[a-zA-Z\d_]+@[a-zA-Z\d]+\.[a-zA-Z\d]{2,4}$/, message: '格式错误', trigger: 'blur'}]">
                <div class="setup-item">
                    <el-checkbox
                        :disabled="form.restoreRemindStatus !== '1'"
                        label="1"
                        v-model="emailCheck"
                        @change="handleEmailCheckChange"
                    >邮件提醒</el-checkbox>
                    <el-input style="margin-left: 12px;width: 184px;" :disabled="form.restoreRemindStatus !== '1' || !emailCheck" placeholder="请填写邮箱" v-model.trim="form.mail"/>
                </div>
                <p class="tips">将在文件解冻完成后，发送提醒至您的邮箱，收到邮件请尽快下载</p>
            </el-form-item>
            <el-form-item prop="phone" :rules="[{pattern: /^1[3-9]\d{9}$/, message: '格式错误', trigger: 'blur'}]">
                <div class="setup-item" >
                    <el-checkbox
                        :disabled="form.restoreRemindStatus !== '1'"
                        label="2"
                        v-model="phoneCheck"
                        @change="handlePhoneCheckChange"
                    >短信提醒</el-checkbox>
                    <el-input style="margin-left: 12px;width: 184px;"  :disabled="form.restoreRemindStatus !== '1' || !phoneCheck" placeholder="请填写手机号" v-model.trim="form.phone"/>
                </div>
                <p class="tips">打开后将在文件解冻完成后，发送短信提醒至您的手机，每个自然日最多发送10条短信，单日额度用完后将不会再发送短信，次日恢复</p>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleCancel" >取消</el-button>
            <el-button size="small" type="primary" :loading="loading" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import  {mapGetters} from 'vuex'
export default {
    name: "set-up",
    data() {
        return {
            visible: false,
            form: {
                restoreRemindStatus: '2',
                mail: '',
                phone: ''
            },
            initLoading: false,
            loading: false,
            emailCheck: false,
            phoneCheck: false,
            config: {}
        }
    },
    computed: {
        ...mapGetters(['userData']),
        accountId () {
            const {account} = this.userData
            if (account) {
                return account.accountId
            }
            return ''
        },
        defaultEmail () {
            return this.userData.mail || ''
        },
        defaultPhone () {
            return this.userData.phone || ''
        }
    },
    mounted() {
    },
    methods: {
        show() {
            this.visible = true
            this.getConfig()
        },
        getConfig () {
            this.initLoading = true
            this.$get('/accountUser/getCurrentAccountUser')
            .then(res => {
                if (res.code === '200') {
                    this.config = res.data
                    this.form.restoreRemindStatus = res.data.restoreRemindStatus || '2'
                    this.$store.commit('user/SET_USER_INFO', res.data)
                    if (res.data.restoreRemindStatus === '1') {
                        this.emailCheck = !!res.data.mail
                        this.phoneCheck = !!res.data.phone
                        this.emailCheck && (this.form.mail = res.data.mail || this.defaultEmail)
                        this.phoneCheck && (this.form.phone = res.data.phone || this.defaultPhone)
                    } else {
                        this.emailCheck = false
                        this.phoneCheck = false
                        this.form.phone = ''
                        this.form.mail = ''
                    }
                    this.$refs.form.clearValidate()
                }
            })
            .finally(() => {
                this.initLoading = false
            })
        },
        handleCancel () {
            this.visible = false
        },
        handleSubmit () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const {restoreRemindStatus, mail, phone} = this.form
                    const {accountId, emailCheck, phoneCheck} = this;
                    let data = {restoreRemindStatus, accountId}
                    if (restoreRemindStatus === '1') {
                        if (mail && emailCheck) {
                            data.mail = mail
                        }
                        if (emailCheck && !mail) {
                            this.$message.warning('请填写邮箱')
                            return
                        }
                        if (phone && phoneCheck) {
                            data.phone = phone
                        }
                        if (phoneCheck && !phone) {
                            this.$message.warning('请填写手机号')
                            return
                        }
                    }
                    this.$postJson('/accountUser/updateAccountUser', data)
                        .then(res => {
                            if (res.code === '200') {
                                this.$message.success('设置成功')
                                this.visible = false
                                this.getConfig();
                            }
                        })
                }
            })
        },

        handleEmailCheckChange (val) {
            if (val) {
                this.form.mail = this.defaultEmail
            } else {
                this.form.mail = ''
            }
        },
        handlePhoneCheckChange (val) {
            if (val) {
                this.form.phone = this.defaultPhone
            } else {
                this.form.phone = ''
            }
        },

        handleStatusChange (e) {
            if (e === '2') {
                this.form.phone = ''
                this.form.mail = ''
                this.emailCheck = false
                this.phoneCheck = false
                this.$refs.form.resetFields()
            } else {
                this.emailCheck = true
                this.form.mail = this.defaultEmail
                this.phoneCheck = true
                this.form.phone = this.defaultPhone
            }
        }
    }
}
</script>

<style scoped>
.setup-item {
    display: flex;
    flex-wrap: nowrap;
}

.tips {
    font-size: 12px;
    color: #666;
    line-height: 18px;
}
</style>