<template>
    <el-header>
        <div class="left"><i class="icon iconfont iconmenu_kongzhitai" style="position: relative;top:1px;"></i><span style="padding-left: 8px;">管理控制台</span></div>
        <div class="right" >
            <div class="right-name" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <div :class="`drop-down ${hiddenDrop ? 'hidden' : ''}`">
                    <div class="drop-down-content">
                        <div class="drop-down-content-item" @click="toUserInfo">用户中心</div>
                        <div class="drop-down-content-item" @click="showSetup">使用设置</div>
                        <div class="drop-down-content-item" @click="logout">退出账号</div>
                    </div>
                </div>
                <span>
                    <b></b>
                    <div class="ellipsis">
                        {{ userData.account ? userData.account.username : '' }}
                    </div>
                    <i :class="`el-icon-caret-${iconSwitch}`"/>
                </span>
            </div>
        </div>
        <Setup ref="setupRef"/>
    </el-header>
</template>
<script>
import {mapGetters} from "vuex";
import Cookie from 'js-cookie'
import Setup from "@/views/layout/Setup";
import vm from "@/plugin/eventUtil";
export default {
    name: "CsHeader",
    components: {Setup},
    data() {
        return {
            hiddenDrop: false,
            iconSwitch: 'bottom'
        };
    },
    computed: {
        ...mapGetters(["userData"]),
    },
    mounted() {
        vm.$on('show-setup', this.showSetup)
    },
    methods: {
        showSetup () {
            this.$refs.setupRef.show();
        },
        logout () {
            this.$confirm("确认退出吗?", "提示", {
                confirmButtonText: "退出",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    Cookie.remove('token');
                    window.location.href = `${window.location.origin}/logout/${window.location.hash}`;
                })
        },
        toUserInfo () {
            this.hiddenDrop = true
            setTimeout(() => {this.hiddenDrop = false}, 200)
            if (this.$route.path.indexOf('/info') === -1) {
                this.$router.push('/info')
            }
        },
        mouseenter () {
            this.iconSwitch = 'top'
        },
        mouseleave () {
            this.iconSwitch = 'bottom'
        }
    },
};
</script>
<style lang="scss" scoped>
$color: #434C6C;
.el-header {
    display: flex;
    justify-content: space-between;
    height: 54px!important;
    line-height:54px;
    background: $color;
    color: #fff;
    font-size: 14px;
    padding: 0 16px;
}

.right-name {
    position: relative;
    cursor: pointer;
    min-width: 100px;
    height: 54px;
    > span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1902;
        text-align: left;
        text-indent: 8px;
        background:$color ;
        padding-right: 12px;
        > b {
            position: absolute;
            width: 34px;
            left: -34px;
            top: 0;
            bottom: 0;
            background: url("../../assets/bluestore_20210315110626.png") no-repeat center center;
            background-size: 34px
        }
        > i {
            position: absolute;
            right: 0;
            height: 54px;
            line-height: 54px;
            top: 0;
        }
    }
}
.drop-down {
    position: absolute;
    background: $color;
    background-clip: content-box;
    padding-top: 4px;
    top: -100px;
    right: 0;
    width: 100px;
    font-size: 12px;
    text-align: center;
    z-index: 1901;
    transition: top 0.2s;
    .drop-down-content {
        padding: 6px 0;
        &-item {
            height: 24px;
            line-height: 24px;
        }
        &-item:hover {
            background: #363E5B;
        }
    }
}
.right-name:hover .drop-down {
    top: 54px;
}
.drop-down.hidden {
    top: -100px!important;
}

</style>
