import DropZone from "./DropZone";
import SpaceItem from "./SpaceItem";
import Pager from "./Pager";
import UploadTask from "./UploadTask";
import FileName from "./FileName";
import OptButton from "./OptButton";
import OptLink from "./OptLink";
import CMLink from "./CMLink";
import ColumnFilter from "./columnFilter/ColumnFilter";

const install = function(Vue, opts = {}) {
  Vue.prototype.$uploadTask = UploadTask;
  Vue.component(DropZone.name, DropZone);
  Vue.component(SpaceItem.name, SpaceItem);
  Vue.component(Pager.name, Pager);
  Vue.component(FileName.name, FileName);
  Vue.component(OptButton.name, OptButton);
  Vue.component(OptLink.name, OptLink);
  Vue.component(CMLink.name, CMLink);
  Vue.component(ColumnFilter.name, ColumnFilter);
};

/* istanbul ignore if */
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export default {
  version: "1.0.0",
  install,
  UploadTask,
};
