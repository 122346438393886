import service from '@/utils/request'

const state = {
    continuationToken: '',
    bucketName: '',
    prefix: '',
    hasNext: true,
    fileList: []
}

const filterByAttr = function (arr = [], key = '') {
    let hasd = [];
    return arr.reduce(function(item, next) {
        hasd[next[key]] ? '' : (hasd[next[key]] = true && item.push(next));
        return item
    }, [])
}

const mutations = {
    SET_BUCKET_NAME: (state, bucketName) => {
        state.bucketName = bucketName
    },
    SET_PREFIX: (state, prefix) => {
        state.prefix = prefix
    },
    SET_CURRENT: (state, continuationToken) => {
        state.continuationToken = continuationToken
    },
    SET_HAS_NEXT: (state, hasNext) => {
        state.hasNext = hasNext
    },
    SET_FILES: (state, fileList) => {
        // state.fileList = fileList
        state.fileList = filterByAttr(fileList, 'key')
    },
    ADD_FILES: (state, fileList) => {
        state.fileList = [...state.fileList, ...fileList]
    }
}

const actions = {
    getFileList({ commit, state}, {current,size, bucketName, prefix, init, regionId}) {
        if(init){
            commit('SET_BUCKET_NAME', bucketName)
            commit('SET_PREFIX', prefix)
            commit('SET_FILES', [])
            commit('SET_HAS_NEXT', true)
            commit('SET_CURRENT', '')
        }
        const needNum = 5 * size * Math.ceil(current / 5)
        const start = (current -1) * size
        const end = current * size
        const getFileListFromServer = ()=>{
            return service.postJson('/file/list',{continuationToken: state.continuationToken, regionId, bucketName, prefix:encodeURIComponent(prefix)}).then(res =>{
                const {data:{objects=[], truncated, nextContinuationToken}} = res
                commit('SET_CURRENT', nextContinuationToken)
                commit('SET_FILES', [...state.fileList, ...objects])
                commit('SET_HAS_NEXT', truncated)
                if(truncated && needNum > state.fileList.length){
                    return getFileListFromServer()
                } else {
                    return res
                }

            })
        }

        const sliceFileList = (state, start, end)=>{
            const {fileList} = state
            const list = fileList.slice(start, end)
            const hasNext = fileList.length > end
            const hasNextPatch = needNum < fileList.length
            return {list, hasNext, hasNextPatch, total: fileList.length}
        }
        return new Promise((resolve, reject) => {

            if(state.fileList.length <= end && state.hasNext){
                getFileListFromServer(state.current + 1).then(() =>{
                    resolve(sliceFileList(state, start, end))
                })
                // if (state.fileList.length > end - 10) {
                //     resolve(sliceFileList(state, start, end))
                // }
            } else if(state.fileList.length < end){
                resolve(sliceFileList(state, start, end))
            } else{
                resolve(sliceFileList(state, start, end))
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
