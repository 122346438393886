import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import qs from 'qs'
import store from '@/store'
import {getToken} from './session'
import Vue from 'vue'
import {getEnv} from './env'
import {noMessageList} from "@/utils/constant";
import Cookie from 'js-cookie'
import storage from "@/utils/storage";
import {licenseExpired} from "@/utils/index";

const serverConfig = {
    baseURL: '/api',
    timeout: 60000
}
const service = axios.create(serverConfig)

// 后端返回数据流的部分接口 需要特殊处理
function isDownload (url) {
    const urls = ['/file/folder/download', '/rfObject/download', '/rfTask/label/download', '/rfTask/shelves/download', '/dashboard/downloadStatisticsSummary']
    for (let i = 0; i < urls.length; i++) {
        if (url.indexOf(urls[i]) > -1) {
            return true
        }
    }
    return  false
}

const onResponse = (response)=>{
    if (response.status === 500) {
        return  Promise.reject(response)
    } else {
        if (response.status == 302) {
            window.location.href = response.location;
            return;
        }
        const res = response.data
        if ((res.code === '703' || res.code === '702') && window.location) {
            router.push({path: '/login', params: {message: res.msg}})
            document.cookie = ''
            return res;
        }

        if (res.code === '711') {
            licenseExpired()
            return res;
        }

        if (isDownload(response.config.url)) {
            return  Promise.resolve(response)
        }
        if (res.code !== '200') {
            if (noMessageList.indexOf(response.config.url.split('?')[0]) === -1) {
                Vue.prototype.$message.error(res.msg || '系统错误')
            }
            return Promise.reject(new Error(res.msg || res.message || '系统错误'))
        } else {
            return res
        }
    }

}
const onRequest = (config)=>{
    // const token = getToken()
    // if (token && token !== 'null') {
    //     // config.headers['token'] = token
    // }
    // // config.headers.AppKey = 'S-01010';
    // // config.headers['user-source'] = getEnv().system;

    if (storage.get('REGION_ID')) {
        config.headers.regionId = storage.get('REGION_ID')
    }
    config.headers.token = storage.get('PRIVATE_BLUESTORE_TOKEN') || ''
    // 下载文件夹  zip 包
    if (isDownload(config.url)) {
        config.responseType = 'blob'
    }

    return config
}
const onReject = (error)=>{
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(onRequest,onReject)

// response interceptor
service.interceptors.response.use(onResponse,
    error => {
        if(axios.isCancel(error)){
            return Promise.reject(error)
        }
        // router.push(`/500`);
        return Promise.reject(error.message)
    }
)

const get = (url)=>{
    return service.get(url)
}
const post =  (url, data)=>{
    return service.post(url, qs.stringify(data, { indices: false }))
}

const postJson = (url, data, config = {})=>{
    return service.post(url, data, config)
}

const deleteJson = (url, data)=>{
  return service.request({
    url,
    method: 'delete',
    data
  })
}
const putJson = (url, data)=>{
  return service.request({
    url,
    method: 'put',
    data
  })
}

const download = async (url, data, method = 'post')=>{
    const queryArgs = {
        baseURL: '/api', // url = base url + request url
        url,
        method,
        data,
        responseType: 'blob',
        headers: {
            withCredentials: true,
            regionId: storage.get('REGION_ID'),
            token: storage.get('PRIVATE_BLUESTORE_TOKEN') || ''
        },
    }
    // tips: 这里直接返回的是response整体!
    try{
        const response = await axios.request(queryArgs)
        convertRes2Blob(response)
        return true
    } catch (e) {
        console.error('error=======', e)
    }
    return false
}

const convertRes2Blob = (response) =>{
    console.log('response===============', response)
    // 提取文件名
    const fileName = response.headers['content-disposition'].match(
      /filename=(.*)/
    )[1]
    // 有的需要转义
    const file_name = decodeURIComponent(fileName)
    // 将二进制流转为blob
    const blob = response.data
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(blob, decodeURI(file_name))
    } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', decodeURI(file_name))
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
    }
}

// 上传成功后通知
const uploadNotice = (bucketName, key, regionId = '') => {
    postJson('/file/uploadNotice', {bucketName, key, regionId}).then(res => {

    })
}

// 上传完成后 给文件增加标签
const addTagsToFile = ({bucket, tagList, key}) =>{
    let data = {
        bucketName: bucket,
        tagList,
        keyAndVersionIdList: [{key, versionId: null}]
    }
    service.post('/file/addTagging', data)
        .then(res => {
            if (res.code === '200') {
                // this.$message.success("添加标签成功");
                // this.visible = false
                // this.$emit('complete')
            }
        })
}

export {
    service
}

export default {
    get,
    post,
    postJson,
    deleteJson,
    putJson,
    download,
    uploadNotice,
    addTagsToFile
}
