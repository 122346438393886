<template>
    <router-view />
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import Layout from "./views/layout/Layout";
import Cookie from 'js-cookie'
import {licenseExpired} from "@/utils";

export default {
    name: "APP",
    components: {
        Layout,
    },
    data() {
        return {
            defaultPath: window.location.pathname,
        };
    },
    methods: {
        // ...mapActions(['login'])
        getLicenseInfo () {
            this.$get('/license/getInfo')
                .then(res => {
                    if (res.code === '200') {
                        // expireFlag
                        this.$store.commit('user/SET_LICENSE', res.data)
                    }
                })
        },
        getAppName () {
            this.$get('/config/appName')
                .then(res => {
                    if (res.code === '200') {
                        this.$store.commit('user/SET_APP_NAME', res.data)
                        document.title = res.data
                    }
                })
        },
    },
    computed: {
        ...mapGetters(["permissions", "userData"]),
        menus() {
            const {permissions} = this;
            const list = permissions.filter((item) => !item.hidden);
            const menus = [];
            for (let i = 0; i < list.length; i++) {
                const menu = {
                    ...list[i],
                };
                if (list[i].children) {
                    menu.children = list[i].children.filter((item) => !item.hidden);
                }
                menus.push(menu);
            }
            return menus;
        },
    },
    watch: {
        permissions(permissions = []) {
            const {pathname} = window.location;
            for (let i = 0; i < permissions.length; i++) {
                const menu = permissions[i];
                const menuChildrenPaths = menu.children
                    ? menu.children.map((item) => {
                        if (item.path.indexOf("/") === 0) {
                            return `${item.path}`;
                        }
                        return `${menu.path}/${item.path}`;
                    })
                    : [];
                if (menu.path === pathname || menuChildrenPaths.indexOf(pathname) > -1) {
                    this.defaultPath = menu.path;
                }
            }
        },
    },
    mounted() {
        localStorage.setItem('show__tips', 1)
        this.getLicenseInfo()
        this.getAppName();
    },
    created() {
        localStorage.removeItem('bucket_create')
        if (this.IS_SSC) {
            import('./assets/common-ssc.scss')
        }
        this.$store.dispatch('user/login').then(({funcPrivList = []}) => {
            let result = false
            const list = funcPrivList.map(i => i.platCode)
            if ((this.IS_SSC && (list.indexOf('S-01104') > -1 || list.indexOf('S-01102') > -1)) || (!this.IS_SSC && list.indexOf('S-01103') > -1)) {
                result = true
            }
            if (!result) {
                this.$confirm("此账号无登录权限，请检查您的网址与账号正确性！", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false
                }).then(res => {
                    Cookie.remove('token');
                    window.location.href = `${window.location.origin}/logout/${window.location.hash}`;
                })
            }
            this.load = result
        })
    }
};
</script>
<style lang="scss">
.crumb {
    display: flex;
    align-items: center;
    height: 60px;
    //padding: 20px;
    font-size: 16px;
    color: #333;
    background: #ffffff;
}
</style>
