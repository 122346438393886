<template>
    <el-dialog title="重置密码" :visible.sync="visible" :close-on-click-modal="false" @close="handleClose">
        <el-form ref="resetPass" :rules="rules" :model="resetPhone" label-width="100px" v-loading="loading">
            <el-form-item  label="新密码" prop="passWord">
                <div class="flex_container">
                <el-input
                    placeholder="请输入密码"
                    v-model.trim="resetPhone.passWord"
                    show-password
                    :maxlength="maxlength"
                    class="pass-inp"
                ></el-input>
                <el-tooltip
                    effect="light"
                    placement="top-start"
                    style="margin-left:4px;vertical-align: middle;"
                    popper-class="atooltip"
                    :tabindex="tabindex"
                >
                    <div slot="content" style="line-height:20px;">
                        <i
                            class="el-icon-check"
                            style="font-size:24px;color:#67C23A;vertical-align: middle;"
                            v-if="!(passStrong < 1)"
                        ></i>
                        <i
                            class="el-icon-close"
                            style="font-size:24px;color:#F56C6C;vertical-align: middle;"
                            v-if="passStrong < 1"
                        ></i>
                        长度为8-30个字符；
                        <br/>
                        <i
                            class="el-icon-check"
                            style="font-size:24px;color:#67C23A;vertical-align: middle;"
                            v-if="passStrong == 3"
                        ></i>
                        <i
                            class="el-icon-close"
                            style="font-size:24px;color:#F56C6C;vertical-align: middle;"
                            v-if="passStrong != 3"
                        ></i
                        >必须包含大写字母、小写字母、数字、符号中至少3种
                    </div>

                    <span class="vol-tip"></span>
                </el-tooltip>
                </div>
            </el-form-item>
            <el-form-item label="确认密码" prop="newPassWord">
                <el-input
                    class="pass-inp"
                    placeholder="请输入确认密码"
                    v-model.trim="resetPhone.newPassWord"
                    show-password
                ></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button :loading="loading" size="small" @click="handleSubmit" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Cookie from 'js-cookie'

export default {
    name: "resetPass",
    data () {
        let validateConfirmPass = (rule, value, callback) => {
            console.log(value, this.resetPhone)
            if (value === '') {
                return callback(new Error('请再次输入密码'));
            } else if (value !== this.resetPhone.passWord) {
                return callback(new Error('两次输入密码不一致!'));
            } else {
                return callback();
            }
        };
        let passStorngConfirm = (rule, value, callback) => {
            if (this.passStrong < 3) {
                return callback(new Error('密码强度不够！'));
            } else {
                this.$refs['resetPass'].validateField('newPassWord');
                return callback();
            }
        };
        return {
            visible: false,
            loading: false,
            tabindex: -1,
            accountUserId: '',
            resetPhone: {
                passWord: '',
                newPassWord: '',
            },
            maxlength: 30,
            rules: {
                passWord: [
                    {required: true, message: '请输入新的账号密码', trigger: 'change'},
                    {validator: passStorngConfirm, trigger: 'change'},
                    {
                        min: 8,
                        max: 30,
                        message: '长度在 8 到 30 个字符',
                        trigger: 'change'
                    }
                ],
                newPassWord: [
                    {required: true, validator: validateConfirmPass, trigger: 'change'}
                ]
            }
        }
    },
    computed: {
        ...mapGetters(['userData']),
        passStrong() {
            let n = 0;
            if (!this.resetPhone.passWord) {
                n = -1;
            } else if (
                this.resetPhone.passWord.length < 8 ||
                this.resetPhone.passWord.length > this.maxlength
            ) {
                n = 0; //长度不符合直接返回0
            } else {
                if (/\d/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含数字
                if (/[a-z]/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含小写字母
                if (/[A-Z]/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含大写字母
                if (/\W/.test(this.resetPhone.passWord)) {
                    // if (/.*[~!@#\\$%&\\*]+.*/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含其他字符
            }
            if (n > 3) {
                n = 3;
            }
            console.log(n)
            return n;
        },
    },
    methods: {
        show(row) {
            console.log(123, row)
            this.visible = true
            if (row && row.bid) {
                // 如果有bid，属于管理员给子账号改密码
                this.accountUserId = row.bid
            }
            setTimeout(() => {
                this.$refs.resetPass && this.$refs.resetPass.resetFields();
            }, 200)
        },
        handleClose () {
            this.visible = false
        },
        handleSubmit () {
            this.$refs.resetPass.validate(valid => {
                if (valid) {
                    this.loading = true
                    if (this.accountUserId) {
                        this.$postJson('/accountUser/updateAccountUser', {
                            accountUserId: this.accountUserId,
                            password: this.resetPhone.passWord
                        })
                        .then(res => {
                            if (res.code === '200') {
                                this.$message.success('已修改')
                                this.$emit('complete')
                                this.visible = false
                                this.resetPhone.passWord = ''
                                this.resetPhone.newPassWord = ''
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$postJson('/accountUser/modifyPassword', {newPassword: this.resetPhone.passWord})
                            .then(res => {
                                if (res.code === '200') {
                                    document.cookie = ''
                                    this.$router.push('/login')
                                    this.$message.success('修改成功，请重新登录')
                                    this.resetPhone.passWord = ''
                                    this.resetPhone.newPassWord = ''
                                }
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>