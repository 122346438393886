import moment from "moment";
import storage from "@/utils/storage";

const getters = {
    currentPoint: state=> {
        return state.region.currentPoint || {}
    },
    points: state=> state.region.points,
    storageClassList: state=> (state.region.currentPoint?.storageClassList || []),
    publicNetSwitch: state=> state.user.publicNetSwitch,
    insertRoutes: state=> state.user.insertRoutes,
    permissions: state=> state.user.permissions,
    regionId: state=> state.user.regionId,
    appName: state=> state.user.appName,
    accountType: state=> state.user.accountType.toLowerCase(),
    cmUserInfo: state=> state.user.cmUserInfo,
    license: state=> state.user.license,
    restoreRemindStatus: state=> state.user.userData.restoreRemindStatus,
    userData: state=> state.user.userData,
    unreadMessage: state=> state.user.unreadMessage,
    userIsFrozen: state=> {
        return state.user.userData && state.user.userData.status === 'DISABLE'
    },
    // 是否超管
    isAdmin: state=> {
        return state.user.userData && state.user.userData.role === 'ROLE_ADMIN'
    },
    esEnabled: state => {
        const {points = []} = state.region
        const regionId = storage.get('REGION_ID')
        return points.find(i => i.regionId === regionId)?.esEnable === 'true'
    },
    accountId: state=> {
        return state.user.userData ? state.user.userData.bid : ''
    },
    token: state=> state.user.token,
    isExpire: state=> {
        if(state.user.userData){
            let expireTime = moment(state.user.userData.expireTime);
            let currentTime = moment();
            let isExpire = false;
            if(currentTime.isAfter(expireTime)){
                isExpire = true
            }
            return isExpire;
        }
        return false;
    },
    bucketList: state=> state.bucket.bucketList,
    uploadNoTip: state=> {
        const {uploadTip,constants} = state.user
        if(constants.UPLOAD_NO_TIP_VALUE_TRUE === uploadTip){
            return true
        }
        return false
    },


}
export default getters
