<template>
  <el-tooltip v-if="userIsFrozen" :content="optDisabledTip" placement="top">
    <span class="opt-button">
      <el-button :type="type" :icon="icon" :size="size" disabled><slot></slot></el-button>
    </span>
  </el-tooltip>
  <el-button v-else :type="type" :icon="icon" :size="size" :disabled="disabled" @click.stop="onClick"><slot></slot></el-button>
</template>

<script>
  import {mapGetters} from "vuex";
  export default {
    name: "OptButton",
    props:['type','size', 'icon', 'disabled', 'userIsFrozen'],
    data(){
      return {
        showTip: false,
      }
    },
    computed: {
      ...mapGetters([""]),
      optDisabledTip(){
        const {userIsFrozen} = this
        return userIsFrozen ? '服务已到期，不支持该操作': ''
      },
    },
    methods:{
      onClick(){
        this.$emit('click')
      }
    }
  };
</script>
<style scoped>
  .opt-button + .opt-button{
    margin-left: 10px;
  }
</style>
