<template>
    <div class="dz-view">
<!--        <el-alert v-if="showTip" style="margin: 0;" title="说明：若未开启版本控制功能，同名文件将无法成功上传" @close="handleCloseAlert" type="warning" show-icon :closable="true" ></el-alert>-->

        <div class="form-item" v-show="storagePlatformType === 'E37DEG' || storagePlatformType === 'SUPERSTOR'">
            <div class="label">存储类型：</div>
            <div class="content">
                <el-radio-group v-model="StorageClass" style="margin-top: 10px;">
                    <el-radio v-show="storageClassList.indexOf('STANDARD') > -1" :label="'STANDARD'">标准存储</el-radio>
                    <el-radio v-show="storageClassList.indexOf('INTELLIGENT') > -1" :label="'INTELLIGENT'">智能存储
                        <el-tooltip effect="dark" :content=tooltipInfo placement="top"><i class="el-icon-info"></i></el-tooltip>
                    </el-radio>
                    <el-radio v-show="storageClassList.indexOf('ARCHIVE') > -1" :label="'ARCHIVE'">归档存储</el-radio>
                    <el-radio v-show="storageClassList.indexOf('GLACIER') > -1" :label="'GLACIER'">深度归档存储</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="form-item" >
            <div class="label">添加标签：</div>
            <div class="content">
                <set-tag ref="setTagRef" @tagList="changeTag"></set-tag>
            </div>
        </div>

        <div class="title">
            <div class="title-btn">
                <span class="disabled" v-show="files.length === 0">清空列表</span>
                <span class="clear" v-show="files.length > 0" @click="handleClear">清空列表</span>
                <span class="upload" v-show="files.length < 100" @click="uploadFile" >添加文件</span>
                <el-tooltip v-show="files.length >= 100" :content="'单次最多支持100个文件'" placement="top">
                    <span class="disabled upload" v-show="files.length >= 100" >添加文件</span>
                </el-tooltip>

            </div>
            <div class="title-info">
                {{ files.length }}/100
                共{{ totalSize }}
            </div>
        </div>


        <div :class="{'dropview': true, 'dropping': borderhover}" ref="dropArea" v-show="files.length === 0">
            <div v-if="borderhover" class="dragView">松开立即上传</div>
            <div v-else class="dragView">
                <div>
                    <img class="upload-btn" src="../../assets/images/upload-btn.png"/>拖拽文件到此处或者
                    <el-button type="text" @click="uploadFile" class="uploadBtn">点击上传</el-button>
                </div>
                <div>单次最多支持100个文件同时上传</div>
            </div>
        </div>

        <el-table :data="files" v-show="files.length > 0">
            <el-table-column label="文件名">
                <FileName slot-scope="{row}" :filename="row.name"></FileName>
            </el-table-column>
            <el-table-column prop="size" label="文件大小">
                <template slot-scope="{ $index, row }">
                    {{ bytesToSize(row.size, 1) }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{ $index, row }">
                    <el-button size="small" type="text" @click="handleDelete($index)">删除</el-button>
                </template>
            </el-table-column>

        </el-table>
        <input multiple="multiple" type="file" style="display: none;" ref="uploadInput" @change="uploadChange"></input>
    </div>
</template>

<script>
import FileName from '../../components/FileName'
import {bytesToSize} from '../../utils/index'
import setTag from "@/views/bucket/components/setTag";
import storage from "@/utils/storage";
import {mapGetters} from "vuex";

export default {
    name: "DropZone",
    components: {FileName, setTag},
    props: ['storagePlatformType'],
    data() {
        return {
            borderhover: false,
            files: [],
            showTip: false,
            StorageClass: 'GLACIER',
            tagList: [],
        };
    },
    watch: {
        storageClassList (val) {
            console.log('storageClassList:', val)
        }
    },
    computed: {
        ...mapGetters(['storageClassList', 'currentPoint']),
        tooltipInfo () {
            const {s3ArchiveBurnDay = 30, s3GlacierBurnDay = 90} = this.currentPoint
            let info = `适用于访问频率不固定的文件，${s3ArchiveBurnDay}天内无访问将会转储至归档文件，90天内无访问将会转储至深度归档文件`
            const {storageClassList} = this
            const hasArchive = storageClassList.indexOf('ARCHIVE') > -1
            const hasGlacier = storageClassList.indexOf('ARCHIVE') > -1

            if (hasArchive && !hasGlacier) {
                info = `适用于访问频率不固定的文件，${s3ArchiveBurnDay}天内无访问将会转储至归档文件`
            } else if (!hasArchive && hasGlacier) {
                info = `适用于访问频率不固定的文件，${s3GlacierBurnDay}天内无访问将会转储至深度归档文件`
            } else if (hasArchive && hasGlacier) {
                info = `适用于访问频率不固定的文件，${s3ArchiveBurnDay}天内无访问将会转储至归档文件，${s3GlacierBurnDay}天内无访问将会转储至深度归档文件`
            }

            return info
        },
        totalSize () {
            const {files = []} = this
            if (files.length === 0) {
                return '0 M'
            }
            let total = files.reduce((total, item) => (total + item.size), 0)
            return this.bytesToSize(total, 1)
        }
    },
    mounted() {
        let _this = this;
        this.showTip = localStorage.getItem('UPLOAD_TIP_CLOSE') !== '1'
        const dropbox = this.$refs.dropArea;
        dropbox.addEventListener("drop", this.handleDrop.bind(this), false);
        dropbox.addEventListener("dragleave", function (e) {
            e.stopPropagation();
            e.preventDefault();
            _this.borderhover = false;
        });
        dropbox.addEventListener("dragenter", function (e) {
            e.stopPropagation();
            e.preventDefault();
            _this.borderhover = true;
        });
        dropbox.addEventListener("dragover", function (e) {
            e.dataTransfer.dropEffect = "move"; // 兼容某些三方应用，如圈点
            e.stopPropagation();
            e.preventDefault();
            _this.borderhover = true;
        });

    },
    beforeDestroy() {
        window.onbeforeunload = null;
    },
    methods: {

        bytesToSize,
        changeTag(val) {
            this.tagList = val
        },
        handleCloseAlert () {
            localStorage.setItem('UPLOAD_TIP_CLOSE', '1')
        },
        getFiles () {
            return this.files
        },
        handleClear () {
            this.files = []
        },
        handleDelete (ind) {
            this.files.splice(ind, 1)
        },
        submit () {
            if (this.tagList && this.tagList.length > 20) {
                this.$message.warning('最多只能添加20个标签')
                return
            }
            if (!this.files || this.files.length === 0) {
                this.$message.warning('请添加文件')
                return;
            }
            this.files.forEach(item => {
                item.storageClass = this.StorageClass
                item.tagList = this.tagList
            })
            this.$emit("change", this.files);
            this.files = []
            this.$refs.setTagRef.init()
            // this.tagList = []
        },

        uploadFile() {
            this.$refs.uploadInput.click();
        },

        uploadChange(event) {
            const {
                target: {files},
            } = event;

            if (files && files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    if (this.files.length >= 100) {
                        // this.$message.warning('单次最多支持100个文件')
                        break;
                    }
                    files[i].fullPath = files[i].name;
                    this.files.push(files[i]);
                }
                // this.$emit("change", uploadFiles);
                this.$refs.uploadInput.value = ""; //虽然file的value不能设为有字符的值，但是可以设置为空值
                // this.$refs.uploadInput
            }
        },
        handleDrop(event) {
            event.stopPropagation();
            event.preventDefault(); //必填字段
            this.borderhover = false;
            this.readDropFileList(event);
        },
        scanFiles(item, parent) {
            const _this = this;
            return new Promise(function (resolve, reject) {
                // 如果是目录，则递归读取
                if (item.isFile) {
                    item.file((file) => {
                        file.fullPath = `${item.fullPath.substr(1)}`;
                        resolve([file]);
                    });
                } else {
                    // 使用目录实体来创建 FileSystemDirectoryReader 实例
                    const paths = item.fullPath.split('/')
                    let files = [
                        {
                            name: paths[paths.length - 1],
                            fullPath: item.fullPath.substr(1),
                            type: "dir",
                            size: 0,
                        },
                    ];
                    let directoryReader = item.createReader();
                    // 上面只是创建了 reader 实例，现在使用 reader 实例来读取 目录实体（读取目录内容）
                    directoryReader.readEntries(function (entries) {
                        // 循环目录内容
                        const promiseList = [];
                        for (let i = 0; i < entries.length; i++) {
                            const entry = entries[i];
                            // 处理内容（递归）
                            promiseList.push(_this.scanFiles(entry, item));
                        }
                        Promise.all(promiseList).then((items) => {
                            for (let i = 0; i < items.length; i++) {
                                files = [...files, ...items[i]];
                            }
                            resolve(files);
                        });
                    });
                }
            });
        },
        readDropFileList(e) {
            let _this = this;
            const df = e.dataTransfer;
            const dropFiles = []; // 拖拽的文件，会放到这里
            let dealFileCnt = 0; // 读取文件是个异步的过程，需要记录处理了多少个文件了
            let allFileLen = df.files.length; // 所有的文件的数量，给非Chrome浏览器使用的变量
            // 检测是否已经把所有的文件都遍历过了
            function checkDropFinish() {
                if (dealFileCnt === allFileLen - 1) {
                    let files = _this.files.concat(dropFiles)
                    if (files.length > 100) {
                        // this.$message.warning('单次最多支持100个文件')
                        _this.files = files.slice(0,100)
                    } else {
                        _this.files = files
                    }
                    // this.files = [...this.files, ...dropFiles]
                    // _this.$emit("change", dropFiles);
                }
                dealFileCnt++;
            }

            if (df.items !== undefined) {
                // Chrome拖拽文件逻辑
                let readFilePromiseList = [];
                for (var i = 0; i < df.items.length; i++) {
                    var item = df.items[i];
                    if (item.kind === "file") {
                        readFilePromiseList.push(this.scanFiles(item.webkitGetAsEntry()));
                    }
                }
                Promise.all(readFilePromiseList).then((resultList) => {
                    for (let j = 0; j < resultList.length; j++) {
                        if (this.files.length >= 100) {
                            // this.$message.warning('单次最多支持100个文件')
                            break;
                        }
                        for (let m = 0; m < resultList[j].length; m++) {
                            if (this.files.length >= 100) {
                                // this.$message.warning('单次最多支持100个文件')
                                break;
                            }
                            resultList[j][m].type !== 'dir' && this.files.push(resultList[j][m])
                        }
                    }

                });
            } else {
                // 非Chrome拖拽文件逻辑
                for (var i = 0; i < allFileLen; i++) {

                    var dropFile = df.files[i];
                    if (dropFile.type) {
                        dropFile.fullPath = `${dropFile.name}`;
                        dropFiles.push(dropFile);
                        checkDropFinish();
                    } else {
                        try {
                            var fileReader = new FileReader();
                            fileReader.readAsDataURL(dropFile.slice(0, 3));

                            fileReader.addEventListener(
                                "load",
                                function (e) {
                                    dropFile.fullPath = `${dropFile.name}`;
                                    dropFiles.push(dropFile);
                                    checkDropFinish();
                                },
                                false
                            );

                            fileReader.addEventListener(
                                "error",
                                function (e) {
                                    checkDropFinish();
                                },
                                false
                            );
                        } catch (e) {
                            checkDropFinish();
                        }
                    }
                }
            }
        },
    },
};
</script>
<style scoped lang="scss">
.dz-view {
    width: 560px;
    margin: 0 0 0 -40px;

    .dropview {
        width: 100%;
        font-size: 12px;
        text-align: center;
        line-height: 60px;
        border: 1px dashed #d9d9d9;

        &.dropping {
            background: #d9d9d9;

            .dragView {
                color: #ffffff;
            }
        }
    }

    .dragView {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height: 118px;
        line-height: 18px;
        color: #bbb;

        div {
            position: relative;
            width: 180px;
            text-align: left;
        }
    }

    .uploadBtn {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .upload-btn {
        position: absolute;
        left: -21px;
        top: 3px;
        width: 16px;
        height: 13px;
        margin-right: 5px;
    }
}

.title {
    display: flex;
    justify-content: space-between;
    height: 32px;
    line-height: 31px;
    margin: 10px 0 ;
    &-btn {
        display: flex;
        justify-content: flex-start;
        > span {
            width: 68px;
            height: 32px;
            border-radius: 2px;
            font-size: 12px;
            text-align: center;
            cursor: pointer;
        }
        > span.clear {
            border: 1px solid #337DFF;
            color: #337DFF;
            margin-right: 10px;
        }
        > span.upload {
            color: #555555;
            border:1px solid #d9d9d9;
        }
        > span.upload:hover {
            color: #337dff;
            border: 1px solid #337dff;
        }
        > span.disabled {
            background-color: #f5f5f5;
            border:1px solid #d9d9d9;
            color: #bbbbbb;
            margin-right: 10px;
            cursor: not-allowed;
        }
        >span:active {
            background-color: #e1ecff;
        }
    }
    &-info {
        font-size: 14px;
        color: #999;
        line-height: 34px;
    }
}

.number {
    height: 42px;
    border-bottom: 1px solid  #E9EBEF;
    display: flex;
    justify-content: flex-start;
    &-item {
        width: 96px;
    }
}

.form-item {
    display: flex;
    min-height: 30px;
    line-height: 30px;
    margin: 10px 0;
    .label {
        width: 80px;
        flex-shrink: 0;
        flex-grow: 0;
        line-height: 40px;
    }
}
</style>
