var formatNum = function(f, digit) {
  var m = Math.pow(10, digit);  // digit是参数的最大精度
  return parseInt(f * m, 10) / m;
}
const dsFormat = (val)=>{
  if(isNaN(val)){
    return '-'
  }
  const size = parseInt(val + '')
  if(size < 1024){
    return `${size}B`
  }
  if(size < 1024 * 1024){
    return `${formatNum(size / 1024, 1)}KB`
  }
  if(size < 1024 * 1024 * 1024){
    return `${formatNum(size / (1024 * 1024), 1)}MB`
  }
  return `${formatNum(size / (1024 * 1024 * 1024), 1)}GB`
}
const install = function(Vue) {
  Vue.filter('dsFormat', dsFormat)
}

if (window.Vue) {
  window['dsFormat'] = dsFormat
  Vue.use(install); // eslint-disable-line
}
export default {
  install
}