export default {
    bind(el, binding, vnode) {
        el.style.cssText += ';cursor:move;'

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const getStyle = (function() {
            if (window.document.currentStyle) {
                return (dom, attr) => dom.currentStyle[attr]
            } else {
                return (dom, attr) => getComputedStyle(dom, false)[attr]
            }
        })()

        el.onmousedown = (e) => {

            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX
            const disY = e.clientY

            const dragDomWidth = el.offsetWidth
            const dragDomHeight = el.offsetHeight

            const screenWidth = document.body.clientWidth
            const screenHeight = document.body.clientHeight

            const minDragDomLeft = el.offsetLeft
            const maxDragDomLeft = screenWidth - dragDomWidth

            const minDragDomTop = el.offsetTop
            const maxDragDomTop = screenHeight - dragDomHeight

            // 获取到的值带px 正则匹配替换
            let styL = getStyle(el, 'left')
            let styT = getStyle(el, 'top')

            if (styL.includes('%')) {
                styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
                styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
            } else {
                styL = +styL.replace(/\px/g, '')
                styT = +styT.replace(/\px/g, '')
            }
            e.preventDefault();//阻止默认事件，取消文字选中
            document.onmousemove = function(e) {
                e.preventDefault();//阻止默认事件，取消文字选中
                // 通过事件委托，计算移动的距离
                let left = e.clientX - disX
                let top = e.clientY - disY

                // 边界处理
                if (-(left) > minDragDomLeft) {
                    left = -minDragDomLeft
                } else if (left > maxDragDomLeft) {
                    left = maxDragDomLeft
                }

                if (-(top) > minDragDomTop) {
                    top = -minDragDomTop
                } else if (top > maxDragDomTop) {
                    top = maxDragDomTop
                }

                // 移动当前元素
                el.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`

                // emit onDrag event
                vnode.context.$emit('dragging')
            }

            document.onmouseup = function(e) {
                e.preventDefault()
                e.stopPropagation()
                document.onmousemove = null
                document.onmouseup = null
            }
        }
    }
}