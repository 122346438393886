import Vue from 'vue'
import ElementUI from 'element-ui';
import './element-variables.scss';
Vue.use(ElementUI);
['success', 'warning', 'info', 'error'].forEach(type => {
  Vue.prototype.$message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.type = type;
    options.offset = 63;
    return ElementUI.Message(options);
  };
});