<template>
    <el-dialog title="编辑账号" :visible.sync="visible" :close-on-click-modal="false" @close="handleClose">
        <el-form ref="resetPass" :rules="rules" :model="form" label-width="100px" v-loading="loading">
            <el-form-item prop="userName" label="用户账号">
                <div style="text-align: left;">{{ userData.username }}</div>
            </el-form-item>
            <el-form-item prop="phone" label="手机号">
                <el-input placeholder="输入手机号" v-model.trim="form.phone"/>
            </el-form-item>
            <el-form-item prop="mail" label="邮箱地址">
                <el-input placeholder="输入邮箱地址" v-model.trim="form.mail"/>
            </el-form-item>
            <el-form-item prop="description" label="描述">
                <el-input type="textarea" :row="3" placeholder="输入邮箱地址" v-model.trim="form.description"/>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button :loading="loading" size="small" @click="handleSubmit" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Cookie from 'js-cookie'

export default {
    name: "resetPass",
    data () {
        return {
            visible: false,
            loading: false,
            form: {
                phone: '',
                mail: '',
                description: ''
            },
            rules: {
                phone: {
                    pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
                    message: '请输入正确的电话号码',
                    trigger: 'blur'
                },
                mail: {
                    pattern:  /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                    message: '请输入正确的邮箱',
                    trigger: 'blur'
                }
            }
        }
    },
    computed: {
        ...mapGetters(['userData']),
    },
    methods: {
        show() {
            this.visible = true
            this.form.phone = this.userData.phone
            this.form.mail = this.userData.mail
            this.form.description = this.userData.description
        },
        handleClose () {
            this.visible = false
        },
        handleSubmit () {
            this.$refs.resetPass.validate(valid => {
                if (valid) {
                    this.loading = true
                    this.$postJson('/accountUser/updateAccountUser', this.form)
                        .then(res => {
                            if (res.code === '200') {
                                this.$message.success('已修改')
                                this.visible = false
                                this.$emit('complete')
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>