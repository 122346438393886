<template>
    <div class="frozen-page">
        <img class="img-frozen img-top-space" src="../assets/frozen.png"/>
        <div class="error-msg">您订购的蓝光存储已到期</div>
        <div class="tip-msg">蓝光存储服务到期后三个月内会为您保留数据，可以进行续费。超过三个月未续费，数据将会被自动销毁，请您及时续期恢复服务。</div>
        <div class="btn-renewal">立即续期</div>
    </div>
</template>
<script>
export default {
    name: "Frozen",
};
</script>
<style lang="scss">
.frozen-page {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    text-align: center;
    background: white;
    border: solid 20px #eff1f4;
    z-index: 1;
}

.img-frozen {
    width: 390px;
    height: 166px;
}

.img-top-space {
    margin-top: 135px;
}

.error-msg {
    margin-top: 27px;
    font-size: 18px;
    color: #333;
    font-weight: 600;
}

.tip-msg {
    width: 548px;
    margin: 16px auto 0;
    font-size: 14px;
    color: #555;
}

.btn-renewal {
    width: 115px;
    height: 32px;
    margin: 14px auto;
    color: white;
    line-height: 32px;
    background: #337dff;
    border-radius: 2px;
}
</style>
