<template>
    <div class="frozen-page">
        <img class="img-unusable unusable-top-space" src="../assets/unusable.png"/>
        <div class="error-msg">暂未订购蓝光存储</div>
        <div class="tip-msg">蓝光存储是一款低成本、高可靠、长周期且易于访问的冷数据存储产品</div>
        <div class="bottom-btn">
            <div class="btn-detail" @click="productDetail">产品详情</div>
            <div class="btn-buy" @click="buyProduct">立即订购</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Unusable",
    methods: {
        productDetail() {
            window.location.href = `${this.cm_host}/home/product-introduction/brs`
        },
        buyProduct() {
            window.location.href = `${this.cm_host}/api/page/op-order-static/product/blue`
        }
    }
};
</script>
<style lang="scss">
.frozen-page {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    text-align: center;
    background: white;
    border: solid 20px #eff1f4;
    z-index: 1;
}

.img-unusable {
    width: 400px;
    height: 240px;
}

.unusable-top-space {
    margin-top: 20px;
}

.error-msg {
    margin-top: 27px;
    font-size: 18px;
    color: #333;
    font-weight: 600;
}

.tip-msg {
    width: 548px;
    margin: 16px auto 0;
    font-size: 14px;
    color: #555;
}

.bottom-btn {
    display: flex;
    width: 170px;
    margin: 20px auto;
    justify-content: space-between;
}

.btn-detail {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    color: #555555;
    cursor: pointer;
}

.btn-buy {
    width: 80px;
    height: 32px;
    color: white;
    line-height: 32px;
    background: #337dff;
    border-radius: 2px;
    cursor: pointer;
}
</style>
