<template>
    <el-popover
        placement="bottom-start"
        width="120"
        v-model="show"
        trigger="click">

        <div class="list">
            <div class="item all"> <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全部</el-checkbox> </div>
            <el-checkbox-group v-model="checkedValues" @change="handleCheckedCitiesChange" style="height: auto;">
                <div class="item" v-for="item in list">
                    <el-checkbox  :label="item.value" :key="item.value">{{item.text}}</el-checkbox>
                </div>
            </el-checkbox-group>
            <div class="btns">
                <span class="confirm btn" @click="confirm">确定</span>
                <span class="cancel btn" @click="cancel">取消</span>
            </div>
        </div>

        <i :class="`icon iconfont ${iconClass}`" style="cursor: pointer;font-size: 10px;color: #666;" slot="reference"></i>
    </el-popover>
</template>

<script>
export default {
    name: "ColumnFilter",
    props: {
        iconClass: {
            type: String,
            default: 'iconloudou'
        },
        list: {
            type: Array,
            default: () => []
        },
    },
    data () {
        return {
            show: true,
            checkAll: true,
            isIndeterminate: false,
            checkedValues: [],
            confirmValues: []
        }
    },
    created() {
        this.checkedValues = this.list.map(i => i.value)
        this.confirmValues = this.list.map(i => i.value)
    },
    methods: {
        handleCheckAllChange (val) {
            const {list} = this;
            this.checkedValues = val ? list.map(i => i.value) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange (value) {
            const {list} = this;
            let checkedCount = value.length;
            this.checkAll = checkedCount === list.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < list.length;
        },
        confirm () {
            this.show = false
            this.confirmValues = this.checkedValues;
            this.$emit('confirm', this.checkedValues)
        },
        cancel () {
            this.show = false
            this.checkedValues = this.confirmValues
        }
    }
}
</script>

<style scoped lang="scss">

.list {
    background: #fff;
    color: #7486D9;
    .item {
        height: 30px;
        line-height: 30px;
    }
    .btns {
        border-top: 1px solid #eee;
        padding-top: 5px;
        display: flex;
        justify-content: center;
        .btn {
            height: 32px;
            line-height: 32px;
            width: 60px;
            background: #7486D9;
            color: #fff;
            text-align: center;
            cursor: pointer;
        }
        .btn.cancel {
            background: #fff;
            color: #7486D9;
        }
    }
}
</style>