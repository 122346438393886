import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import {getEnv} from "@/utils/env";
import {Message} from 'element-ui'
import {getToken} from '@/utils/session'

import Error from '../views/500.vue'
import NotFound from '../views/404.vue'
import Frozen from '../views/Frozen.vue'
import Unusable from '../views/Unusable'
import Info from '../views/info'
import SSC from '../views/ssc'
import vm from '@/utils/event'
import Layout from "../views/layout/Layout";
import {licenseExpired} from "@/utils";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        component: Layout,
        redirect: 'overview',
        children: [
            {
                path: '/overview',
                name: 'overview',
                component: () => import('../views/overView.vue'),
                meta: {
                    title: '概览',
                    hidden: false,
                    accountType: ['main', 'sub']
                }
            },
            {
                path: '/bucket',
                name: 'Bucket',
                meta: {
                    title: '桶管理',
                    accountType: ['main', 'sub']
                },
                redirect: '/bucket/list',
                component: () => import('../views/bucket/Index.vue'),
                children: [{
                    path: 'list',
                    name: 'BucketList',
                    hidden: true,
                    meta: {
                        title: '桶管理',
                    },
                    component: () => import('../views/bucket/List.vue')
                }, {
                    path: 'file',
                    name: 'File',
                    hidden: true,
                    meta: {
                        title: '文件管理',
                    },
                    component: () => import('../views/bucket/File.vue')
                }]
            },
            {
                path: 'aksk',
                name: 'aksk',
                meta: {
                    title: '认证管理',
                    hidden: false,
                    accountType: ['main', 'sub']
                },
                component: () => {
                    console.log('aksk render ...')
                    return import('../views/AkSk.vue')
                }
            },
            {
                path: '/syslog',
                name: 'sysLog',
                meta: {
                    title: '日志审计',
                    hidden: false,
                    accountType: ['main']
                },
                component: () => import('../views/SysLog.vue')
            },
            {
                path: '/accessControl',
                name: 'accessControl',
                meta:{title: '访问控制',
                    hidden: false,
                    accountType: ['main']
                },
                component: () => import('../views/accessControl/index.vue'),
                redirect: '/accessControl/list',
                children: [
                    {
                        path: 'list',
                        name: 'accessControlList',
                        hidden: true,
                        meta:{
                            title: '设置权限',
                        },
                        component: () => import('../views/accessControl/list.vue')
                    },
                    {
                        path: 'manager',
                        name: 'accessControlManager',
                        hidden: true,
                        meta:{
                            title: '设置权限',
                        },
                        component: () => import('../views/accessControl/manager.vue')
                    }
                ]
            },

            {
                path: '/info',
                name: 'info',
                component: () => import('../views/info.vue'),
                meta: {
                    title: '基本资料',
                    hidden: true,
                }
            },
            {
                path: '/tag',
                name: 'tag',
                component: () => import('../views/Tag.vue'),
                meta: {
                    title: '标签管理',
                    hidden: false,
                    accountType: ['main', 'sub']
                }
            },
            {
                path: '/offline_task',
                name: 'offline_task',
                component: () => import('../views/offline/Task.vue'),
                meta: {
                    title: '离线任务管理',
                    hidden: false,
                    accountType: ['main']
                }
            },
            {
                path: '/otd',
                name: 'offline_task_detail',
                component: () => import('../views/offline/TaskDetail.vue'),
                meta: {
                    title: '离线任务详情',
                    hidden: true
                }
            },
            {
                path: '/offline_cassette',
                name: 'offline_cassette',
                component: () => import('../views/offline/Cassette.vue'),
                meta: {
                    title: '离线盘匣管理',
                    hidden: false,
                    accountType: ['main']
                }
            },
            {
                path: '/octl',
                name: 'offline_task_list',
                component: () => import('../views/offline/TaskList.vue'),
                meta: {
                    title: '离线任务列表',
                    hidden: true
                }
            },
            {
                path: '/userlevelSearch',
                name: 'userlevelSearch',
                component: () => import('../views/userlevelSearch.vue'),
                meta: {
                    title: '用户级搜索',
                    hidden: false,
                    accountType: ['main', 'sub']
                }
            },
            {
                path: '/advancedSearch',
                name: 'advancedSearch',
                component: () => import('../views/userlevelSearch.vue'),
                meta: {
                    title: '高级搜索',
                    hidden: false,
                    accountType: ['main', 'sub']
                }
            },
            //
            {
                path: '/userManager',
                name: 'userManager',
                component: () => import('../views/userManager/userManage.vue'),
                meta: {
                    title: '用户管理',
                    hidden: false,
                    accountType: ['main']
                }
            },
            {
                path: '/createUser',
                name: 'createUser',
                component: () => import('../views/userManager/createUser.vue'),
                meta: {
                    title: '创建用户',
                    hidden: true,
                    accountType: ['main']
                }
            },
            {
                path: '/messageManage',
                name: 'messageManage',
                component: () => import('../views/message/messageManage.vue'),
                meta: {
                    title: '消息管理',
                    hidden: false,
                    accountType: ['main']
                }
            },
            {
                path: '/createMessage',
                name: 'createMessage',
                component: () => import('../views/message/createMessage.vue'),
                meta: {
                    title: '发布消息',
                    hidden: true,
                    accountType: ['main']
                }
            },
            {
                path: "/userInfo",
                name: "userInfo",
                component: () => import('../views/userManager/userInfo2'),
                meta: {
                    title: "用户信息",
                    hidden: true,
                    accountType: ['main']
                }
            },
            {
                path: '/messageDetail',
                name: 'messageDetail',
                component: () => import('../views/message/messageDetails.vue'),
                meta: {
                    title: '消息详情',
                    hidden: true,
                    accountType: ['main']
                }
            },
            {
                path: '/license',
                name: 'license',
                component: () => import('../views/license.vue'),
                meta: {
                    title: 'License授权',
                    hidden: false,
                    accountType: ['main']
                }
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/Login.vue'),
                meta: {
                    noheader: true,
                    full: true
                }
            },
        ]
    },
    {
        path: '/500',
        name: 'Error',
        component: () => import('../views/500.vue'),
        meta: {
            full: true
        }
    },
    {
        path: '/frozen',
        name: 'Frozen',
        component: () => import('../views/Frozen'),
        meta: {
            full: true
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: () => import('../views/404.vue'),
        meta: {
            full: true
        }
    },
    {
        path: '/ssc',
        name: 'ssc',
        component: () => import('../views/ssc.vue'),
        meta: {
            noheader: true,
            full: true
        }
    },

    {
        path: '/unusable',
        name: 'Unusable',
        component: () => import('../views/Unusable.vue'),
        meta: {
            full: true
        }
    },

    {
        path: '/forget',
        name: 'forget',
        component: () => import('../views/Forget.vue'),
        meta: {
            noheader: true,
            full: true
        }
    },

]

const dynamicRoutes = [{
    path: '*', // 页面不存在的情况下会跳到404页面
    redirect: '/404',
    hidden: true
}]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.VUE_APP_PAGE_BASE,
    routes
})

const ignorePathList = ['/frozen', '/unusable']

router.beforeEach(async (to, from, next) => {
    console.log(to.path)
    if (ignorePathList.indexOf(to.path) > -1) {
        next();
        return;
    }
    // if ( to.path.indexOf('license') === -1 && to.path.indexOf('login') === -1 && store.getters.license.expireFlag) {
    //     licenseExpired()
    // }


    next()
})

export default router
