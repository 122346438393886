<template>
  <el-link :underline="underline" :href="`${cm_host}${path}`" @click="onClick"><slot></slot></el-link>
</template>

<script>
  export default {
    name: "CMLink",
    props:['underline','disabled', 'title', 'path'],
    methods:{
      onClick(){
        this.$emit('click')
      }
    }
  };
</script>
