import AWS from 'aws-sdk';
import da from "element-ui/src/locale/lang/da";
import Vue from 'vue'
import router from "@/router";
import {service} from '../utils/request'
import Axios from 'axios'
import storage from "@/utils/storage";

function isClintUpload () {
    let type = storage.get('UPLOAD_TYPE')
    return type === 'client'
}


function wrapperError(awsError) {
    console.log(awsError.code)
    let message
    switch (awsError.code) {
        case 'NoSuchBucket':
            message = '指定的桶不存在'
            break;
        case 'BucketNotEmpty':
            message = '桶不为空'
            break;
        case 'RequestAbortedError':
            message = 'abort'
            break;
        case 'AccessDenied':
            message = '无权限'
            break;
        case 'NoSuchKey':
            message = '大文件分片上传失败，可能是由于您在上传过程中清理了该文件的碎片，请重新上传'
            break;
        case 'NoSuchUpload':
            message = '大文件分片上传失败，可能是由于您在上传过程中清理了该文件的碎片，请重新上传'
            break;
        case 'QuotaExceeded':
            message = '桶配额已达上限'
            break;
        default:
            message = '服务异常'
            break;
    }
    // console.log('awsError code', awsError.code)
    // if (awsError.code === 'AccessDenied') {
    //   Vue.prototype.$confirm('您的账号权限被管理员改变，请点击确定返回桶管理页面')
    //       .then(() => {
    //         router.push('/bucket/list')
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       });
    // }
    return new Error(message)
}

function wrapperResult(data) {
    return {
        code: 0,
        data
    }
}

export class S3 {
    host = 'http://10.20.5.98:9001'
    accessKey = ''
    secretKey = ''
    client = null

    constructor(host, accessKey, secretKey) {
        this.host = host
        this.accessKey = accessKey
        this.secretKey = secretKey
        const credentials = new AWS.Credentials({
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
        });
        // const ep = new AWS.Endpoint(host);
        this.client = new AWS.S3({
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
            endpoint: host,
            credentials: credentials,
            s3ForcePathStyle: true,
            sslEnabled: false,
            httpOptions: {
                timeout: 60 * 1000 * 10
            }
        })
    }

    static isAbort(error) {
        return error && error.message === 'abort'
    }

    createDir({bucket, key}) {
        let index = key.indexOf('/', 0);
        let taskList = []
        while (index > 0 && index < key.length) {
            let k = key.substring(0, index);
            index = key.indexOf("/", index + 1);
            let dirName = k + "/";
            taskList.push(new Promise((resolve, reject) => {
                this.client.putObject({
                    Bucket: bucket,
                    Key: dirName
                }, function (err, data) {
                    if (err !== null) {
                        reject(wrapperError(err))
                    } else {
                        resolve(wrapperResult(data))
                    }
                })
            }))
        }
        return Promise.all(taskList)
    }

    //文件上传
    uploadFile({bucket, key, body, StorageClass = 'GLACIER', tagList = []}, onUploadProgress = () => {
    }, cancel = () => {
    }) {
        let Tagging = ''
        if (tagList && tagList.length > 0) {
            Tagging = 'k0='
            tagList.forEach(item => {
                Tagging += item + '-'
            })
        }
        let data = {
            Bucket: bucket,
            Key: key,
            ContentLength: body.size,
            Body: body,
            StorageClass,
            ContentDisposition: 'attachment',
        }
        if (Tagging) {
            data.Tagging = Tagging
        }
        return new Promise((resolve, reject) => {
            if (isClintUpload()) {
                const request = this.client.upload(data, {
                    partSize: 200 * 1024 * 1024
                },function(err, data) {
                    if(err !== null){
                        reject(wrapperError(err))
                    } else {
                        resolve(wrapperResult(data))
                    }
                }).on('httpUploadProgress',onUploadProgress)
                cancel(()=>{
                    request.abort()
                })
            } else {
                let formdata = new FormData()
                formdata.append('file', body)
                formdata.append('bucketName', bucket)
                formdata.append('key', key)
                formdata.append('storageClass', StorageClass)
                formdata.append('tagList', tagList)

                service.post('/file/upload', formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: onUploadProgress,
                    cancelToken: new Axios.CancelToken(cancel),
                    timeout: 1000 * 300
                })
                    .then(res => {
                        if (res.code === '200') {
                            resolve({code: 0, data: res.data})
                        } else {
                            reject(new Error(res.msg))
                        }

                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    }

    //取消分片上传
    abortMultipartUpload({bucket, key, uploadId}) {

        return new Promise((resolve, reject) => {
            if (isClintUpload()) {
                this.client.abortMultipartUpload({
                    Bucket: bucket,
                    Key: key,
                    UploadId: uploadId
                }, function (err, data) {
                    if (err !== null) {
                        reject(wrapperError(err))
                    } else {
                        resolve(wrapperResult(data))
                    }
                })
            } else {
                service.post('/file/multi/abort', {multipartList: [{
                    bucketName: bucket,
                    key, uploadId
                }]})
                    .then(res => {
                        if (res.code === '200') {
                            resolve(wrapperResult(res.data))
                        } else  {
                            reject(wrapperError(res.msg))
                        }
                    })
            }
        })
    }

    //创建分片上传
    createMultipartUpload({bucket, key, StorageClass = 'GLACIER', tagList = []}) {
        let Tagging = ''
        if (tagList && tagList.length > 0) {
            Tagging = 'k0='
            tagList.forEach(item => {
                Tagging += item + '-'
            })
        }
        let data = {
            Bucket: bucket,
            Key: key,
            StorageClass,
        }
        if (Tagging) {
            data.Tagging = Tagging
        }
        return new Promise((resolve, reject) => {
            if (isClintUpload()) {
                this.client.createMultipartUpload(data, function (err, data) {
                    if (err !== null) {
                        reject(wrapperError(err))
                    } else {
                        resolve(wrapperResult(data))
                    }
                })
            } else {
                service.post('/file/multi/init', {
                    bucketName: bucket,
                    key,
                    storageClass: StorageClass,
                    tagList
                })
                    .then(res => {
                        if (res.code === '200') {
                            let data = {
                                UploadId: res.data
                            }
                            resolve(wrapperResult(data))
                        }
                    })
            }
        })
    }

    //上传分片
    uploadPart({bucket, key, body, partNumber, uploadId}, onUploadProgress = () => {
    }, cancel = () => {
    }) {
        return new Promise((resolve, reject) => {
            if (isClintUpload()) {
                const request = this.client.uploadPart({
                    Bucket: bucket,
                    Key: key,
                    Body: body,
                    PartNumber: partNumber,
                    UploadId: uploadId,
                }, function (err, data) {
                    if (err !== null) {
                        reject(wrapperError(err))
                    } else {
                        resolve(wrapperResult({
                            ...data,
                            partNumber
                        }))
                    }
                }).on('httpUploadProgress', onUploadProgress)
                cancel(() => {
                    request.abort()
                })
            } else {
                const data = new FormData()
                data.append('bucketName', bucket)
                data.append('key', key)
                data.append('file', body)
                data.append('index', partNumber)
                data.append('uploadId', uploadId)
                service.post('/file/multi/upload', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: onUploadProgress,
                    cancelToken: new Axios.CancelToken(cancel),
                    timeout: 1000 * 300
                })
                    .then(res => {
                        if (res.code === '200') {
                            resolve({
                                code: 0,
                                data: {
                                    partNumber,
                                    ETag: res.data
                                }
                            })
                        } else {
                            reject(new Error(res.msg))
                        }
                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    }

    //完成分片上传
    completeMultipartUpload({bucket, key, uploadId, parts = []}) {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: bucket,
                Key: key,
                UploadId: uploadId,
                MultipartUpload: {
                    Parts: parts.map(item => {
                        return {
                            ETag: item.etag.replace(/"/ig, ""),
                            PartNumber: item.partNumber
                        }
                    })
                }
            };
            this.client.completeMultipartUpload(params, function (err, data) {
                if (err !== null) {
                    reject(wrapperError(err))
                } else {
                    resolve(wrapperResult(data))
                }
            })
        })
    }

    getObjectUrl({bucket, key, Expires = 900, versionId}) {
        const params = {Bucket: bucket, Key: key, Expires};
        if (versionId) {
            params.VersionId = versionId
        }
        return this.client.getSignedUrl('getObject', params);
    }

    getS3Instance() {
        return this.client
    }
}
