import Vue from 'vue'
import './plugin/elementui'
import './plugin/ajax'
import './plugin/awesome'
import './plugin/extension'
// import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store'
// import Components from './components'
import Directives from './directive'
import Filter from './filter'
import './assets/common.css';
import './assets/pager.scss'
import axios from "axios";
import Cookie from 'js-cookie'
import 'core-js/stable'
import {RfStatusList} from "@/utils/rfUtil";
import JsEncrypt from 'jsencrypt';




import Dropdown from "./views/components/dropdown/index";
import storage from "@/utils/storage";

Vue.prototype.$dropdown = Dropdown
Vue.prototype.$RfStatusList = RfStatusList

// Vue.use(Components)
Vue.use(Directives)
Vue.use(Filter)


Vue.config.productionTip = false
const bounceLog = {}
Vue.prototype.$debounce = function (method, wait = 0) {
    const uid = this._uid;
    const _this = this;
    if (bounceLog[uid]) {
        clearTimeout(bounceLog[uid])
        bounceLog[uid] = null
    }
    bounceLog[uid] = setTimeout(function () {
        _this[method]()
    }, wait)
}

Vue.filter('formatSize', function (limit) {
    let size = "";
    if (limit < 0.1 * 1024) {
        size = limit.toFixed(2)
    } else if (limit < 0.1 * 1024 * 1024) {
        size = (limit / 1024).toFixed(2)
    } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        size = (limit / (1024 * 1024)).toFixed(2)
    } else if (limit < 0.1 * 1024 * 1024 * 1024 * 1024) {
        size = (limit / (1024 * 1024 * 1024)).toFixed(2)
    } else {
        size = (limit / (1024 * 1024 * 1024 * 1024)).toFixed(2)
    }

    let sizeStr = size + "";
    let index = sizeStr.indexOf(".");
    let dou = sizeStr.substr(index + 1, 2)
    if (dou == "00") {
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
    }
    return size;
})

Vue.filter('formatUnit', function (size) {
    let unit = '';
    if (size < 0.1 * 1024) {
        unit = 'B'
    } else if (size < 0.1 * 1024 * 1024) {
        unit = 'K'
    } else if (size < 0.1 * 1024 * 1024 * 1024) {
        unit = 'M'
    } else if (size < 0.1 * 1024 * 1024 * 1024 * 1024) {
        unit = 'G'
    } else {
        unit = 'T'
    }
    return unit;
})

Vue.prototype.$isClintUpload = () => {
    let type = storage.get('UPLOAD_TYPE')
    return type === 'client'
}


Vue.prototype.$encruption = function(obj) {
    let encrypt = new JsEncrypt();
    // 公钥

    encrypt.setPublicKey(
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDQgJgAcB/Rcolx6EabTmrDt5dIBFbn8XqLyGmWFBUJif/r+Owv5ftkzY5P5rCj6q22+WzisTG4AO17q4Zx3SCSv769CDCRGQp22kn83sjTymmWPf1sTNahMeaHHbhrIc5+uQkgFH6a1jJFkTFB8c17uFFfr+bAptRayTIZtkJ/gwIDAQAB'
    );
    return encrypt.encrypt(obj);
};

window._SOCKET_TEST_COUNT_ = 0

//
axios.get('/config.json', {})
    .then(res => {
        sessionStorage.setItem('__env__', JSON.stringify(res.data))
        sessionStorage.setItem('SUPERSTOR_TOKEN', (res.data.service || res.data.SERVICE))
        storage.set('UPLOAD_TYPE', (res.data.upload_type || res.data.UPLOAD_TYPE))
        Vue.prototype.MAX_FILE_SIZE = res.data.MAX_FILE_SIZE
        Vue.prototype.IS_SSC = true
        localStorage.setItem('regionType', res.data.REGION_TYPE)
        store.commit('user/SET_PERMISSIONS', res.data.REGION_TYPE)
        Vue.use(require('./components').default)
        // this.$postJson('/region/listPurchased')
        //     .then(res => {
        //         if (res.code === '200') {
        //             this.regionList = res.data
        //             if (res.data && res.data.length > 0) {
        //                 this.regionId = res.data[0].regionId
        //                 storage.set('REGION_ID', this.regionId)
        //             }
        //         }
        //     })
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })




