<template>
    <div class="si-view"> </div>
</template>

<script>
    export default {
        name: 'SpaceItem',
    }
</script>
<style scoped lang="scss">
    .si-view{
        display: inline-block;
        width: 10px;
        height: 100%;
    }
</style>
