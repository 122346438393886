<template>
    <div class="error-page">
        <img class="img-error img-top-space" src="../assets/500_2.png" style="width: 300px;height: 221px;"/>
        <div class="error-msg">服务器内部错误</div>
        <div class="btn-back" @click="$router.back(-1)">返回上一页</div>
    </div>
</template>
<script>
export default {
    name: 'Error'
}
</script>
<style>
.error-page {
    border: solid 16px #F7F7F7;
    border-bottom: solid 10px #F7F7F7;

  background: #FFFFFF;
  box-shadow: 1px 1px 4px 0px #F0F2F5;
  border-radius: 2px;
}
</style>
