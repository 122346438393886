const storage = window.sessionStorage;
import Cookie from 'js-cookie'
const TokenKey = 'token'
const RegionKey = 'Admin-Region'
const UserInfoKey = 'Admin-UserInfo'
const CＭUserInfoKey = 'CM-UserInfo'
const RegionList = 'Region-List'
import moment from 'moment'
export function setRegionList (list) {
    return storage.setItem(RegionList, JSON.stringify(list))
}

export function getRegionList () {
    return JSON.parse(storage.getItem(RegionList))
}

export function getToken() {
    return Cookie.get(TokenKey) || storage.getItem(TokenKey)
}

export function setToken(token) {
    return storage.setItem(TokenKey, token)
}

export function removeToken() {
    return storage.removeItem(TokenKey)
}

export function setRegion(region) {
    return storage.setItem(RegionKey, JSON.stringify(region))
}

export function removeRegion() {
    return storage.removeItem(RegionKey)
}

export function getRegion() {
    return JSON.parse(storage.getItem(RegionKey))
}
export function getUserInfo() {
    return JSON.parse(storage.getItem(UserInfoKey))
}

export function setUserInfo(userInfo) {
    return storage.setItem(UserInfoKey, JSON.stringify(userInfo))
}
export function getCMUserInfo() {
    return JSON.parse(storage.getItem(CＭUserInfoKey))
}

export function setCMUserInfo(userInfo) {
    return storage.setItem(CＭUserInfoKey, JSON.stringify(userInfo))
}

const MetaList = 'META_LIST'
sessionStorage.setItem(MetaList, JSON.stringify(new Object()))

export function addMeta ({id, status}) {
    let list = JSON.parse(sessionStorage.getItem(MetaList)) || {}
    list[id] = {status, expireTime: moment().add(1, 'minutes').valueOf()}
    sessionStorage.setItem(MetaList, JSON.stringify(list));
}

export function deleteMeta (id) {
    let list = JSON.parse(sessionStorage.getItem(MetaList)) || {}
    for (const listKey in list) {
        if (listKey.indexOf(id) > -1) {
            delete list[listKey]
            console.log('删除meta缓存：', listKey)
            sessionStorage.setItem(MetaList, JSON.stringify(list));
        }
    }
}

export function findMeta (id) {
    let list = JSON.parse(sessionStorage.getItem(MetaList)) || {}
    if (list[id]) {
        let meta = list[id]
        if (moment(meta.expireTime) < moment()) {
            delete list[id]
            sessionStorage.setItem(MetaList, JSON.stringify(list));
            return null
        } else {
            return meta
        }
    }
}
