import service from '@/utils/request'
import moment from 'moment'
import {getToken, setToken, setUserInfo, setCMUserInfo} from '@/utils/session'
import {cache} from '@/utils/cache'
import {getEnv} from "@/utils/env";

const uploadNOTipKey = 'uploadTip'
const UPLOAD_NO_TIP_VALUE_TRUE = '1'
const UPLOAD_NO_TIP_VALUE_FALSE = '0'
import Vue from 'vue'
import Info from "@/views/info";

const permissions = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: '概览',
            hidden: false,
            accountType: ['main', 'sub']
        },
        component: 'Home.vue'
    },
    {
        path: '/bucket',
        name: 'Bucket',
        meta: {
            title: '桶管理',
            hidden: false,
            accountType: ['main', 'sub']
        },
        redirect: '/bucket/list',
        component: 'bucket/Index.vue',
        children: [{
            path: 'list',
            name: 'BucketList',
            hidden: true,
            meta: {
                title: '桶管理',
            },
            component: 'bucket/List.vue'
        }, {
            path: 'file',
            name: 'File',
            hidden: true,
            meta: {
                title: '文件管理',
            },
            component: 'bucket/File.vue'
        }]
    },
    {
        path: '/aksk',
        name: 'aksk',
        meta: {
            title: '认证管理',
            hidden: false,
            accountType: ['main', 'sub']
        },
        component: 'AkSk.vue'
    },
    {
        path: '/syslog',
        name: 'sysLog',
        meta: {
            title: '日志审计',
            hidden: false,
            accountType: ['main']
        },
        component: 'SysLog.vue'
    },
    {
        path: '/accessControl',
        name: 'accessControl',
        meta:{title: '访问控制',
            hidden: false,
            accountType: ['main']
        },
        component: 'accessControl/index.vue',
        redirect: '/accessControl/list',
        children: [
            {
                path: 'list',
                name: 'accessControlList',
                hidden: true,
                meta:{
                    title: '设置权限',
                },
                component: 'accessControl/list.vue'
            },
            {
                path: 'manager',
                name: 'accessControlManager',
                hidden: true,
                meta:{
                    title: '设置权限',
                },
                component: 'accessControl/manager.vue'
            }
        ]
    },

    {
        path: '/info',
        name: 'info',
        component: 'info.vue',
        meta: {
            title: '基本资料',
            hidden: true,
        }
    },
    {
        path: '/tag',
        name: 'tag',
        component: 'Tag.vue',
        meta: {
            title: '标签管理',
            hidden: false,
            accountType: ['main']
        }
    },
    {
        path: '/offline_task',
        name: 'offline_task',
        component: 'offline/Task.vue',
        meta: {
            title: '离线任务管理',
            hidden: false,
            accountType: ['main']
        }
    },
    {
        path: '/otd',
        name: 'offline_task_detail',
        component: 'offline/TaskDetail.vue',
        meta: {
            title: '离线任务详情',
            hidden: true
        }
    },
    {
        path: '/offline_cassette',
        name: 'offline_cassette',
        component: 'offline/Cassette.vue',
        meta: {
            title: '离线盘匣管理',
            hidden: false,
            accountType: ['main']
        }
    },
    {
        path: '/octl',
        name: 'offline_task_list',
        component: 'offline/TaskList.vue',
        meta: {
            title: '离线任务列表',
            hidden: true
        }
    },
    {
        path: '/userlevelSearch',
        name: 'userlevelSearch',
        component: 'userlevelSearch.vue',
        meta: {
            title: '用户级搜索',
            hidden: false,
            accountType: ['main', 'sub']
        }
    },
    //
    {
        path: '/userManager',
        name: 'userManager',
        component: 'userManager/userManage.vue',
        meta: {
            title: '用户管理',
            hidden: false,
            accountType: ['main']
        }
    },
    {
        path: '/createUser',
        name: 'createUser',
        component: 'userManager/createUser.vue',
        meta: {
            title: '创建用户',
            hidden: true,
            accountType: ['main']
        }
    },
    {
        path: '/messageManage',
        name: 'messageManage',
        component: 'message/messageManage.vue',
        meta: {
            title: '消息管理',
            hidden: false,
            accountType: ['main']
        }
    },
    {
        path: '/createMessage',
        name: 'createMessage',
        component: 'message/createMessage.vue',
        meta: {
            title: '发布消息',
            hidden: true,
            accountType: ['main']
        }
    },
    {
        path: '/messageDetail',
        name: 'messageDetail',
        component: 'message/messageDetails.vue',
        meta: {
            title: '消息详情',
            hidden: true,
            accountType: ['main']
        }
    },
    {
        path: '/license',
        name: 'license',
        component: 'license.vue',
        meta: {
            title: 'License授权',
            hidden: false,
            accountType: ['main']
        }
    },
    {
        path: '/overview',
        name: 'overview',
        component: 'overView.vue',
        meta: {
            title: '概览',
            hidden: false,
            accountType: ['main']
        }
    },
]




const state = {
    constants: {
        UPLOAD_NO_TIP_VALUE_TRUE,
        UPLOAD_NO_TIP_VALUE_FALSE,
    },
    token: getToken() || '',
    uploadTip: cache(uploadNOTipKey),
    userData: {},
    cmUserInfo: {},
    insertRoutes: false,
    permissions,
    isExpire: false,
    spaceStatus: {},
    accountType: 'sub',
    restoreRemindStatus: '',
    publicNetSwitch: false, // 是否有公网环境
    regionId: '', //全局切换资源池
    license: {},
    isAdmin: true,
    unreadMessage: 0,
    appName: ''
}

const mutations = {
    SET_APP_NAME(state, l) {
        state.appName = l
    },
    SET_UNREAD_MESSAGE (state, c) {
        state.unreadMessage = c
    },
    SET_LICENSE(state, l) {
        state.license = l
    },
    SET_REGIONID (state, id) {
        console.log('SET_REGIONID:',id)
        state.regionId = id
    },
    SET_PUBLIC_NET_SWITCH (state, publicNetSwitch) {
        state.publicNetSwitch = publicNetSwitch
    },
    SET_PERMISSIONS: (state, regionType) => {
        if (regionType === 'GDAS') {
            let p = permissions.slice(0, 4)
            let p2 = permissions.slice(5)
            // p.push(permissions[5])
            // p.push(permissions[6])
            // p.push(permissions[7])
            // p.push(permissions[8])
            // p.push(permissions[9])
            // p.push(permissions[10])
            // p.push(permissions[11])
            state.permissions = [...p, ...p2]
        } else {
            state.permissions = [...permissions]
        }
    },
    SET_TOKEN: (state, token) => {
        state.token = token
        setToken(token)
    },
    SET_ACCOUNTTYPE: (state, accountType) => {
        state.accountType = accountType
    },
    SET_RESTOREREMINDSTATUS: (state, restoreRemindStatus) => {
        state.restoreRemindStatus = restoreRemindStatus
    },
    SET_USER_INFO: (state, userData) => {
        state.userData = {...state.userData, ...userData}
        state.accountType = state.userData.subType === 0 ? 'main' : 'sub'
        setUserInfo(state.userData)
    },
    SET_CM_USER_INFO: (state, cmUserInfo) => {
        state.cmUserInfo = {...state.cmUserInfo, ...cmUserInfo}
        setCMUserInfo(state.cmUserInfo)
    },
    SET_UPLOAD_TIP: (state, uploadTip) => {
        state.uploadTip = uploadTip
        cache(uploadNOTipKey, uploadTip)
    },
    SET_IS_EXPIRE: (state, isExpire) => {
        state.isExpire = isExpire;
    },
    SET_SPACE_STATUS: (state, spaceStatus) => {
        state.spaceStatus = spaceStatus;
    },
    SET_INSERT_ROUTES: (state, insertRoutes) => {
        state.insertRoutes = insertRoutes;
    },
}

const actions = {
    // user login
    checkSpace({commit}, {regionId}) {
        return service.postJson("/dashboard/stat/space?regionId=" + regionId).then((res) => {
            const {usedSize, remainSize, totalSize} = res.data
            const spaceStatus = {
                totalSize: totalSize,
                remainSize: remainSize,
                usedSize: usedSize,
            }
            commit('SET_SPACE_STATUS', spaceStatus);
            return spaceStatus
        });
    },
    login({commit, state}) {
        return new Promise((resolve, reject) => {
            if (state.userData && state.userData.id) {
                resolve(state.userData)
            }
        })
    },
    frozen({commit, state}) {
        state.userData.status = '2'
        commit('SET_USER_INFO', state.userData)
    },
    checkUserInfo({commit, state}) {
        return service.post("/dashboard/user").then((res) => {
            commit('SET_USER_INFO', res.data)
            return res.data
        });
    },
    checkIsExpire({commit, state}) {
        let expireTime = moment(state.userData.expireTime);
        let currentTime = moment();
        let isExpire = false;
        if (currentTime.isAfter(expireTime)) {
            isExpire = true
        }
        commit('SET_IS_EXPIRE', isExpire);
        return isExpire;
    },
    insertRoutes({commit, state}) {
        commit('SET_INSERT_ROUTES', true);
        return true;
    },
    uploadConfirmNoTip({commit}, {value}) {
        if (!!value) {
            commit('SET_UPLOAD_TIP', UPLOAD_NO_TIP_VALUE_TRUE)
        } else {
            commit('SET_UPLOAD_TIP', UPLOAD_NO_TIP_VALUE_FALSE)
        }
        return true
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
