<template>
  <el-link
      v-else
      :underline="underline"
      @click="onClick"
      :title="title"
      :style="style || (IS_SSC ? 'color: #ff8700;' : '')"
      :disabled="disabled"><slot></slot></el-link>
</template>

<script>
  import {mapGetters} from "vuex";
  export default {
    name: "OptLink",
    props:['underline','disabled', 'title', 'style'],
    computed: {
      ...mapGetters(["userIsFrozen"]),
      optDisabledTip(){
        const {userIsFrozen} = this
        return userIsFrozen ? '服务已到期，不支持该操作': ''
      },
    },
    methods:{
      onClick(){
        this.$emit('click')
      }
    }
  };
</script>
