import axios from "axios";
import Cookie from 'js-cookie'
import Vue from 'vue'
import store from "@/store";
import router from "@/router";

export function bytesToSize(bytes, fixed = 2) {

    if (bytes === 0) return '0 B';

    if (!bytes) {
        return ' '
    }

    if (bytes < 1 && bytes >0) {
        return bytes + ' B'
    }

    let k = 1024;

    let sizes = ['B','K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

    let i = Math.floor(Math.log(bytes) / Math.log(k));

    let num = bytes / Math.pow(k, i);
    return num.toFixed(fixed) + ' ' + (sizes[i] || '');

    //return (bytes / Math.pow(k, i)) + ' ' + sizes[i];
    //toPrecision(3) 后面保留一位小数，如1.0GB //return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}



// export const keepSign = function (userName = 'fxltest09', password = 'ehl123456!', gateway = '172.38.90.135') {
export const keepSign = function (userName = 'admin', password = 'Ehl12345', gateway = '172.38.90.94') {
    return new Promise(function (resolve) {
        resolve()
    })

}



const  downloadZip = (res, name) => {
    const temp = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(temp, name);
    } else {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(temp);
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}

let flag = false

export const licenseExpired = () => {
    if (flag) {
        return;
    }
    let pathname = window.location.pathname
    if (pathname.indexOf('login') > -1 || pathname.indexOf('license') > -1) {
        return
    }
    let isAdmin = store.getters.isAdmin

    let message = isAdmin ? '授权已过期，请授权后再操作。' : '授权已过期，请联系超管人员进行授权后再操作。'
    flag = true
    Vue.prototype.$confirm(message, {
        confirmButtonText: '确定',
        showCancelButton:false,
        type: 'warning',
        dangerouslyUseHTMLString: true,
    })
        .then(() => {
            if (isAdmin) {
                router.push('/license')
            } else {
                if (window.location.pathname.indexOf('login') === -1) {
                    router.push('/login')
                }
            }
            flag = false
        })
}



export {
    downloadZip
}