import service from "@/utils/request";
import de from "element-ui/src/locale/lang/de";
import Vue from 'vue'
const state = {
  bucketList: [],
};

const mutations = {
  SET_BUCKET_LIST: (state, bucketList) => {
    state.bucketList = bucketList;
  },
  UNSHIFT_BUCKET: (state, bucket) => {
    state.bucketList.unshift(bucket);
  },
  DELETE_BUCKET: (state, bucket) => {
    const index = state.bucketList.findIndex((item) => item.name === bucket);
    state.bucketList.splice(index, 1);
  },
};

const actions = {
  exists({ commit, state }, { bucketName, regionId }) {
    return service.postJson("/bucket/exists", { bucketName, regionId }).then((res) => {
      console.log(23344, res)
      return res.data;
    });
  },
  stat({ commit, state }, { bucketName }) {
    return service.postJson("/bucket/stat", [bucketName]).then((res) => {
      return res.data;
    });
  },
  create({ commit, state }, { bucketName, regionId, quota }) {
    return service.postJson("/bucket/create", { bucketName, regionId, quota }).then((res) => {
      commit("UNSHIFT_BUCKET", res.data);
      return true;
    });
  },
  delete({ commit, state }, { bucketName, regionId }) {
    return service.postJson("/bucket/delete", { bucketName,  regionId }).then((res) => {
      commit("DELETE_BUCKET", bucketName);
      return true;
    });
  },
  async getBucketList({ commit, state }, { current, size, keyword, init, regionId }) {
    if (init) {
      commit("SET_BUCKET_LIST", []);
    }
    const getBucketListFromServer = (regionId) => {
      let url = '/bucket/list'
      if (regionId) {
        url += '?regionId=' + regionId
      }
      return service.postJson(url).then((res) => {
        const { data = [] } = res;
        commit("SET_BUCKET_LIST", data);
        return res;
      }).catch(e => {
          // Vue.prototype.$message.error(e.message)
      });
    };

    const sliceFileList = (state, start, end, keyword, regionId) => {
      const { bucketList } = state;
      // const resultList = keyword
      //   ? bucketList.filter((item) => {
      //       return item.name.indexOf(keyword) > -1;
      //     })
      //   : bucketList;
      const resultList = bucketList.filter(item => {
        return (!keyword || item.name.indexOf(keyword) > -1) && (!regionId || item.regionId === regionId)
      })
      const list = resultList.slice(start, end);
      return { list, total: resultList.length };
    };

    const start = (current - 1) * size;
    const end = current * size;

    if (init) {
      await getBucketListFromServer(regionId);
    }
    return sliceFileList(state, start, end, keyword, regionId);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
