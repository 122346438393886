import service from '@/utils/request'
import {setRegion,getRegion} from '@/utils/session'
import storage from "@/utils/storage";
const state = {
  points: [],
  currentPoint: {},
  storageClass: [], // 当前资源池支持的存储类型
}

const mutations = {
  SET_POINTS: (state, points) => {
    state.points = points
    const regionId = storage.get('REGION_ID')
    const curPoint = points.find(item => item.regionId === regionId)
    console.log('curPoint:', curPoint)
    if (curPoint) {
      state.currentPoint = curPoint
    }
  },
  SET_STORAGE_CLASS: (state, storageClass = []) => {
    state.storageClass = storageClass
  },
  SET_CURRENT_POINTS: (state, point) => {
    state.currentPoint = point
    setRegion(point)
  }
}

const actions = {
  initPoints({ commit, state}){
    service.post('/region/list').then(res =>{
      const points = res.data
      commit('SET_POINTS', points)
      if(!state.currentPoint){
        commit('SET_CURRENT_POINTS', points[0].pools[0])
      }
    })
  },
  changePoint({ commit },{point}){
    commit('SET_CURRENT_POINTS',point)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}