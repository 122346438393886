<template>
    <div class="restore-task-container">
        <el-dialog title="解冻列表" custom-class="restore-task" :visible.sync="visible" append-to-body
                   :close-on-click-modal="false" width="1000px" z-index="2001">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane name="1">
                    <span slot="label">解冻完成({{ restoreCount.restoreFinish }})</span>
                    <div class="btns">
                        <div>
                            <el-button type="primary" size="small" style="margin-right: 10px;" @click="handleRemoveAll('RESTORED')">移除全部记录</el-button>
                            <el-button type="primary" disabled v-if="!finishList || finishList.filter(i => i.status === 'RESTORED').length === 0" size="small" @click="downloadAll">下载当前页全部</el-button>
                            <el-button type="primary" v-else size="small" @click="downloadAll">下载当前页全部</el-button>
                            <el-tooltip slot="suffix" content="如下载失败，请检查浏览器是否拦截下载任务" placement="top">
                                <span class="vol-tip"><i class="fa fa-question-circle-o" style="font-size: 16px;"></i></span>
                            </el-tooltip>
                        </div>
                        <div>
                            <span>{{ restoreCount.restoreFinish }}个文件，共计{{ restoreCount.totalSize }}</span>
                            <OptButton
                                size="small" icon="icon iconfont iconbianzu1711"
                                style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                                   @click="getRestoreList"></OptButton>
                        </div>
                    </div>
                    <el-table :data="finishList" v-loading="loading" height="400px" style=""
                              header-cell-class-name="task-cell">
<!--                        <el-table-column type="selection" width="60" show-overflow-tooltip></el-table-column>-->
                        <el-table-column label="文件" prop="fileName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="所在资源池" prop="regionName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="所在路径" prop="filePath" show-overflow-tooltip></el-table-column>
                        <el-table-column label="文件大小" prop="restoreByte" show-overflow-tooltip>
                            <template slot-scope="{$index, row}">{{ bytesToSize(row.restoreByte) }}</template>
                        </el-table-column>
                        <el-table-column label="发起解冻时间" prop="restoreTime" show-overflow-tooltip>
                            <template slot-scope="{$index, row}">
                                {{ moment(row.restoreTime).format('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                        <el-table-column label="解冻状态" prop="status" show-overflow-tooltip>
                            <template slot-scope="{$index, row}">
                                {{ {RESTORED: '解冻成功', RESTORED_DELAY: '解冻成功但已超过下载时限'}[row.status] }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" prop="action" width="150">
                            <template slot-scope="{$index, row}">
                                <div style="display: flex;justify-content: space-around;" v-show="row.status === 'RESTORED'">
                                    <span v-show="row.status === 'RESTORED'" class="table-action-button" @click="handleDownload(row)">下载</span>
                                    <span v-show="row.status === 'RESTORED'" class="table-action-button" @click="handleRemove(row)">移除</span>
                                    <span v-show="row.status === 'RESTORED'" class="table-action-button" @click="handleDelay(row)">延期</span>
                                    <span v-show="row.status === 'RESTORED_DELAY'" class="table-action-button" @click="handleRestoreAgain(row)">重新解冻</span>
                                    <span v-show="row.status === 'RESTORED_DELAY'" class="table-action-button" @click="handleRemove(row)">移除</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pager v-model="pager1" :sizes="[5, 10, 20]" @currentChange="handleCurrentChange1"
                           @sizeChange="handleSizeChange1"></Pager>
                </el-tab-pane>
                <el-tab-pane label="2" name="2">
                    <span slot="label">正在解冻({{ restoreCount.restoreIng }})</span>
                    <div class="btns">
                        <div></div>
                        <OptButton size="small" icon="icon iconfont iconbianzu1711"
                                   style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                                   @click="getRestoreList"></OptButton>
                    </div>
                    <el-table :data="restoreList" v-loading="loading" height="400px" style=""
                              header-cell-class-name="task-cell">
                        <el-table-column label="文件" prop="fileName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="所在资源池" prop="regionName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="所在路径" prop="filePath" show-overflow-tooltip></el-table-column>
                        <el-table-column label="文件大小" prop="restoreByte" show-overflow-tooltip>
                            <template slot-scope="{$index, row}">{{ bytesToSize(row.restoreByte) }}</template>
                        </el-table-column>

                        <el-table-column label="发起解冻时间" prop="restoreTime" show-overflow-tooltip>
                            <template slot-scope="{$index, row}">
                                {{ moment(row.restoreTime).format('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pager v-model="pager2" :sizes="[5, 10, 20]" @currentChange="handleCurrentChange2"
                           @sizeChange="handleSizeChange2"></Pager>
                </el-tab-pane>
                <el-tab-pane label="3" name="3">
                    <span slot="label">解冻失败({{ restoreCount.restoreFail }})</span>
                    <div class="btns">
                        <div>
                            <el-button type="primary" size="small" style="margin-right: 10px;" @click="handleRemoveAll('FAIL')">移除全部记录</el-button>
                            <el-button type="primary" size="small" @click="handleAllAgain">全部重试</el-button>
                        </div>
                        <OptButton size="small" icon="icon iconfont iconbianzu1711"
                                   style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                                   @click="getRestoreList"></OptButton>
                    </div>
                    <el-table :data="failList" v-loading="loading" height="400px" style=""
                              header-cell-class-name="task-cell">
<!--                        <el-table-column type="selection" width="60" show-overflow-tooltip></el-table-column>-->
                        <el-table-column label="文件" prop="fileName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="所在资源池" prop="regionName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="所在路径" prop="filePath" show-overflow-tooltip></el-table-column>
                        <el-table-column label="文件大小" prop="restoreByte" show-overflow-tooltip>
                            <template slot-scope="{$index, row}">{{ bytesToSize(row.restoreByte) }}</template>
                        </el-table-column>
                        <el-table-column label="发起解冻时间" prop="restoreTime" show-overflow-tooltip>
                            <template slot-scope="{$index, row}">
                                {{ moment(row.restoreTime).format('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                        <el-table-column label="解冻状态" prop="status" show-overflow-tooltip>
                            <template slot-scope="{$index, row}"> 解冻失败</template>
                        </el-table-column>
                        <el-table-column label="失败原因" prop="failReason" show-overflow-tooltip></el-table-column>
                        <el-table-column label="操作" prop="action" width="100">
                            <template slot-scope="{$index, row}">
                                <div style="display: flex;justify-content: space-around;">
                                    <span class="table-action-button" @click="handleRestoreAgain(row)">重试</span>
                                    <span class="table-action-button" @click="handleRemove(row)">移除</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pager v-model="pager3" :sizes="[5, 10, 20]" @currentChange="handleCurrentChange3"
                           @sizeChange="handleSizeChange3"></Pager>
                </el-tab-pane>

            </el-tabs>

            <!--        <span slot="footer" class="dialog-footer">-->
            <!--              <el-button size="small" @click="showCreate=false">取 消</el-button>-->
            <!--              <el-button size="small" type="primary" @click="doCreate">确 定</el-button>-->
            <!--        </span>-->


        </el-dialog>
        <el-dialog custom-class="changeConfirm" top="35vh" :title="'文件解冻'" @close="showThawDialog = false"
                   :close-on-click-modal="false"
                   :visible.sync="showThawDialog" width="484px" z-index="2005">
            <div class="cc-body"><i class="el-alert__icon el-icon-warning"></i>当前排队人数为{{
                    queueUserCount
                }}人，排队文件为{{ queueKeyCount }}个，文件解冻完成后，请及时下载。
            </div>
            <div class="cc-footer thaw">
                <el-button  @click="showThawDialog = false">取消</el-button>
                <el-button type="primary" @click="confirmThawFile">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import {bytesToSize} from "@/utils";
import {S3} from "@/utils/aws";
import {deleteMeta} from "@/utils/session";

export default {
    name: "restore-task",
    data() {
        return {
            visible: false,
            activeName: '1',
            list: [],
            loading: false,
            pager1: {
                current: 1,
                size: 10,
                total: 0,
            },
            pager2: {
                current: 1,
                size: 10,
                total: 0,
            },
            pager3: {
                current: 1,
                size: 10,
                total: 0,
            },
            finishList: [],
            restoreList: [],
            failList: [],
            queueUserCount: '',
            queueKeyCount: '',
            dbClick: '',
            restoreAgainRow: {},
            showThawDialog: false,
            restoreCount: {
                restoreFail: 0,
                restoreFinish: 0,
                restoreIng: 0,
                totalSize: '0'
            }
        }
    },
    computed: {},
    methods: {
        moment,
        bytesToSize,
        getRestoreCount () {
            this.$postJson('/file/restore/queue/count')
            .then(res => {
                if (res.code === '200') {
                    this.restoreCount.restoreFail = res.data.restoreFail
                    this.restoreCount.restoreFinish = res.data.restoreFinish
                    this.restoreCount.restoreIng = res.data.restoreIng
                    this.restoreCount.totalSize = bytesToSize(res.data.totalSize)
                }
            })
        },
        show() {
            this.visible = true
            this.activeName = '1'
            this.getRestoreList()
        },
        getRestoreList() {
            const {activeName, pager1, pager2, pager3} = this;
            let data = {restoreStatus: ''}
            switch (activeName) {
                case '1':
                    data.restoreStatus = 'RESTORED'
                    data.pageNum = pager1.current
                    data.pageSize = pager1.size
                    break;
                case '2':
                    data.restoreStatus = 'RESTORE_ING'
                    data.pageNum = pager2.current
                    data.pageSize = pager2.size
                    break;
                case '3':
                    data.restoreStatus = 'FAIL'
                    data.pageNum = pager3.current
                    data.pageSize = pager3.size
                    break;
            }
            this.loading = true
            this.$postJson('/file/restore/queue/list', data)
                .then(res => {
                    if (res.code === '200') {
                        if (activeName === '1') {
                            this.finishList = res.data.records
                            this.pager1.total = res.data.total
                        } else if (activeName === '2') {
                            this.restoreList = res.data.records
                            this.pager2.total = res.data.total
                        } else if (activeName === '3') {
                            this.failList = res.data.records
                            this.pager3.total = res.data.total
                        }
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            this.getRestoreCount();
        },
        handleClick(val) {
            this.getRestoreList()
        },
        handleCurrentChange1(cur) {
            this.pager1.current = cur
            this.getRestoreList()
        },
        handleSizeChange1(size) {
            this.pager1.current = 1
            this.pager1.size = size
            this.getRestoreList()
        },
        handleCurrentChange2(cur) {
            this.pager2.current = cur
            this.getRestoreList()
        },
        handleSizeChange2(size) {
            this.pager2.current = 1
            this.pager2.size = size
            this.getRestoreList()
        },
        handleCurrentChange3(cur) {
            this.pager3.current = cur
            this.getRestoreList()
        },
        handleSizeChange3(size) {
            this.pager3.current = 1
            this.pager3.size = size
            this.getRestoreList()
        },
        handleRemove(row) { // 移除
            this.$postJson('/file/restore/remove', {bids: [row.bid]})
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功');
                        (this['pager' + this.activeName]).current = 1;
                        this.getRestoreList();
                    }
                })
        },
        handleDelay(row) {
            let data = {bids: []}
            if (row) {
                data.bids.push(row.bid)
            }
            this.$postJson('/file/restore/delay', data)
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功');
                        this.getRestoreList();
                    }
                })
        },
        handleDownload(row) {
            const s3 = new S3(row.s3Host, row.accessKey, row.secretKey)
            const url = s3.getObjectUrl({
                bucket: row.bucket,
                key: row.key,
            });
            window.open(url)
        },
        downloadAll () {
            const {finishList} = this;
            for (let i = 0; i < finishList.length; i++) {
                if (finishList[i].status === 'RESTORED_DELAY') {
                    continue
                }
                this.handleDownload(finishList[i])
            }
        },
        handleRestoreAgain(row) {
            this.$postJson("/file/restore/queue?regionId=" + row.regionId).then((res) => {
                this.queueUserCount = res.data.userCount;
                this.queueKeyCount = res.data.keyCount;
                if (this.queueUserCount == 0) {
                    this.confirmThawFile(row);
                } else {
                    this.showThawDialog = true;
                    this.restoreAgainRow = row
                }
            });
        },
        confirmThawFile(row) {
            if (!row || !row.key) {
                row = this.restoreAgainRow
            }
            console.log('重试。。。', row)
            if (Date.now() - this.dbClick < 1000) { // 防止二次点击
                console.log('二次点击无效。。。')
                this.showThawDialog = false;
                return;
            }
            this.showThawDialog = false;
            this.dbClick = Date.now();
            this.$postJson("/file/restore", {bucketName: row.bucket, keysAndVersions: [{key: row.key,versionId: row.versionId}], regionId: row.regionId})
                .then((res) => {
                    if (res.code === '200') {
                        this.$message.success("申请解冻成功");
                        // 解冻成功后，删除本地缓存
                        deleteMeta(row.bucket + row.key)
                        this.getRestoreList()
                    } else {
                        this.$message.error(`文件解冻失败，原因为${res.msg},请联系客服人员进行处理。`);
                    }
                })
                .catch((e) => {
                    this.showThawDialog = false;
                    this.$message.error("文件解冻失败，原因为系统异常,请联系客服人员进行处理。");
                })
                .finally(() => {
                })
        },
        handleRemoveAll (restoreStatus) {
            this.$postJson('/file/restore/remove', {restoreStatus})
            .then(res => {
                if (res.code === '200') {
                    this.$message.success('操作成功');
                    (this['pager' + this.activeName]).current = 1;
                    this.getRestoreList()
                }
            })
        },
        handleAllAgain () {
            this.$postJson('/file/restore/all')
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功');
                        this.getRestoreList()
                    }
                })
        }
    }
}
</script>

<style scoped lang="scss">
.btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

}

.restore-task-container ::v-deep.changeConfirm {
    .el-dialog__header {
        //display: none;
        border: none;
        padding: 30px 30px 0;

        .el-dialog__headerbtn {
            top: 30px;
            right: 30px;
        }
    }
}
.cc-body {
    width: 100%;
    margin: 0 auto 18px;
    text-align: justify;
    font-size: 14px;
    color: #333;
    position: relative;
    [class^="el-icon-"],
    [class*=" el-icon-"] {
        font-size: 14px;
        color: #FAAD14;
        position: absolute;
        left: -17px;
        top: 3px;
    }
}
.cc-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px -50px 0 -40px;

    .no-again-tip {
        width: 100px;
        flex-grow: 1;
        flex-shrink: 1;

        ::v-deep.el-checkbox__label {
            font-size: 12px;
            color: #333;
        }
    }

    .el-button {
        width: 78px;
        height: 32px;
        line-height: 32px;
        padding: 0px;
        border-radius: 3px;
        font-size: 12px;
    }
}
</style>
