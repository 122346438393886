<template>
    <div class="user-info">
        <div class="header">基本资料</div>
        <div class="main">
            <div class="title"><b></b>用户信息</div>
            <div class="content">
                <div class="avatar"></div>
                <div class="content-list">
                    <div class="content-item">
                        <label>用户账号：</label>
                        <span>
                            {{userData.username || 123 }}

                        </span>
                    </div>
                    <div class="content-item">
                        <label>手机号码：</label>
                        <span>{{userData.phone}}
                            <span class="edit" @click="showEditAccount" >
                                <i class="icon iconfont iconedit"></i>
                            </span>
                        </span>
                    </div>
                    <div class="content-item">
                        <label>登录密码：</label>
                        <span>********
                            <span class="edit" @click="showEditPass" >
                                <i class="icon iconfont iconedit"></i>
                            </span>
                        </span>
                    </div>
                    <div class="content-item">
                        <label>邮箱地址：</label>
                        <span>{{userData.mail}}
                            <span class="edit" @click="showEditAccount" >
                                <i class="icon iconfont iconedit"></i>
                            </span>
                        </span>
                    </div>
                    <div class="content-item">
                        <label>可访问桶数量：</label>
                        <span>{{userData.bucketCount}}</span>
                    </div>
                    <div class="content-item">
                        <label>创建时间：</label>
                        <span>{{ userData.createdAt ? moment(userData.createdAt).format('YYYY-MM-DD HH:mm:ss') : '' }}</span>
                    </div>

                </div>
            </div>
        </div>
        <ResetPass ref="resetPassRef"/>
        <UpdateAccount ref="updateAccountRef" @complete="handleUpdate"/>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import vm from "@/plugin/eventUtil";
import moment from 'moment'
import ResetPass from "@/views/components/ResetPass";
import UpdateAccount from "@/views/components/UpdateAccount";

export default {
    name: "user-info",
    components: {ResetPass, UpdateAccount},
    computed: {
        ...mapGetters(["userData"]),
    },
    mounted() {
        if (!this.userData.id) {
            this.getUserInfo();
        }
        console.log(this.userData)
    },
    methods: {
        moment,
        getUserInfo () {
            this.$get('/accountUser/getCurrentAccountUser')
                .then(res => {
                    if (res.code === '200') {
                        this.$store.commit('user/SET_USER_INFO', res.data)
                    }
                })
        },
        showEditAccount () {
            this.$refs.updateAccountRef.show();
        },
        showEditPass () {
            this.$refs.resetPassRef.show()
        },
        handleUpdate () {
            this.getUserInfo()
        }
    }
}
</script>

<style lang="scss" scoped>
.user-info {
    margin: 14px 16px 10px;
    height: calc(100% - 36px);
}

.header {
    height: 62px;
    line-height: 62px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    text-indent: 26px;
    text-align: left;
    background: #fff;
}

.main {
    background: #fff;
    padding: 26px;
    margin: 10px 0;
    height: calc(100% - 62px);
    .title {
        height: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
        position: relative;
        text-align: left;
        text-indent: 26px;
        > b {
            position: absolute;
            top: 0;left: 0;
            width: 2px;
            height: 16px;
            background: #7486D9;
        }
    }
}

.content {
    margin-top: 30px;
    display: flex;
    .avatar {
        width: 128px;
        height: 128px;
        background: url("../assets/bluestore_202103151106261.png") no-repeat center center;
        background-size: 104px;
    }
}

.content-list {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    .content-item {
        width: 50%;
        position: relative;
        height: 50px;
        line-height: 50px;
        text-align: left;
        padding-left: 110px;
        > label{
            position: absolute;
            width: 100px;
            left: 0;
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
        }
        > span {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            display: flex;
            .edit {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>