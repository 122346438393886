import Vue from 'vue'
import Index from './Index.vue'
let UploadTaskConstructor = Vue.extend(Index);

let instance;
let id = 'upload_task_instance'
const UploadTask = {}

UploadTask.init = function(options, _id) {
    if (Vue.prototype.$isServer) return
    if(instance) {
        document.getElementById(_id).appendChild(instance.$el)
        return
    }
    options = options || {};
    instance = new UploadTaskConstructor({
        data: options
    });
    instance.id = id;
    instance.$mount();
    // document.body.appendChild(instance.$el);
    document.getElementById(_id).appendChild(instance.$el)
    // document.getElementsByClassName('el___main')[0].appendChild(instance.$el)
    return instance;
}
UploadTask.addUploadListener = function(listener) {
    if(instance){
        instance.registerUploadListener(listener)
    }
}

UploadTask.removeUploadListener = function(listener) {
    if(instance){
        instance.removeUploadListener(listener)
    }
}

UploadTask.push = function(files) {
    if(instance){
        instance.push(files)
    }
}
UploadTask.showList = function(files) {
    if(instance){
        instance.showList(files)
    }
}

UploadTask.closeList = function () {
    if(instance){
        instance.closeList()
    }
}

UploadTask.toggleDisplay = function(display) {
    if(display){
        instance.$el.style = 'display:none;'
    } else {
        instance.$el.style = 'display:block;'
    }
}

UploadTask.getFiles = function () {
    console.log(instance)
    if(instance){
        return instance.files;
    }
}

UploadTask.showEntrance = function (isShow) {
    if (instance) {
        instance.setIsShowEntrance(isShow)
    }
}

export default UploadTask;
