import Vue from 'vue'
import {isIE} from "@/utils/env";
Array.prototype.removeIf = function (callbackfn) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (callbackfn(this[i], i)) {
      this.splice(i, 1);
    }
  }
}
Array.prototype.remove = function (item) {
  const index = this.indexOf(item);
  index > -1 && this.splice(index, 1);
}
//注册自定义事件监听
const IeEventListener = {}
window.registerEventListener = function (type, fn) {
  if(isIE()) {
    if(!IeEventListener[type]){
      IeEventListener[type] = [fn]
    } else {
      IeEventListener[type].push(fn)
    }
  } else if (window.addEventListener) {
    window.addEventListener(type, function(e) {
      fn(e)
    }, false);
  }
}
window.createEvent = function(type = '', options = {}){
  if (isIE()){
    return {
      type: type,
      ...options
    }
  }
  return new CustomEvent(type, options)
}
//触发自定义事件监听
window.triggerEvent = function (event) {
  try {
    if (isIE()){
      if(IeEventListener[event.type]){
        for (let i = 0; i < IeEventListener[event.type].length; i++) {
          IeEventListener[event.type][i](event)
        }
      }
    }
    else{
      return window.dispatchEvent(event);
    }
  }catch(e){}
}

Vue.prototype.$env = process.env.VUE_APP_SYSTEM

