import axios from "axios";
import service from "@/utils/request";
import {S3} from "@/utils/aws";
import store from "@/store";
import {getRegionList} from "@/utils/session";
import Vue from 'vue'

import storage from "@/utils/storage";

function isClintUpload () {
  let type = storage.get('UPLOAD_TYPE')
  return type === 'client'
}

const MAX_FILE_SIZE = Vue.prototype.MAX_FILE_SIZE * 1 || 134217728;
const MAX_UPLOAD_PARTS = 3;
export const STATUS_WAIT_UPLOAD = "0";
export const STATUS_UPLOADING = "1";
export const STATUS_UPLOADING_CACHING = "2";
export const STATUS_UPLOADED = "3";
export const STATUS_UPLOAD_FAIL = "4";
export const STATUS_UPLOAD_CANCEL = "5";

export default class UploadFile {
  constructor(file, index) {
    let key = file.parent ? `${file.parent}/${file.fullPath}` : `${file.fullPath}`;
    this.uploadId = "";
    this.index = index;
    this.key = key;
    this.name = file.name;
    this.fullPath = file.fullPath;
    this.size = file.size;
    this.type = file.type;
    this.status = STATUS_WAIT_UPLOAD;
    this.failReason = "";
    this.bucket = file.bucket;
    this.parent = file.parent;
    this.raw = file.raw;
    this.uploadedPercent = 0;
    this.needMulti = MAX_FILE_SIZE < this.size;
    this.uploaded = 0;
    this.cancelToken = null;
    this.regionId = file.regionId;
    const host = file.xskyS3Host;
    const ak = file.accessKey
    const sk = file.secretKey
    this.StorageClass = file.StorageClass;
    this.vesionSetup = file.vesionSetup
    console.log(host, ak, sk)
    this.s3 = new S3(host, ak, sk)
    this.tagList = file.tagList
    if (this.needMulti) {
      this.current = 0;
      this.files = [];
      this.muitlUploadResults = [];
      this.initMulti(this.raw);
    }
  }

  initMulti(file) {
    let ext = file.name.split(".");
    ext = ext[ext.length - 1]; // 获取文件后缀名
    // 通过hash标识文件
    let random = Math.random().toString();
    random = random.split(".");
    random = random[random.length - 1];
    let hash = Date.now() + random + file.lastModified;
    const blockCount = Math.ceil(file.size / MAX_FILE_SIZE); // 分片总数
    let fileIndex = 1;

    // 处理每个分片的上传操作
    for (let i = 0; i < blockCount; i++) {
      let start = i * MAX_FILE_SIZE,
        end = Math.min(file.size, start + MAX_FILE_SIZE);
      // 构建表单
      this.files.push({
        name: `${hash}_${i + 1}.${ext}`,
        index: i + 1,
        file: file.slice(start, end),
        size: end - start,
        uploaded: 0,
        status: STATUS_WAIT_UPLOAD,
        cancelToken: null,
        onUploadProgress(loaded, total) {
          if (loaded > this.uploaded) {
            this.uploaded = loaded;
          }
        },
      });
    }
  }
  reUpload() {
    this.status = STATUS_WAIT_UPLOAD;
    if (this.needMulti) {
      const { files } = this;
      files.forEach((item) => {
        if (item.status !== STATUS_UPLOADED) {
          item.status = STATUS_WAIT_UPLOAD;
        }
      });
    }
    this.upload();
  }
  beforeUploadCheck() {
    const regionId = this.regionId
    return new Promise((resolve, reject) => {
      if (this.key.length > 1024) {
        reject(new Error("超出总路径长度"));
      }
      const data = {
        bucketName: this.bucket,
        key: this.key,
        regionId: this.regionId
      };
      service.postJson("/file/exist", data)
        .then((res) => {
          if (this.vesionSetup !=='Enabled' && res && res.data) {
            reject(new Error("已存在同名文件"));
          }
          return store.dispatch("user/checkSpace", {regionId});
        })
        .then((res) => {
          const remainSize = res.remainSize || 0;
          if (remainSize < this.size) {
            reject(new Error("空间不足"));
          }
          resolve(true);
        });
    });
  }
  upload() {
    if (this.needMulti) {
      this.uploaded = 0;
      this.uploadedPercent = 0;
      this.status = STATUS_UPLOADING;
      this.multiUpload();
      return;
    }
    const data = {
      bucket: this.bucket,
      key: this.key,
      body: this.raw,
      StorageClass: this.StorageClass,
      // tagList: this.tagList
    };
    this.uploaded = 0;
    this.uploadedPercent = 0;
    this.status = STATUS_UPLOADING;
    this.beforeUploadCheck()
      .then((res) => {
        return this.s3.uploadFile(data,
          ({ lengthComputable, loaded, total }) => {
            this.onUploadProgress(loaded, total);
          },
          (cancel) => {
            this.cancelToken = cancel;
          }
        );
      })
        // .catch(error => {
        //   return Promise.reject({message: "123"})
        // })
        .then((res) => {
        if (res.code == 0) {
          this.status = STATUS_UPLOADED;
          // 上传完成
          // service.uploadNotice(this.bucket, this.key, this.regionId)
          this.uploaded = this.size;
          this.onUploaded();
        } else {
          this.status = STATUS_UPLOAD_FAIL;
          this.failReason = res.message || res.msg;
          this.onUploadFail();
        }
      }).catch((e) => {
        if (S3.isAbort(e)) {
          this.status = STATUS_UPLOAD_CANCEL;
          this.failReason = "取消上传";
        } else if (e.message.indexOf("设备上没有空间") > -1) {
          file.status = STATUS_UPLOAD_FAIL;
          this.failReason = "设备空间不足";
          this.onUploadFail();
        } else {
          this.status = STATUS_UPLOAD_FAIL;
          this.failReason = e.message || "网络问题，上传中断";
          this.onUploadFail();
        }
      });
  }
  beginMultiUpload() {
    const uploadId = this.uploadId || "";
    const data = {
      bucket: this.bucket,
      key: this.key,
      StorageClass: this.StorageClass,
      // tagList: this.tagList
    };
    return new Promise((resolve, reject) => {
      this.beforeUploadCheck()
        .then((res) => {
          if (uploadId) {
            resolve(uploadId);
            return;
          }
          this.s3.createMultipartUpload(data)
            .then(({ data: { UploadId } }) => {
              resolve(UploadId);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  lineUpload(completeCallback = () => {}, uploadError = () => {}) {
    const { files, current } = this;
    const needUpload = [STATUS_WAIT_UPLOAD, STATUS_UPLOADING, STATUS_UPLOADING_CACHING];
    const uploadingStatus = [STATUS_UPLOADING];
    const waitUploadStatus = [STATUS_WAIT_UPLOAD];
    const cancelAndFailStatus = [STATUS_UPLOAD_CANCEL, STATUS_UPLOAD_FAIL];
    if (cancelAndFailStatus.indexOf(this.status) > -1 || files.filter((item) => cancelAndFailStatus.indexOf(item.status) > -1).length > 0) {
      return;
    }
    if (files.filter((item) => needUpload.indexOf(item.status) > -1).length == 0) {
      completeCallback && completeCallback();
      return;
    }
    if (files.filter((item) => uploadingStatus.indexOf(item.status) > -1).length >= MAX_UPLOAD_PARTS) {
      return;
    }
    if (files.filter((item) => waitUploadStatus.indexOf(item.status) > -1).length == 0) {
      return;
    }
    const file = files.filter((item) => waitUploadStatus.indexOf(item.status) > -1)[0];
    const data = {
      bucket: this.bucket,
      key: this.key,
      uploadId: this.uploadId,
      body: file.file,
      partNumber: file.index,
    };

    file.status = STATUS_UPLOADING;
    file.uploaded = file.uploaded || 0;
    file.file.hashName = file.name;
    this.s3.uploadPart(data,
        ({ loaded, total }) => {
          file.onUploadProgress(loaded, total);
          this.computeUploaded();
        },
        (cancel) => {
          file.cancelToken = cancel;
        }
      )
      .then((res) => {
        if (res.code == 0) {
          const { partNumber, ETag: etag } = res.data;
          this.muitlUploadResults.push({
            partNumber,
            etag,
          });
          file.status = STATUS_UPLOADED;
          file.uploaded = file.size;
          this.lineUpload(completeCallback, uploadError);
        } else {
          file.status = STATUS_UPLOAD_FAIL;
          uploadError(res.message || res.msg);
        }
      })
      .catch((e) => {
        if (S3.isAbort(e)) {
          file.status = STATUS_UPLOAD_CANCEL;
          uploadError("取消上传", true);
        } else if (e.message.indexOf("设备上没有空间") > -1) {
          file.status = STATUS_UPLOAD_FAIL;
          uploadError("设备空间不足");
        } else {
          file.status = STATUS_UPLOAD_FAIL;
          uploadError(e.message || "网络问题，上传中断");
        }
      });
    this.lineUpload(completeCallback, uploadError);
  }
  afterMultiUpload() {


    const data = {
      bucket: this.bucket,
      key: this.key,
      uploadId: this.uploadId,
      parts: this.muitlUploadResults.sort((a, b) => a.partNumber - b.partNumber),
    };
    if (isClintUpload()) {
      return this.s3.completeMultipartUpload(data)
          .then(() => {
            this.status = STATUS_UPLOADED;
            // service.uploadNotice(this.bucket, this.key, this.regionId)
            this.uploaded = this.size;
            this.uploadId = "";
            this.onUploaded();
            return true;
          })
          .catch((e) => {
            this.status = STATUS_UPLOAD_FAIL;
            this.failReason = "系统原因";
            this.onUploadFail();
          });
    } else {
      const data = {
        bucketName: this.bucket,
        key: this.key,
        uploadId: this.uploadId,
        partETags: this.muitlUploadResults.sort((a, b) => a.partNumber - b.partNumber),
      }
      service.postJson('/file/multi/complete', data)
          .then(res => {
            if (res.code === '200') {
              this.status = STATUS_UPLOADED;
              this.uploadId = "";
              this.onUploaded();
              return true;
            }
          })
          .catch((e) => {
            this.status = STATUS_UPLOAD_FAIL;
            this.failReason = "系统原因";
            this.onUploadFail();
          });
    }


  }
  async multiUpload() {
    try {
      const uploadId = await this.beginMultiUpload();
      if (!this.uploadId) {
        this.uploadId = uploadId;
      }
      if (uploadId) {
        this.lineUpload(
          () => {
            this.status = STATUS_UPLOADING_CACHING;
            this.afterMultiUpload();
          },
          (msg, cancel = false) => {
            this.failReason = msg;
            if (cancel) {
              this.status = STATUS_UPLOAD_CANCEL;
            } else {
              this.status = STATUS_UPLOAD_FAIL;
              this.onUploadFail();
            }
          }
        );
      }
    } catch (e) {
      this.failReason = e.message || e.msg || "系统原因";
      this.status = STATUS_UPLOAD_FAIL;
      this.onUploadFail();
    }
  }
  computeUploaded() {
    const { files } = this;
    let loaded = 0,
      total = this.size;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      loaded = loaded + file.uploaded;
    }
    this.uploaded = loaded;
    this.uploadedPercent = ((loaded / total) * 100).toFixed(0);
    if (this.uploadedPercent >= 99) {
      this.status = STATUS_UPLOADING_CACHING;
    }
    window.triggerEvent(
      window.createEvent("onFileUploadProgress", {
        detail: {
          data: { loaded, total },
        },
      })
    );
  }
  //取消上传
  cancelMulti() {
    const canCancelStatus = [STATUS_WAIT_UPLOAD, STATUS_UPLOADING];
    if (canCancelStatus.indexOf(this.status) == -1) {
      return false;
    }
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (canCancelStatus.indexOf(file.status) > -1) {
        file.cancelToken && file.cancelToken();
      }
      file.status = STATUS_UPLOAD_CANCEL;
      file.uploaded = 0;
    }
    const data = {
      bucket: this.bucket,
      key: this.key,
      uploadId: this.uploadId,
    };
    return this.s3.abortMultipartUpload(data).then(() => {
      this.status = STATUS_UPLOAD_CANCEL;
      this.uploadId = "";
      return true;
    });
  }

  cancel() {
    const canCancelStatus = [STATUS_WAIT_UPLOAD, STATUS_UPLOADING];
    if (canCancelStatus.indexOf(this.status) == -1) {
      return false;
    }
    if (this.needMulti) {
      this.cancelMulti();
    }
    this.cancelToken && this.cancelToken();
  }
  //上传进度回调
  onUploadProgress(loaded, total) {
    const { index } = this;
    this.uploaded = loaded;
    this.uploadedPercent = ((loaded / total) * 100).toFixed(0);
    if (this.uploadedPercent >= 99) {
      this.status = STATUS_UPLOADING_CACHING;
    }
    window.triggerEvent(
      window.createEvent("onFileUploadProgress", {
        detail: {
          data: { index, loaded, total },
        },
      })
    );
  }
  //上传完成回调
  onUploaded() {
    const { index, tagList } = this;
    if (tagList && tagList.length > 0) {
      service.addTagsToFile(this)
    }
    window.triggerEvent(
      window.createEvent("onFileUploaded", {
        detail: {
          index: index,
        },
      })
    );
  }

  onUploadFail() {
    const { index } = this;
    window.triggerEvent(
      window.createEvent("onUploadFail", {
        detail: {
          index: index,
        },
      })
    );
  }
}
