import ssc from "@/views/ssc";
import axios from 'axios'

export function isIE() {
  const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  const isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  const isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  return isIE || isIE11
}

export function isChrome() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("chrome") > 1;
}
function _mime(option, value){
  var mimeTypes = navigator.mimeTypes
  for (var mt in mimeTypes) {
    if (mimeTypes[mt][option] == value) {
      return true;
    }
  }
  return false;
}
export function is360() {
  return isChrome() && _mime("type", "application/vnd.chromium.remoting-viewer");
}

export function getEnv  ()  {
  let hostname = window.location.hostname
  const ssc_host = 'ssc-console.bj-cs.datalake.cn'
  let env = JSON.parse(sessionStorage.getItem('__env__'))
  if (env && env.SERVICE_URL) {
    return {
      env: env.ENV,
      system: env.SYSTEM,
      gateway: env.SERVICE_URL,
      uc: env.USERCENTER_FRONT_URL,
      ssc: env.SSC_URL,
      account:env.ACCOUNT
    }
  }
  return {
    env: 'prod',
    system: hostname.indexOf(ssc_host) > -1 ? 'EHL_SSC' : 'UNICOM_CM',
    gateway: 'http://gw.bj-cs.datalake.cn/bluestoreconsoleservice',
    uc: 'http://uc.bj-cs.datalake.cn/userservice',
    // 门户地址
    ssc: 'http://ssc.ehualu.com',
    account:['admin']
  }
}
