import {all} from "../../public/underscore";

function getEnvUc () {
    let hostname = window.location.hostname;
    if (hostname === '172.38.40.210' || hostname === '172.38.40.246') {
        return '172.38.40.246:8080'
    } else if (hostname) {

    }
}

export const noMessageList = [
    '/file/restore',
    '/file/exist',
    '/login/slidingBlock/verifyImage',
    '/login',
    '/login/sms',
    '/login/ifSendCodeValid',
    '/accountUser/getCurrentAccountUser'
]




export const messageType = [
    {code: '', type: '全部'},
    {code: 'SYSTEM_FAILURE', type: '系统故障'},
    {code: 'SYSTEM_RECOVERY', type: '系统恢复'},
    {code: 'SHUTDOWN_MAINTENANCE', type: '停机维护'},
    {code: 'NETWORK_CUTTING', type: '网络切割'},
]




/**
 * ROLE_ADMIN
 * ROLE_USER
 * ROLE_LOGGER
 * **/
 const allMenus = [
    {
        path: '/overview',
        name: 'overview',
        meta: {
            title: '概览',
            icon: 'iconfont2 icongailan',
            hidden: false,
            accountType: ['main', 'sub'],
            role: ['ROLE_ADMIN','ROLE_USER']
        }
    },
    {
        path: '/userManager',
        name: 'userManager',
        meta: {
            title: '用户管理',
            icon: 'iconfont2 iconyonghuguanli-fangwenguanli',
            hidden: false,
            accountType: ['main'],
            role: ['ROLE_ADMIN']
        }
    },
    {
        path: '/fileManage',
        name: 'fileManage',
        meta: {
            title: '文件管理',
            icon: 'iconfont2 iconduixiangcunchu',
            hidden: false,
            accountType: ['main', 'sub'],
            role: ['ROLE_USER']
        },
        children: [
            {
                path: '/bucket/list',
                name: 'Bucket',
                meta: {
                    title: '桶管理',
                    hidden: false,
                    accountType: ['main', 'sub'],
                    role: ['ROLE_USER']
                },
            },
            {
                path: '/userlevelSearch',
                name: 'userlevelSearch',
                meta: {
                    title: '用户级搜索',
                    hidden: false,
                    accountType: ['main'],
                    role: ['ROLE_USER']
                }
            },
            {
                path: '/tag',
                name: 'tag',
                meta: {
                    title: '标签管理',
                    hidden: false,
                    accountType: ['main'],
                    role: ['ROLE_USER']
                }
            },
        ],
    },
    {
        path: '/accessControl/list',
        name: 'accessControl',
        meta:{title: '子账号管理',
            hidden: false,
            icon: 'iconfont2 iconyonghuguanli-fangwenguanli',
            accountType: ['main'],
            role: ['ROLE_USER']
        },
    },
    {
        path: '/offline',
        name: 'offline',
        meta:{title: '离线管理',
            icon: 'iconlixian-xian iconfont',
            hidden: false,
            accountType: ['main'],
            role: ['ROLE_USER']
        },
        children: [
            {
                path: '/offline_task',
                name: 'offline_task',
                meta: {
                    title: '离线任务管理',
                    hidden: false,
                    accountType: ['main'],
                    role: ['ROLE_USER']
                }
            },
            {
                path: '/offline_cassette',
                name: 'offline_cassette',
                meta: {
                    title: '离线盘匣管理',
                    hidden: false,
                    accountType: ['main'],
                    role: ['ROLE_USER']
                }
            },
        ]
    },
    {
        path: '/system',
        name: 'system',
        meta: {
            title: '系统管理',
            icon: 'iconxitongshezhi iconfont',
            hidden: false,
            accountType: ['main', 'sub'],
            role: ['ROLE_ADMIN','ROLE_USER', 'ROLE_LOGGER']
        },
        children: [
            {
                path: '/aksk',
                name: 'aksk',
                meta: {
                    title: '认证管理',
                    hidden: false,
                    accountType: ['main', 'sub'],
                    role: ['ROLE_USER']
                },
            },
            {
                path: '/messageManage',
                name: 'messageManage',
                meta: {
                    title: '消息管理',
                    icon: 'iconfont2 iconxiaoxiguanli',
                    hidden: false,
                    accountType: ['main'],
                    role: ['ROLE_ADMIN','ROLE_USER']
                }
            },
            {
                path: '/syslog',
                name: 'sysLog',
                meta: {
                    title: '日志审计',
                    icon: 'iconfont2 iconrizhiguanli',
                    hidden: false,
                    accountType: ['main'],
                    role: ['ROLE_LOGGER', 'ROLE_USER', 'ROLE_ADMIN']
                },
            },
            {
                path: '/license',
                name: 'license',
                meta: {
                    title: 'License授权',
                    icon: 'iconfont2 iconsk',
                    hidden: false,
                    accountType: ['main'],
                    role: ['ROLE_ADMIN']
                }
            },
        ]
    },

]

const filterRouter = (accountType, role, ignore = [], item, esEnabled) => {
    return item.meta.accountType.indexOf(accountType) > -1 // 主子账号
        && item.meta.role.indexOf(role) > -1  // 角色
        && ignore.indexOf(item.path) === -1 // 需要忽略的
        && (esEnabled || (!esEnabled && item.name !== 'userlevelSearch')) // 当前环境是否支持es搜索
}

// 处理主账号菜单 主账号菜单是二级的
const handleRouters1 = (accountType, role, ignore = [], esEnabled) =>{
     // userlevelSearch
    let result = []
    for (let i = 0; i < allMenus.length; i++) {
        if (!filterRouter(accountType, role, ignore, allMenus[i], esEnabled)) {
            continue
        }
        let r = {
            ...allMenus[i]
        }
        if (allMenus[i].children && allMenus[i].children.length) {
            let child = allMenus[i].children.filter(item => filterRouter(accountType, role, ignore, item, esEnabled))
            r.children = child
        }
        result.push(r)
    }
    return result
}


// 其他角色菜单是 一级
const handleRouters2 = (accountType, role, ignore = []) => {
    let result = []
    for (let i = 0; i < allMenus.length; i++) {
        if (!filterRouter(accountType, role, ignore, allMenus[i])) {
            continue
        }

        if (allMenus[i].children && allMenus[i].children.length) {
            allMenus[i].children.map(item => {
                if (filterRouter(accountType, role, ignore, item)) {
                    result.push(item)
                }
            })
        } else {
            result.push(allMenus[i])
        }
    }
    return result
}

//
export const getRouters = (accountType, role, ignore = [], esEnabled) =>{
     console.log('getRouters  esEnabled', esEnabled)
    if (role === 'ROLE_USER' && accountType === 'main') {
        return handleRouters1(accountType, role, ignore, esEnabled)
    } else {
        return  handleRouters2(accountType, role, ignore, esEnabled)
    }
}

export const LOG_TYPE = [
    {value: 'LOGIN_IN', label: '用户登录'},
    {value: 'LOGIN_OUT', label: '用户登出'},
    {value: 'CREATE_MAIN_ACCOUNT', label: '创建主账号'},
    {value: 'DELETE_MAIN_ACCOUNT', label: '删除主账号'},
    {value: 'DISABLED_MAIN_ACCOUNT', label: '停用主账号'},
    {value: 'PUBLISH_MESSAGE', label: '发送消息'},
    {value: 'DELETE_MESSAGE', label: '删除消息'},
    {value: 'SAVE_LICENSE', label: 'License授权'},
    {value: 'CREATE_AKSK', label: '创建AK/SK'},
    // {value: 'REPLACE_AKSK', label: '更换AK/SK'},
    {value: 'DELETE_AKSK', label: '删除AK/SK'},

    {value: 'CREATE_BUCKET', label: '创建桶'},
    {value: 'DELETE_BUCKET', label: '删除桶'},
    {value: 'SET_BUCKET_ACL', label: '设置桶权限'},
    // {value: 'SET_BUCKET_VERSION', label: '设置桶版本控制'},
    {value: 'SET_BUCKET_LIFECYCLE', label: '设置桶生命周期'},
    {value: 'DELETE_BUCKET_LIFECYCLE', label: '删除桶生命周期'},
    {value: 'SET_BUCKET_REFERER', label: '设置桶防盗链'},
    {value: 'CLEAN_MULTI', label: '清理碎片'},
    {value: 'CREATE_IMAGE_STYLE', label: '创建图片样式'},
    {value: 'DELETE_IMAGE_STYLE', label: '删除图片样式'},
    {value: 'SET_OBJECT_TAGGING', label: '设置文件标签'},
    {value: 'RESTORE_OBJECT', label: '解冻文件'},
    {value: 'SET_OBJECT_ACL', label: '设置文件权限'},
    {value: 'GENERATE_OBJECT_URL', label: '生成文件URL'},
    {value: 'DELETE_OBJECT', label: '删除文件'},
    {value: 'MORE_SEARCH', label: '文件搜索'},
    {value: 'DELETE_TAG', label: '删除标签'},
    {value: 'SET_TAG_COMMON_USE', label: '设置常用标签'},

]

