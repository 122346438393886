<template>
  <div class="pager-view" v-show="total > 10">
    <div class="pager-left">
      <div class="total" v-if="layouts.indexOf('total') > -1">共 {{ total }} 条记录</div>
      <SpaceItem v-if="layouts.indexOf('total') > -1"></SpaceItem>
      <div class="size" v-if="layouts.indexOf('sizes') > -1">
        <span>每页显示</span>
        <el-select size="mini" :value="size" @change="changeSize">
          <el-option v-for="s in sizes" :key="s" :label="s" :value="s"></el-option>
        </el-select>
        <span>条</span>
      </div>
    </div>
    <div class="pager-right">
      <div class="pages">
        <div :class="prevBatchClass" @click="prevBatch">
          <i class="el-icon-d-arrow-left"></i>
        </div>
        <div :class="prevPageClass" @click="prevPage">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div :class="{ block: true, left: item < current, right: item > current, active: current === item }" @click="currentChange(item)" v-for="item in pageList">{{ item }}</div>
        <div :class="nextPageClass" @click="nextPage">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div :class="nextBatchClass" @click="nextBatch">
          <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <SpaceItem v-if="layouts.indexOf('quick') >= -1"></SpaceItem>
      <div class="jumps" v-if="layouts.indexOf('quick') >= -1">
        <span>跳至</span>
        <el-input type="number" step="1" v-model.trim="jumpPage"></el-input>
        <span style="margin-right: 5px">页</span>
        <el-button size="mini" plain @click="gotoJumpPage">GO</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pager",
  model: {
    prop: "pager",
    event: "pagerChange",
  },
  props: {
    layout: {
      type: String,
      default: "total,sizes,page,quick",
    },
    sizes: {
      type: Array,
      default: [],
    },
    pager: {
      type: Object,
    },
    moreBatch: {
      type: Boolean,
      default: false,
    },
    pagesOfBatch: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    layouts() {
      const { layout } = this;
      return layout.split(",");
    },
    pageList() {
      const { batch } = this;
      const start = this.pageListStart(batch);
      const end = this.pageListEnd(batch);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    pageTotal() {
      const { total, size } = this;
      if (total && size) {
        return Math.ceil(total / size);
      }
      return -1;
    },
    hasPrevPage() {
      const { current } = this;
      return current > 1;
    },
    hasNextPage() {
      const { pageList, current, hasNextBatch } = this;
      if (pageList.length > 0) {
        const lastPage = pageList[pageList.length - 1];
        return current === lastPage ? hasNextBatch : true;
      }
      return false;
    },
    hasPrevBatch() {
      const { batch } = this;
      return batch > 1;
    },
    hasNextBatch() {
      const { pageList, pagesOfBatch, moreBatch, total, size } = this;
      if (pageList.length < pagesOfBatch) {
        return false;
      }
      const lastPage = pageList[pageList.length - 1];
      const totalPage = Math.ceil(total / size);
      return moreBatch ? moreBatch : lastPage < totalPage;
    },
    prevBatchClass() {
      const { hasPrevBatch } = this;
      return {
        block: true,
        "prev-batch": true,
        left: true,
        disabled: !hasPrevBatch,
      };
    },
    prevPageClass() {
      const { hasPrevPage } = this;
      return {
        block: true,
        "prev-page": true,
        left: true,
        disabled: !hasPrevPage,
      };
    },
    nextBatchClass() {
      const { hasNextBatch } = this;
      return {
        block: true,
        "next-batch": true,
        right: true,
        disabled: !hasNextBatch,
      };
    },
    nextPageClass() {
      const { hasNextPage } = this;
      return {
        block: true,
        "next-page": true,
        right: true,
        disabled: !hasNextPage,
      };
    },
    batch() {
      const batch = Math.ceil(this.current / this.pagesOfBatch);
      return batch;
    },
  },
  watch: {
    pager: {
      handler(val) {
        const { total, size, current } = val;
        this.total = total;
        this.size = size;
        this.current = current;
      },
      deep: true,
    },
  },
  data() {
    return {
      total: 0,
      size: 10,
      current: 1,
      jumpPage: "",
    };
  },
  methods: {
    pageListStart(batch) {
      const { pagesOfBatch } = this;
      return (batch - 1) * pagesOfBatch + 1;
    },
    pageListEnd(batch) {
      const { total, size, pagesOfBatch } = this;
      return Math.min(batch * pagesOfBatch, Math.ceil(total / size));
    },
    changeSize(e) {
      this.current = 1;
      this.size = e;
      this.$emit("pagerChange", {
        current: this.current,
        size: this.size,
        total: this.total,
      });
      this.$emit("sizeChange", e);
    },
    notifyCurrentChange() {
      this.$emit("pagerChange", {
        current: this.current,
        size: this.size,
        total: this.total,
      });
      this.$emit("currentChange", this.current);
    },
    currentChange(current) {
      this.current = current;
      this.notifyCurrentChange();
    },
    prevBatch() {
      if (this.hasPrevBatch) {
        const batch = this.batch - 1;
        this.current = this.pageListStart(batch);
        this.notifyCurrentChange();
      }
    },
    prevPage() {
      if (this.hasPrevPage) {
        this.current = this.current - 1;
        if (this.current < this.pageList[0]) {
          this.batch = this.batch - 1;
        }
        this.notifyCurrentChange();
      }
    },
    nextPage() {
      if (this.hasNextPage) {
        this.current = this.current + 1;
        this.notifyCurrentChange();
      }
    },
    nextBatch() {
      if (this.hasNextBatch) {
        const batch = this.batch + 1;
        this.current = this.pageListStart(batch);
        this.notifyCurrentChange();
      }
    },
    gotoJumpPage() {
      const { jumpPage } = this;
      if (!jumpPage) {
        this.$message.warning("请输入页码");
        return;
      }
      if (parseInt(`${jumpPage}`) > 0 && /^\d+$/.test(`${jumpPage}`)) {
        const jump = parseInt(jumpPage);
        if (jump <= this.pageTotal) {
          this.current = jump;
        } else {
          this.current = this.pageTotal;
          this.jumpPage = this.pageTotal;
        }
        this.notifyCurrentChange();
      } else {
        this.$message.warning("无效页码");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pager-left,
.pager-right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.pager-left .total {
  line-height: 28px;
  margin-right: 25px;
}
</style>
