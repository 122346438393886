<template>
    <el-aside :class="{ 'cs-aside': true, collapse: collapse }" >
        <!--    <div class="cs-menus-switch" @click="toggleMenus">-->
        <!--      <i :class="[collapse ? 'el-icon-caret-right' : 'el-icon-caret-left']"></i>-->
        <!--    </div>-->
        <el-menu :default-active="defaultPath" :collapse-transition="false" :collapse="collapse"
                 router class="cs-menus">
            <template v-for="({ path, meta, children }, index) in menus">
                <el-menu-item :key="`menu_${index}`" :index="path" v-if="!children || !children.length">
                    <i :class="meta.icon" v-if="meta.icon"></i>
                    <span style="padding-left: 6px;">{{ meta.title }}</span>
                </el-menu-item>
                <el-submenu v-else :index="path" :key="`submenu_${index}`">
                    <template slot="title">
                        <i :class="meta.icon" v-if="meta.icon"></i>
                        <span>
                            {{ meta.title }}
                        </span>
                    </template>
                    <el-menu-item :key="`child_${index}`" :index="child.path" v-for="(child, childIndex) in children">
                        <span>{{ child.meta.title }}</span>
                    </el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
        <div class="restore-task-show"
             v-if="!isAdmin"
             @click="$refs.restoreTaskRef.show()"
             >
            解冻任务
        </div>
        <div class="version"
             style="position: absolute;bottom: 8px;left: 0;right: 0;text-align: center;color: #999;font-size: 12px;">
            版本号：{{ version }}
        </div>

        <RestoreTask ref="restoreTaskRef"/>

    </el-aside>
</template>

<script>
import {mapGetters} from "vuex";
import v from '../../utils/event';
import service from '../../utils/request'
import vm from "@/plugin/eventUtil";
import RestoreTask from "@/views/components/RestoreTask";
import {getRouters} from "@/utils/constant";
import storage from "@/utils/storage";
import {createSocket} from "@/utils/socket";

export default {
    name: "CsAside",
    components: {RestoreTask},
    data() {
        return {
            collapse: false,
            menuRender: true,
            version: '',
            defaultPath: '',

        };
    },
    mounted() {
        console.log('aside mounted', )
        this.defaultPath = window.location.pathname
        setTimeout(() => {
            let token = storage.get('PRIVATE_BLUESTORE_TOKEN') || null
			let protocol = location.protocol.replace('http', 'ws')
			if (token) {
                let url = `${protocol}//${window.location.host}/websocket/api/ws/message/token/${token}`
				if (window.location.host.indexOf('127.0.0.1') > -1) {
					url = `${protocol}//172.38.186.51:9078/api/ws/message/token/${token}`
				}
                createSocket(url)
            }
        }, 2000)
    },
    computed: {
        ...mapGetters(["userData", 'accountType', 'license', 'points', 'esEnabled', 'isAdmin']),
        menus() {
            /**
             * ROLE_ADMIN   1
             * ROLE_USER    2
             * ROLE_LOGGER  3
             * **/
            // let menus = getRouters(this.accountType, 'ROLE_USER')
            let menus = getRouters(this.accountType, this.userData.role, [], this.esEnabled)
            return menus
            // const {permissions, accountType} = this;
            // const list = permissions.filter((item, index) => {
            //     return !item.meta.hidden && item.meta.accountType.indexOf(accountType) > -1
            // });
            // const menus = [];
            // for (let i = 0; i < list.length; i++) {
            //     const menu = {
            //         ...list[i],
            //     };
            //     if (list[i].children) {
            //         menu.children = list[i].children.filter((item) => !item.hidden && item.meta.accountType.indexOf(accountType) > -1);
            //     }
            //     menus.push(menu);
            // }
            // // if (localStorage.getItem('regionType') === 'GDAS') {
            // //     menus.shift()
            // // }
            // return menus;
        },
        // defaultPath() {
        //   const { menus } = this;
        //   const { pathname } = window.location;
        //   for (let i = 0; i < menus.length; i++) {
        //     const menu = menus[i];
        //     const menuChildrenPaths = menu.children
        //       ? menu.children.map((item) => item.path)
        //       : [];
        //     if (menu.path === pathname || menuChildrenPaths.indexOf(pathname) > -1) {
        //         console.log(' pathname1: ',pathname)
        //       return menu.path;
        //     }
        //   }
        //   console.log(' pathname2: ',pathname)
        //   return pathname;
    },
    watch: {

    },
    methods: {
        setDefaultPath(permissions = []) {
            const {pathname} = window.location;
            for (let i = 0; i < permissions.length; i++) {
                const menu = permissions[i];
                const menuChildrenPaths = menu.children
                    ? menu.children.map((item) => {
                        if (item.path.indexOf("/") === 0) {
                            return `${item.path}`;
                        }
                        return `${menu.path}/${item.path}`;
                    })
                    : [];

                if (menu.path === pathname || menuChildrenPaths.indexOf(pathname) > -1) {
                    this.defaultPath = menu.path;
                }
            }
        },
        setCurrentMenu() {
            const {permissions} = this;
            const {pathname} = window.location;
            for (let i = 0; i < permissions.length; i++) {
                const menu = permissions[i];
                const menuChildrenPaths = menu.children
                    ? menu.children.map((item) => {
                        if (item.path.indexOf("/") === 0) {
                            return `${item.path}`;
                        }
                        return `${menu.path}/${item.path}`;
                    })
                    : [];
                if (menu.path === pathname || menuChildrenPaths.indexOf(pathname) > -1) {
                    return menu.path;
                }
            }
            return pathname;
        },
        toggleMenus() {
            this.collapse = !this.collapse;
        },
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        },
    },
    created() {
        vm.$on('toggleMenus', this.toggleMenus)
        if (window.location.pathname.indexOf('ssc') === -1) {
            service.get('/version/current')
                .then(res => {
                    if (res.code === '200') {
                        this.version = res.data;
                    }
                })
        }
        const _this = this;
        // this.defaultPath = this.setCurrentMenu()
        // v.$on('menu-change', () => {
        //     this.menuRender = false``
        //     _this.defaultPath = _this.setCurrentMenu()
        //     setTimeout(() => {
        //         this.menuRender = true
        //     })
        // })
    }
};
</script>

<style lang="scss">
.el-aside {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 184px !important;
    overflow: visible;
    border-right: 1px solid #e9ebef;

    &.collapse {
        width: 0px !important;

        .el-menu-item {
            padding-left: 0px !important;
        }

        .cs-product {
            visibility: hidden;
        }
    }

    .cs-product {
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        font-size: 14px;
        text-align: left;
        visibility: visible;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }

    .cs-menus-switch {
        position: absolute;
        right: -11px;
        top: 50%;
        margin-top: -30px;
        width: 10px;
        height: 60px;
        background: #fff;
        border-radius: 0 20px 20px 0;
        z-index: 2000;
        cursor: pointer;
    }

    .cs-menus-switch i {
        position: relative;
        left: -1px;
        margin: 24px auto;
        font-size: 12px;
    }

    .cs-menus {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        text-align: left;
    }
}

.restore-task-show {
    width: 94px;
    height: 32px;
    line-height: 32px;
    position: absolute;
    bottom: 30px;
    left: 45px;
    right: 0;
    text-align: center;
    color: #7486D9;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #7486D9;
    border-radius: 2px;
}
.restore-task-show:hover {
    background: #ff8700;
    border-color: #ff8700;
    color: #fff;
}
</style>
