import drag from './drag'

const install = function(Vue) {
    Vue.directive('draggable', drag)
}

if (window.Vue) {
    window['draggable'] = drag
    Vue.use(install); // eslint-disable-line
}
export default {
    install
}