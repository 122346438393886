let Socket = ''
let setIntervalWesocketPush = null
import vm from "@/plugin/eventUtil";

/**
 * 建立websocket连接
 * @param {string} url ws地址
 */

let  urlCache = ''
export const createSocket = (url = urlCache) => {
    urlCache = url
    Socket && Socket.close()
    if (!Socket) {
        console.log('建立websocket连接')
        Socket = new WebSocket(url)
        Socket.onopen = onopenWS
        Socket.onmessage = onmessageWS
        Socket.onerror = onerrorWS
        Socket.onclose = oncloseWS
    } else {
        console.log('websocket已连接')
    }
}

export const closeSocket = () => {
    Socket && Socket.close()
    Socket = null
}

/**打开WS之后发送心跳 */
const onopenWS = () => {
    sendPing()
}

/**连接失败重连 */
const onerrorWS = () => {
    Socket.close()
    clearInterval(setIntervalWesocketPush)
    if (Socket.readyState !== 3 && window.window._SOCKET_TEST_COUNT_ < 10) {
        setTimeout(() => {
            console.log('连接失败重连中')
            Socket = null
            createSocket()
            window._SOCKET_TEST_COUNT_ = window._SOCKET_TEST_COUNT_ + 1
        }, 30000)
    }
}

/**WS数据接收统一处理 */
const onmessageWS = e => {
    console.log('ws接收数据：',e)
    if (e.data) {
        handleData(JSON.parse(e.data))
    }
}

/**
 *  type FOLDER_STAT 文件夹统计
 * **/
const handleData = _data => {
    const {type, data} = _data
    vm.$emit('socket-message', {...data, type})
}


/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
const connecting = message => {
    setTimeout(() => {
        if (Socket.readyState === 0) {
            connecting(message)
        } else {
            Socket.send(message)
        }
    }, 1000)
}

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = message => {
    if (Socket !== null && Socket.readyState === 3) {
        Socket.close()
        createSocket()
    } else if (Socket.readyState === 1) {
        Socket.send(message)
    } else if (Socket.readyState === 0) {
        connecting(message)
    }
}

/**断开重连 */
const oncloseWS = () => {
    clearInterval(setIntervalWesocketPush)
    console.log('websocket已断开....正在尝试重连')
    if (Socket.readyState !== 2 && window.window._SOCKET_TEST_COUNT_ < 10) {
        setTimeout(() => {
            Socket = null
            createSocket()
            window._SOCKET_TEST_COUNT_ = window._SOCKET_TEST_COUNT_ + 1
        }, 30000)
    }
}
/**发送心跳
 * @param {number} time 心跳间隔毫秒 默认5000
 * @param {string} ping 心跳名称 默认字符串ping
 */
export const sendPing = (time = 5000, ping = 'keep alive') => {
    clearInterval(setIntervalWesocketPush)
    Socket.send(ping)
    setIntervalWesocketPush = setInterval(() => {
        Socket.send(ping)
    }, time)
}
