<template>
    <transition name="notification-fade">
        <div v-if="show" class="notification-popup">
            <div class="title">{{ title }}</div>
            <div class="notification-content">

                <p v-html="message" />

                <p>点击 <span  class="color_e83e8c" @click="emitDetailsEvent">查看详情</span></p>

            </div>
            <button class="close-button" @click="closePopup">
                <i class="el-icon-close"></i>
            </button>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '任务完成'
        },
    },
    data() {
        return {
            show: false,
            message: "",
            timeout: null,
            dataInfo: {}
        };
    },
    methods: {
        showPopup(message, dataInfo) {
            this.message = message;
            this.dataInfo = dataInfo
            this.show = true;
            this.timeout = setTimeout(() => {
                this.closePopup();
            }, 30000); // 自定义弹窗显示时间，这里是30
        },
        closePopup() {
            this.show = false;
            clearTimeout(this.timeout);
        },
        emitDetailsEvent() {
            this.$emit("show-details", this.dataInfo);
        }
    }
};
</script>

<style lang="scss">
.notification-popup {
    position: fixed;
    z-index: 10000;
    top: 20px;
    right: 20px;
    background-color: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    padding: 20px;
    border-radius: 4px;
    text-align: left;
}

.notification-content {
    color: #606266;
    font-size: 14px;
    line-height: 21px;
    margin: 6px 0 0;
    text-align: justify;
}

.details-button {
    background: none;
    border: none;
    color: #409eff;
    cursor: pointer;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #909399;
}

.title {
    font-weight: 700;
    font-size: 16px;
    color: #303133;
    margin: 0;
}
</style>
