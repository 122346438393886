<template>
    <el-header>
        <div class="left">
            <span style="" class="logo">{{ appName }}控制台</span>

            <div class="regions" v-show="regionList && regionList.length > 1">
                <el-select size="small" :value="regionId" @change="handleRegionIdChange">
                    <el-option
                        v-for="item in regionList"
                        :disabled="item.userStatus === 'SUSPENDED'"
                        :key="item.regionId"
                        :label="`${item.regionName}${item.userStatus === 'SUSPENDED' ? `(已停用)` : ''}`"
                        :value="item.regionId"></el-option>
                </el-select>
            </div>
        </div>
        <div class="right" >
            <el-badge
                v-show="userData.role !== 'ROLE_LOGGER' && accountType !== 'sub'"
                class="right-item"
                :value="unreadMessage > 99 ? '99+' : unreadMessage"
                :hidden="unreadMessage === 0"
                :max="99" @click.native="$router.push({ name: 'messageManage' })"

                title="消息管理">
                <i class="iconfont2 iconxiaoxiguanli-1"></i>
            </el-badge>
            <div class="right-name" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <div :class="`drop-down ${hiddenDrop ? 'hidden' : ''}`">

                    <div class="drop-down-content">
                        <div class="drop-down-content-item" @click="toUserInfo">用户中心</div>
                        <div v-if="publicNetSwitch && !isAdmin" class="drop-down-content-item" @click="showSetup">使用设置</div>
                        <div class="drop-down-content-item" @click="logout">退出账号</div>
                    </div>
                </div>
                <span>
                    <b></b>
                    <div class="ellipsis">
                        {{ userData.username }}
                    </div>
                    <i :class="`el-icon-caret-${iconSwitch}`"/>
                </span>
            </div>
        </div>
        <Setup ref="setupRef"/>
    </el-header>
</template>
<script>
import {mapGetters} from "vuex";
import Cookie from 'js-cookie'
import vm from '@/plugin/eventUtil'
import {getEnv} from "@/utils/env";
import Setup from "@/views/layout/Setup";
import storage from "@/utils/storage";
import {getUnreadCount} from "@/utils/message";

export default {
    name: "CsHeader",
    components: {Setup},
    data() {
        return {
            hiddenDrop: false,
            iconSwitch: 'bottom',
            regionList: [],
            regionId: '',
        };
    },
    computed: {
        ...mapGetters(["userData", 'publicNetSwitch', 'unreadMessage', 'appName', 'isAdmin', 'accountType']),
    },
    mounted() {
        vm.$on('show-setup', this.showSetup)
        this.regionId = storage.get('REGION_ID')
        this.getRegions();
        // this.getAppName()
        getUnreadCount()
        this.inteval = setInterval(() => {
            getUnreadCount()
        }, 90 * 1000)
    },
    methods: {

        getRegions () {
            this.$postJson('/region/listPurchased')
            .then(res => {
                if (res.code === '200') {
                    this.regionList = res.data

                    if (res.data && res.data.length > 0) {
						/**
						 * 前提：regionId默认从storage缓存中获取,
						 * 操作场景：
						 * 1.缓存为空，则默认显示第一个资源池
						 * 2. 如果是登录进来，检查缓存的资源池是否在资源池列表中，不在的话，默认选中第一个资源池
						 * 3、如果是任何页面刷新，这里的判断必然是不成立的，因为资源池列表一定包含缓存的regionId
						 * */
						if (!this.regionId || res.data.filter(i => i.regionId === this.regionId).length === 0) {
							this.regionId = res.data[0].regionId
							storage.set('REGION_ID', this.regionId)
						}
                    }

                    this.$store.commit('region/SET_POINTS', res.data)
                }
            })
        },
        /**
         * 切换资源池的时候处理默认页面
         * 1、大部分刷新
         * 2、部分详情页、编辑页跳转列表页
         * 3、用户级搜索和资源池是否支持有关，需要额外判断
         * 4、
         *
         * **/
        handleRegionIdChange (val) {
            storage.set('REGION_ID', val)
            const {regionList = []} = this
            const curPoint = regionList.find(item => item.regionId === val)
            const path = this.$route.path
            let url = ''
            switch (path) {
                case '/bucket/file':
                    url = '/bucket/list'
                    break
                case '/advancedSearch':
                    url = '/bucket/list'
                    break
                case '/accessControl/manager':
                    url = '/accessControl'
                    break
                case '/otd':
                    url = '/offline_task'
                    break
                case '/octl':
                    url = '/offline_cassette'
                    break
                case '/createUser':
                    url = '/userManager'
                    break
                case '/userInfo':
                    url = '/userManager'
                    break
                case '/createMessage':
                    url = '/messageManage'
                    break
                case '/messageDetail':
                    url = '/messageManage'
                    break
            }
            /**  用户级搜索特殊处理: 资源池是否支持 ES  **/
            if (path === '/userlevelSearch') {
                if (!curPoint || curPoint.esEnable !== 'true') {
                    url = 'overview'
                }
            }

            if (url) {
                this.$router.push(url)
                location.reload()
            } else {
                location.reload()
            }

            // location.reload()
            // if (this.$route.path.indexOf('overview') > -1) {
            //
            // } else {
            //     this.$router.push('/')
            // }
            // this.$store.commit('user/SET_REGIONID', this.regionId)
        },
        logout () {
            this.$confirm("确认退出吗?", "提示", {
                confirmButtonText: "退出",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$get('/logout')
                    .then(res => {
                        storage.remove('PRIVATE_BLUESTORE_TOKEN')
                        this.$message.success(res.msg)
                        this.$router.push('/login')
                    })

                })
        },
        showSetup () {
            this.$refs.setupRef.show();
        },
        navigatorTo () {
            window.open(getEnv().ssc+'/?userId=' + this.userData.account.accountId + '&t='+Cookie.get('token'))
        },
        toggleMenus() {
            vm.$emit('toggleMenus')
        },
        mouseenter () {
            this.iconSwitch = 'top'
        },
        mouseleave () {
            this.iconSwitch = 'bottom'
        },
        toUserInfo () {
            this.hiddenDrop = true
            setTimeout(() => {this.hiddenDrop = false}, 200)
            if (this.$route.path.indexOf('/info') === -1) {
                this.$router.push('/info')
            }
        },
    },
};
</script>
<style lang="scss" scoped>
$color: #ff8700;
.el-header {
    display: flex;
    justify-content: space-between;
    height: 54px!important;
    line-height:54px;
    background: $color;
    color: #fff;
    font-size: 14px;
    padding: 0 16px 0 0 ;
}
.left {
    display: flex;
    .collapse-btn {
        width: 65px;
        height: 38px;
        margin-top: 8px;
        background: url("../../assets/3_202103311621002.png") no-repeat center center;
        background-size: 18px;
        cursor: pointer;
        border-right: 1px solid rgba(255, 255, 255, .2);
        margin-right: 20px;
    }
    .logo {
        margin-left: 50px;
        text-indent: 50px;
        background: url("../../assets/2_202103311621001.png") no-repeat left center;
        background-size: 40px 20px;
    }
}
.right {
    display: flex;
    span{
        cursor: pointer;
        padding-right: 8px;
        background: url("../../assets/1_20210331162100.png") no-repeat left center;
        background-size: 16px;
        padding-left: 24px;
    }
    &-item {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        border-radius: 100%;
        background: hsla(0,0%,100%,.25);
        cursor: pointer;
        transform: translateY(12px);
        margin: 0 10px;
    }
}

.right-name {
    position: relative;
    cursor: pointer;
    min-width: 100px;
    height: 54px;
    margin-left: 50px;
    > span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1902;
        text-align: left;
        text-indent: 8px;
        background:$color ;
        padding-right: 12px;
        > b {
            position: absolute;
            width: 34px;
            left: -34px;
            top: 0;
            bottom: 0;
            background: url("../../assets/bluestore_20210315110626.png") no-repeat center center;
            background-size: 34px
        }
        > i {
            position: absolute;
            right: 0;
            height: 54px;
            line-height: 54px;
            top: 0;
        }
    }
}
.drop-down {
    position: absolute;
    background: #FFF8F1;
    background-clip: content-box;
    padding-top: 4px;
    top: -100px;
    right: 0;
    width: 100px;
    font-size: 12px;
    text-align: center;
    z-index: 1901;
    transition: top 0.2s;
    .drop-down-content {
        padding: 6px 0;
        &-item {
            height: 24px;
            line-height: 24px;
            color: #ff8700;
        }
        &-item:hover {
            background: #ff8700;
            color: #fff;
        }
    }
}
.right-name:hover .drop-down {
    top: 54px;
}
.drop-down.hidden {
    top: -100px!important;
}

.regions {
    margin-left: 20px;
}

</style>
