<template>
    <div class="ssc">
        <div class="ssc-left">
            <div class="title">文档目录</div>
            <div class="menus" style="height: 400px;">

                <div v-for="item in menus" :class="'menu' + item.class"><a :href="'#' + item.id">{{ item.text }}</a></div>
            </div>
        </div>
        <div class="ssc-content">
            <div v-for="i in docu" :class="i.class < 5 ? 'title' + i.class : 'content'">
                <span v-if="i.class < 5" :id="i.id">{{ i.text }}</span>
                <ul v-else>
                    <li v-for="j in i.text" >
                        <p v-if="j.indexOf('/ssc/') === -1" v-html="j"></p>
                        <div class="example" v-if="j.indexOf('/ssc/') > -1">
                            <el-image
                                :src="j"
                                :preview-src-list="[j]"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="ssc-right">
            <div class="img"><img src="../assets/down_20210514150559.png" alt=""></div>
            <div class="text">将蓝光网关BRG下载到本地</div>
            <div class="btn"><a href="https://ssc-static-objects.obs.cn-southwest-2.myhuaweicloud.com/tools/blue-store-gateway/%E8%93%9D%E5%85%89%E7%BD%91%E5%85%B3%E4%BA%A7%E5%93%81%E5%AE%89%E8%A3%85%E5%8C%85.zip">下载</a></div>
            <div class="version">版本：v1.0.0.2</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ssc",
    data() {
        return {
            docu: [
                {class: 1, id: 't1', text: '产品介绍'},
                {class: 2, id: 't1-1', text: '1.产品定位'},
                {class: 9, id: '', text: ['蓝光网关（Blue-Ray Gateway）以蓝光存储BRS为后端存储，为企业应用提供冷数据存储服务，可以部署在用户侧服务器或客户端上，帮助用户简化存储管理，实现用户业务系统和蓝光存储存储服务的无缝对接。']},
                {class: 2, id: 't1-2', text: '1.2产品功能'},
                {class: 9, text:
                    [
                        '蓝光网关包含五大功能模块：',
                         '(1)资源池管理：认证添加开通的蓝光存储资源池，进行资源池的统一管理；（详细讲解见章节2.1资源池管理）',
                        '(2)挂载管理：创建并管理挂载配置；（详细讲解见章节2.2挂载管理）',
                        '(3)同步管理：创建和管理同步配置；（详细讲解见章节2.3同步管理）',
                        '(4)文件管理：管理挂载配置和同步配置下的文件；（详细讲解见章节2.4文件管理）',
                        '(5)日志审计：对用户操作行为及系统运行情况进行记录和显示；（详细讲解见2.5日志管理）',
                    ]
                },
                {class: 2, id: 't1-3', text: '1.3产品优势'},
                {class: 9, text:
                        [
                            '蓝光网关有以下优势：',
                            '（1）简易扩展：可将蓝光存储资源前置到用户本地，将其当做本地存储空间使用，支持多台主机同时挂载拓展空间；',
                            '（2）高效传输：支持企业标准S3存储协议，本地数据通过配置实现自动传输，用户可自主定义传输时间、传输方式，避免占用忙时带宽资源；',
                            '（3）一键部署：支持Windows、Linux系统，服务端在客户侧，无需场景改造，即装即用，无缝对接用户本地；',
                            '（4）全量日志管控：数据传输配置心跳健康检查、数据传输情况、数据一致性校验全量管控，保障用户数据安全、稳定传输。',
                        ]
                },
                {class: 2, id: 't1-4', text: '1.4安装说明'},
                {class: 9, text:
                        [
                            '1、在控制台下载安装包，运行安装：双击运行安装程序，按照提示操作即可',
                            '① 选择安装路径。如需要自主设定安装目录，点击【浏览】指定目录；若无特殊要求，点击【下一步】选择默认安装路径即可。',
                            '/ssc/1.4-1.png',
                            '② 选择【创建桌面快捷方式】，点击【下一步】',
                            '/ssc/1.4-2.png',
                            '③ 点击【安装】按钮，开始程序安装',
                            '/ssc/1.4-3.png',
                            '/ssc/1.4-4.png',
                            '④ 最后，点击【完成】结束安装',
                            '/ssc/1.4-5.png',
                            '⑤在登录页面输入账号、密码进入网关操作页面；登录账号密码请查看readme.txt文档。',
                            '/ssc/1.4-6.png',
                            '2、卸载：进入系统设置-应用和功能-蓝光网关，点击【卸载】即可。'
                        ]
                },


                {class: 1, id: 't2', text: '蓝光网关操作说明'},
                {class: 9, text: ['用户在订购了蓝光存储套餐，开通蓝光存储资源后，可以在控制台获取所开通资源池的Endpoint、AK、SK等资源认证信息。完成蓝光网关安装并且完成资源池认证后，即可通过挂载配置和同步配置功能实现蓝光存储资源的本地挂载和数据自动同步。']},
                {class: 2, id: 't2-1', text: '2.1资源池管理'},
                {class: 9, text: ['通过资源池管理，可以对已开通的蓝光存储资源进行统一的认证管理。']},
                {class: 3, id: 't2-1-1', text: '2.1.1添加资源池'},
                {class: 9, text: [
                    '1、在蓝光存储控制台-桶管理-桶详情中获取开通资源池域名信息；',
                        '/ssc/2.1.1-1.png',
                    '2、在蓝光存储控制台-认证管理中开通节点的一对AK/SK。',
                        '/ssc/2.1.1-2.png',
                    '3、进入网关操作页面，默认进入【资源池管理】模块，点击【添加资源池】，在弹窗中填入资源池的认证信息，点击【确定】开始校验信息正确性，校验通过后以列表显示已添加的资源池，并可对其进行修改和删除操作。',
                        '/ssc/2.1.1-3.png',
                    ]},
                {class: 3, id: 't2-1-2', text: '2.1.2 资源池信息操作'},
                {class: 9, text: ['(1)编辑资源池信息：点击【编辑】，在弹窗中输入新的Endpoint、AK、SK后点击保存，通过资源认证后即可更新资源池信息。当AK/SK等资源池信息发生改变时，请在网关资源池管理中及时编辑修改资源池信息。', '(2)删除资源池信息：已失效或者不使用的资源池信息点击【删除】按钮确认删除即可。', '/ssc/2.1.2-1.png']},

                {class: 2, id: 't2-2', text: '2.2 同步管理'},
                {class: 9, text: ['在蓝光存储控制台创建存储桶后，通过同步配置将用户本地的文件路径与在控制台创建的桶目录相关联，当用户的本地文件路径中有数据产生时自动将数据传输至资源池，进行数据自动归档/备份。']},
                {class: 3, id: 't2-2-1', text: '2.2.1 创建同步配置'},
                {class: 9,
                    text: [
                        '点击【创建】，在弹窗中输入同步配置名称、选择对应的资源池、选择S3类型、输入同步的桶目录和本地目录，在选择数据同步策略和周期后点击【确定】即可完成同步配置，并以列表形式显示同步配置的信息。',
                        '/ssc/2.2.1-1.png',
                        '<b>*注意：</b>',
                        '①<b>同步配置名称命名规范</b>：配置名称为用户定义的S3认证接口，用于数据同步传输，其命名支持中文、大小写字母、数字、下划线及其组合，长度为3-15个字符；',
                        '②<b>S3类型</b>：蓝光存储与其他类型存储服务的S3类型不同，后期蓝光网关会开放其他的S3类型，可以和不同的公有云存储产品对接使用。',
                        '③<b>同步策略</b>：数据备份是指在本地保存一份，再在蓝光存储资源池备份一份；数据迁移是指数据不再在本地留存，直接存储到蓝光存储；',
                        '④<b>所有同步操作规则</b>：远端操作不影响本地。',
                        '⑤<b>同步周期</b>：采用Cron表达式，字符串以4个空格隔开，分为5个域，每一个域代表一个含义，语法格式为：Seconds Minutes Hours DayofMonth Month DayofWeek',
                        'A：结构：corn从左到右（用空格隔开）：分 小时 月份中的日期 月份 星期中的日期',
                        'B：各字段的含义：',
                        '/ssc/2.2.1-2.png',
                        'C：每一个域都使用数字，但还可以出现如下特殊字符，它们的含义是：',
                        '<span class="docu-text-indent"/>a. * ：表示匹配该域的任意值。假如在Minutes域使用*, 即表示每分钟都会触发事件。\n',
                        '<span class="docu-text-indent"/>b. ? ：只能用在DayofMonth和DayofWeek两个域。它也匹配域的任意值，但实际不会。因为DayofMonth和DayofWeek会相互影响。例如想在每月的20日触发调度，不管20日到底是星期几，则只能使用如下写法： 13 13 15 20 * ?, 其中最后一位只能用？，而不能使用*，如果使用*表示不管星期几都会触发，实际上并不是这样。',
                        '<span class="docu-text-indent"/>c. - ：表示范围。例如在Minutes域使用5-20，表示从5分到20分钟每分钟触发一次',
                        '<span class="docu-text-indent"/>d. / ：表示起始时间开始触发，然后每隔固定时间触发一次。例如在Minutes域使用5/20,则意味着5分钟触发一次，而25，45等分别触发一次 ',
                        '<span class="docu-text-indent"/>e. , ：表示列出枚举值。例如：在Minutes域使用5,20，则意味着在5和20分每分钟触发一次。',
                        '<span class="docu-text-indent"/>f. L ：表示最后，只能出现在DayofWeek和DayofMonth域。如果在DayofWeek域使用5L,意味着在最后的一个星期四触发。',
                        '<span class="docu-text-indent"/>g. W ：表示有效工作日(周一到周五),只能出现在DayofMonth域，系统将在离指定日期的最近的有效工作日触发事件。例如：在 DayofMonth使用5W，如果5日是星期六，则将在最近的工作日：星期五，即4日触发。如果5日是星期天，则在6日(周一)触发；如果5日在星期一到星期五中的一天，则就在5日触发。另外一点，W的最近寻找不会跨过月份 。',
                        '<span class="docu-text-indent"/>h. LW ：这两个字符可以连用，表示在某个月最后一个工作日，即最后一个星期五。',
                        '<span class="docu-text-indent"/>i. # ：用于确定每个月第几个星期几，只能出现在DayofWeek域。例如在4#2，表示某月的第二个星期三。',
                    ]
                },
                {class: 3, id: 't2-2-2', text: '2.2.2 停用/启用 同步配置'},
                {class: 9, text: ['当同步配置创建完成后默认处于启用状态，当需要中断同步配置时，点击操作栏的【停用】即可，需要恢复同步配置时点击【启用】即可重新启动配置。', '/ssc/2.2.2-1.png', '*注：上图所示配置为将用户本地D:\\testfile\\【001】路径下的文件自动传输到蓝光存储资源池中用户创建的桶“test1”中。']},
                {class: 3, id: 't2-2-3', text: '2.2.3 修改 同步配置'},
                {class: 9, text: ['当同步配置的配置信息需要变更时，点击列表中的【修改】按钮，在弹窗中输入新的配置信息点击【确认同步】即可。', '/ssc/2.2.3-1.png']},
                {class: 3, id: 't2-2-4', text: '2.2.4 删除 同步配置'},
                {class: 9, text: ['当有同步配置不再使用需要删除时，可点击同步配置列表中的【删除】按钮确认删除即可。', '注意：在删除配置前需要先将配置停用。', '/ssc/2.2.4-1.png']},

                {class: 2, id: 't2-3', text: '2.3 挂载管理'},
                {class: 9, text: ['在蓝光存储控制台创建存储桶后，通过挂载配置，将蓝光存储资源以虚拟盘符或者虚拟路径的形式前置到用户本地，可作为用户的数据盘使用，当数据被存进该盘符或者路径时会自动传输至指定资源池。']},
                {class: 3, id: 't2-3-1', text: '2.3.1 创建挂载配置'},
                {class: 9, text: [
                        '(1)点击【创建】，在弹窗中输入配置名称、选择需要挂载资源的资源池、选择S3类型、输入需要进行挂载的桶/桶目录，最后选择挂载类型，输入挂载的盘符或者挂载的路径后点击【确认挂载】即可完成挂载配置，并以列表形式显示挂载信息。',
                        '/ssc/2.3.1-1.png',
                        '*注意：',
                        '①挂载配置名称命名规范：配置名称为用户定义的S3认证接口，用于数据同步传输，其命名支持中文、大小写字母、数字、下划线及其组合，长度为3-15个字符；',
                        '②S3类型：蓝光存储与其他类型存储服务的S3类型不同，后期蓝光网关会开放其他的S3类型，可以和不同的公有云存储产品对接使用。',
                        '③选择挂载类型，进行盘符/目录命名时需要输入不存在的盘符/文件目录；',
                        '④挂载的高级配置只有设置缓冲区大小。该参数为读取每个文件时的内存缓冲区大小，控制sscsync上传和挂载的时候的内存占用；内存占用过高时请调低此参数，文件传输速率太低时可根据服务器配置适量调高此参数。',
                    ]},
                {class: 3, id: 't2-3-2', text: '2.3.2 停用/启用挂载配置'},
                {class: 9, text: ['当挂载配置创建完成后默认处于启用状态，当需要中断挂载状态时，点击操作栏的【停用】即可断开挂载配置，需要恢复挂载配置时点击【启用】即可重新启动配置。', '/ssc/2.3.2-1png', '/ssc/2.3.2-2.png', '*注：以上图所 示M：盘（通过配置“挂载1”生成）为例，用户直接点击即可查看资源池中桶“test005”中的所有文件，在蓝光存储控制台或者蓝光网关文件管理页面申请“解冻”后即可直接打开。']},
                {class: 3, id: 't2-3-3', text: '2.3.3 删除挂载配置'},
                {class: 9, text: ['当某条挂载配置不再需要时，可点击挂载配置列表中的【删除】按钮确认删除即可。', '*注意：在删除配置前需要先将配置停用。', '/ssc/2.3.3-1.png']},

                {class: 2, id: 't2-4', text: '2.4 文件管理'},
                {class: 3, id: 't2-4-1', text: '2.4.1 进入文件列表'},
                {class: 9, text: ['(1)点击【文件管理】模块即可进入文件管理页面，已有挂载/同步配置时，先进入用户最新创建配置的文件列表。']},
                {class: 3, id: 't2-4-2', text: '2.4.2 文件管理功能栏'},
                {class: 9, text: [
                      '/ssc/2.4.2-1.png',
                      '（1）当前目录：显示当前文件列表所在的桶目录，桶目录支持点击跳转；',
                      '（2）配置选择：点击展开【配置选择】后的下拉框，可选择不同的配置切换到其他的文件管理页面；',
                      '（3）批量操作：支持多选&全选当前已加载文件列表，选中文件后可进行批量解冻、批量延期。',
                      '（4）操作时需要先选择文件，随后选择操作类型，选择完成后执行对应操作。当前单次批量操作限制最多100个文件。',
                      '（5）检索：支持前缀搜索和模糊搜索',
                      '前缀检索：仅在当前路径下检索；检索文件名的前10个字符前缀，如与输入框内字符一致则在列表内展示相应文件，反之不展示。',
                      '模糊检索：在根目录下检索；检索文件名的全部字符，如与输入框内字符一致则在列表内展示相应文件，反之不展示。',
                      '说明：默认为在当前路径搜索，以“/”开头表示全路径搜索。',
                  ]},
                {class: 3, id: 't2-4-3', text: '2.4.3 文件取回、文件解冻&下载'},
                {class: 9, text: [
                      '在文件列表的操作栏，挂载配置用户可点击【解冻】申请“解冻”文件，将文件提取到热磁服务器，直接进行读取操作；同步配置用户可点击【取回】申请文件“取回”，将文件取回（包含了解冻、下载两个过程）到本地的指定目录。',
                      '挂载配置：',
                      '点击【解冻】申请文件解冻，待文件状态变为“已解冻”状态，【解冻】会变为【延期】，在数据保存路径下对文件进行打开、复制、移动等操作；文件“已解冻”状态保持24小时，超过24小时文件会再次被冻结；申请【延期】后，文件的“已解冻”时间将会从点击时刻再向后延长24小时。',
                      '/ssc/2.4.3-1.png',
                      '同步配置：',
                      '在同步配置的文件列表中，点击【取回】按钮，在弹窗中输入文件取回路径，点击【确定】待文件取回完成，文件状态显示“已取回”，用户即可在本地路径中查看取回文件。',
                      '/ssc/2.4.3-2.png',
                  ]},

                {class: 2, id: 't2-5', text: '2.5 日志管理'},
                {class: 9, text: ['蓝光网关的日志分为：操作日志和运行日志。分别记录用户在网关上的操作行为以及网关的状态自检信息。']},
                {class: 3, id: 't2-5-1', text: '2.5.1 操作日志'},
                {class: 9, text: [
                      '记录用户在网关上的操作行为，包括添加、编辑、删除资源池信息，创建、停用/启用、删除配置，取回文件等。并支持用户按照日志产生时间、日志类型来筛选日志。\n',
                      '/ssc/2.5.1-1.png',
                      '操作日志分别有如下类目：',
                      '资源池管理',
                      '/ssc/2.5.1-2.png',
                      '挂载管理',
                      '/ssc/2.5.1-3.png',
                      '同步管理',
                      '/ssc/2.5.1-4.png',
                      '文件管理',
                      '/ssc/2.5.1-5.png'
                  ]},
                {class: 3, id: 't2-5-2', text: '2.5.2 运行日志'},
                {class: 9, text: [
                      '网关系统会定期自动执行挂载/同步配置的运行状态检测，并显示检测结果；当有数据进行传输时，系统也会自动定时统计数据传输情况，并反馈数据传输完成情况。',
                      '/ssc/2.5.2-1.png',
                      '运行日志主要包括以下类型：',
                      '状态自查',
                      '/ssc/2.5.2-2.png',
                      '状态自查记录配置自动启动与配置传输中断、配置传输完成。',
                      '数据传输',
                      '/ssc/2.5.2-3.png',
                  ]},

                {class: 1, id: 't3', text: '蓝光网关使用常见问题说明'},

                {class: 2, id: 't3-1', text: '3.1 端口号冲突，如何自定义修改端口号？'},
                {class: 3, id: 't3-1-1', text: '3.1.1 Windows系统'},
                {class: 9, text: [
                      '1.正常安装软件',
                      '2.以管理员运行cmd，命令打开安装路径（如：D:\\Program Files (x86)\\蓝光网关）',
                      '3.进入service 文件夹  cd service',
                      '/ssc/3.1.1-1.png',
                      '顺序执行以下命令',
                      'ssc-gateway.exe -service-stop',
                      '/ssc/3.1.1-2.png',
                      'ssc-gateway.exe -service-remove',
                      '/ssc/3.1.1-3.png',
                      '比如修改端口号为8080，（修改红色数字）',
                      'ssc-gateway.exe -service-install -server-port=8080',
                      '/ssc/3.1.1-4.png',
                      '启动服务',
                      'ssc-gateway.exe -service-start',
                      '/ssc/3.1.1-5.png',
                      '5.修改前端页面服务器地址',
                      '按 Ctrl+S ，或者在左上角“设置”打开“修改服务地址”',
                      '填写 localhost:8080',
                      '点击确认修改',
                      '/ssc/3.1.1-6.png',
                  ]},
                {class: 3, id: 't3-1-2', text: '3.1.2 Linux'},
                {class: 9, text: [
                      '1.在安装前打开 install.sh',
                      '将默认18080修改成想要配置的端口号',
                      '/ssc/3.1.2-1.png',
                      '保存修改',
                      '执行安装脚本 ，sh install.sh',
                  ]},

                {class: 2, id: 't3-2', text: '3.2 电脑安装了杀毒软件，检测出蓝光网关有自启动服务，能否将其清理并关闭？'},
                {class: 9, text: ['无需关闭。蓝光网关后台的自启动服务是为了实现数据自动传输，若关闭了自启动服务则网关的同步管理功能会有故障，所以建议将蓝光网关加入开机自启动的白名单，无需清理关闭其自启动功能。']},

                {class: 2, id: 't3-3', text: '3.3 Linux系统下如何开放端口和防火墙？'},
                {class: 9, text: [
                    'linux端口及防火墙开放说明',
                    'Centos 7 ：',
                    'firewall-cmd --zone=public --add-port=18080/tcp --permanent   # 开放18080端口',
                    'firewall-cmd --reload   # 配置立即生效',
                    'systemctl stop firewalld.service  # 关闭防火墙',
                    'Ubuntu 20.04 :', 'sudo ufw allow 18080  # 开放18080端口',
                    'sudo ufw reload   # 重启防火墙',
                    'sudo ufw disable   # 关闭防火墙',
                      'redhat 6.9：',
                      'sudo iptables -I INPUT -p tcp -m tcp --dport 18080 -j ACCEPT  # 开放18080端口',
                      'sudo service iptables save  # 保存防火墙设置',
                      'sudo service iptables restart  # 重启防火墙'
                  ]},

                {class: 2, id: 't3-4', text: '3.4 数据库信息误删了怎么办？'},
                {class: 9, text: ['蓝光网关每个自然日（0点）执行一次备份操作，将网关数据库信息gateway.db备份到windows C:\\\\Users\n' +
                  'linux /usr/目录下，以防误删数据库信息后无法恢复。']},

                {class: 2, id: 't3-5', text: '3.5 如何修改蓝光网关登录页密码？'},
                {class: 9, text: ['点击页面左上角【设置】，点击【修改密码】，依据对应提示修改密码即可。修改密码成功后将立即退出该账号打开登录页面，此时可重新登录新账号使用。', '请注意：原密码为当前登录的密码；新密码限制为英文、数字、特殊字符（ . @$!%*#_~?&^ ）组成，长度10-16字符。']},
            ],
            menus: [],
            timeout: null,
            active: '1',
        }
    },
    created() {
        this.menus = this.docu.filter(i => i.class < 5)
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
.ssc {
    background: #F7F7F7;
}

.title {
    height: 88px;
    line-height: 102px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid #E9EDF9;
    text-indent: 36px;
}

.active {
    color: #0f70ff !important;
}

.ssc-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 220px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px 2px #F0F2F5;
    text-align: left;
    overflow: auto;

    .menu2, .menu1 {
        cursor: pointer;
    }

    .menu1 {
        line-height: 60px;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        text-indent: 34px;
    }

    .menu2 {
        line-height: 46px;
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        text-indent: 46px;
    }
    .menu3 {
      line-height: 46px;
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      text-indent: 58px;
    }
}

.ssc-right {
    width: 320px;
    background: #FFFFFF;
    box-shadow: 1px 1px 4px 0px #F0F2F5;
    border-radius: 2px;
    position: fixed;
    top: 14px;
    bottom: 0;
    right: 16px;
    text-align: center;

    .img {
        margin: 89px auto 16px;
    }

    .text {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
    }

    .btn {
        margin: 22px auto;
        width: 92px;
        height: 32px;
        line-height: 32px;
        background: #E2757A;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 500;

        > a {
            color: #ffffff;
            display: block;
        }
    }

    .version {
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        position: absolute;
        bottom: 22px;
        left: 0;
        right: 0;
    }
}

.ssc-content {
    margin: 14px 351px 0 235px;
    background: #FFFFFF;
    box-shadow: 1px 1px 4px 0px #F0F2F5;
    border-radius: 2px;
    text-align: left;
    height: auto;

    .title, .title1, .title2, .title3, .content, .example {
        padding-left: 50px;
    }

    .title {
        height: 74px;
        line-height: 74px;
        text-indent: 0;
    }

    .title1 {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        height: 80px;
        line-height: 80px;
    }

    .title2 {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        height: 42px;
        line-height: 42px;
    }

    .title3 {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        height: 42px;
        line-height: 42px;
    }

    .content {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        padding-right: 50px;
        p {
            line-height: 27px;
        }
    }

    .example {
        margin-top: 14px;

        > .el-image {
            max-width: 80%;
        }
    }
}
</style>