import Vue from 'vue'
import service from '@/utils/request'
Vue.prototype.$get = service.get
Vue.prototype.$post = service.post
Vue.prototype.$postJson = service.postJson
Vue.prototype.$deleteJson = service.deleteJson
Vue.prototype.$putJson = service.putJson
Vue.prototype.$userGet = service.userGet
Vue.prototype.$userPost = service.userPost
Vue.prototype.$download = service.download
