<template>
    <el-container >
        <CsHeader v-if="showLayout" ></CsHeader>
        <div class="cs-main" >
            <CsAside  v-if="showLayout" ></CsAside>
            <el-main class="cs-page el___main" style="position: relative;">
                <router-view />
            </el-main>
        </div>

        <MessageHistory />
    </el-container>
</template>
<script>
import CsHeader from "./CsHeader";
import CsAside from "./CsAside";
import CmHeader from "./CmHeader";
import vm from '@/plugin/eventUtil'
import {getUnreadCount} from "@/utils/message";
import storage from "@/utils/storage";
import router from "@/router";
import MessageHistory from "@/components/MessageHistory";

export default {
    name: "Layout",
    components: {
        CsHeader,
        CsAside,
        CmHeader,
        MessageHistory
    },
    computed: {
        showLayout () {

            const { path } = this.$route

            return path.indexOf('login') === -1
        }
    },
    data () {
        return {
            system: '',
            inteval: null
        }
    },
    created() {
        console.log(this.$route)
        this.getPublicNetSwitch();
        let regionId = storage.get('REGION_ID')
        if (!regionId) {
            router.push({path: '/login', params: {message: '登录信息失效，请重新登录'}})
        }
    },
    mounted() {

        this.getUserInfo()
        this.system = this.$env
        this.$dropdown.init()
        // this.getInfo()
// 全局注册点击事件
        document.addEventListener('click', () => {
            vm.$emit('body-click')
        })
    },
    beforeDestroy() {
        clearInterval(this.inteval)
    },
    methods: {
        getPublicNetSwitch () {
            this.$get('/login/publicNetSwitch')
            .then(res => {
                if (res.code === '200') {
                    this.$store.commit('user/SET_PUBLIC_NET_SWITCH', res.data)
                }
            })
        },
        getUserInfo () {
            this.$get('/accountUser/getCurrentAccountUser')
                .then(res => {
                    if (res.code === '200') {
                        this.$store.commit('user/SET_USER_INFO', res.data)
                    }
                })
        },
        getConfig (accountId) {
            let data = {accountId}
            this.$postJson('/user/config/get', data)
                .then(res => {
                    if (res.code === '200') {
                        this.$store.commit('user/SET_RESTOREREMINDSTATUS', res.data.restoreRemindStatus)
                    }
                })
        },
    }
};
</script>
<style lang="scss">
.el-container {
    text-align: center;
    color: #2c3e50;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;

    .el-dialog__body {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.el-header {
    flex-grow: 0;
    flex-shrink: 0;
}

.cs-main {
    width: 100%;
    height: calc(100% - 54px);
    min-height: 600px;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    //padding-top: 50px;
}

.cs-page {
    width: 100%;
    height: calc(100vh - 60px);
    flex-shrink: 1;
    flex-grow: 1;
    background: #F7F7F7;
    padding: 0px;
}

.crumb {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>
