<template>
  <span class="file-name" :class="[directory ? 'file-folder' : '']">
<!--    <img src="../../assets/images/file-folder.png" width="16px" height="20px" class="file-icon" v-if="directory" />-->
    <i class=" file-icon icon iconfont iconwenjianjia"  style="color: #FAAD14;font-size: 15px;" v-if="directory" />
    <img src="../../assets/images/file-doc.png" width="16px" height="20px" class="file-icon" v-else-if="ext == 'doc' || ext == 'docx'" />
    <img src="../../assets/images/file-xls.png" width="16px" height="20px" class="file-icon" v-else-if="ext == 'xls' || ext == 'xlsx'" />
    <img src="../../assets/images/file-ppt.png" width="16px" height="20px" class="file-icon" v-else-if="ext == 'ppt' || ext == 'pptx'" />
    <img src="../../assets/images/file-pdf.png" width="16px" height="20px" class="file-icon" v-else-if="ext == 'pdf'" />
    <img src="../../assets/images/file-zip.png" width="16px" height="20px" class="file-icon" v-else-if="ext == 'zip' || ext == 'rar' || ext == 'gz'" />
    <img src="../../assets/images/file-img.png" width="16px" height="20px" class="file-icon" v-else-if="ext == 'png' || ext == 'jpg' || ext == 'jpeg'" />
    <img src="../../assets/images/file-other.png" width="16px" height="20px" class="file-icon" v-else />
    <span class="name ellipsis" style="cursor: pointer;color: #006bff;">{{ filename }}</span>
  </span>
</template>

<script>
  export default {
    name: "FileName",
    props: {
      filename: {
        type: String,
        default: "",
      },
      directory:{
        type:Boolean,
        default: false
      }
    },
    computed: {
      ext() {
        const { filename } = this;
        const index = filename.lastIndexOf(".");
        if (index > 0) {
          return filename.substr(index + 1);
        }
        return "";
      },
    },
  };
</script>

<style scoped lang="scss">
  .file-name{
    position: relative;
  }
  .file-folder {
    cursor: pointer;
  }
  .file-folder:hover {
    color: #337dff;
  }
  .file-icon {
    position: absolute;
    top:50%;
    margin-top: -10px;
    flex-shrink: 0;
    width: 17px;
    margin-right: 5px;
  }
  .name{
    padding-left: 23px;
  }
</style>
