<template>
    <div class="ut-view" v-draggable id="utView">
        <div class="ut-status" @click.stop="showList" @mousedown.stop="()=>{}" v-show="isShowEntrance">
            <!-- <div class="ut-progress" :style="{width:`${progressStyle}`}"></div> -->
            <span class="ut-num">
                <span class="upload-btn-aside-part" >
<!--                    <img class="upload-btn-aside" src="../../assets/images/file-upload-aside.png"/>-->
                    <i class="icon iconfont icontubiao" style="font-size: 13px;" />&nbsp;&nbsp;&nbsp;
                    <span style="padding-left: 5px;transform: translateY(-2px);">上传列表 <span style="padding: 2px;"></span>（成功{{ files.filter(i => i.status === '3').length }}个
                        失败{{ files.filter(i => i.status === '4').length }}个
                        上传中{{ files.filter(i => i.status === '1'||i.status === '0').length }}个）</span>
                </span>
            </span>
        </div>

        <el-dialog title="上传任务" custom-class="task-dialog" append-to-body :close-on-click-modal="false" :modal-append-to-body="false"
                   :visible.sync="showDialog" width="1000px" style="min-height: 528px;">
            <div class="task-opts">
                <!--<el-button type="primary" size="small" @click="selectFiles">继续上传</el-button>-->
                <el-button size="small" type="primary" class="_create" :disabled="removableList.length <= 0" @click="deleteUploaded">移除已完成</el-button>
                <el-button size="small" :disabled="cancelableList.length <= 0" @click="cancelAllConfirm">取消上传</el-button>
            </div>
            <el-alert class="alert" style="margin: 10px 0;" title="文件上传过程中，请勿刷新或关闭页面，否则上传任务会被中断且列表会被清空。" type="warning" show-icon :closable="false"></el-alert>
            <el-table :data="files" height="400px" style="width:880px;max-width:880px;max-height:400px;margin:0px 0" header-cell-class-name="task-cell">
                <el-table-column label="文件名称">
                    <FileName slot-scope="{row}" :filename="row.name"></FileName>
                </el-table-column>
                <el-table-column label="文件大小" width="260px">
                    <template slot-scope="{row}">{{row.size|dsFormat}}</template>
                </el-table-column>
                <el-table-column label="状态" width="300px">
                    <template slot-scope="{row}">
                        <span v-if="row.status === STATUS_UPLOADING" style="width: 180px;">
                            <el-progress :percentage="parseInt(row.uploadedPercent)"></el-progress>
                        </span>
                        <span v-else-if="row.status === STATUS_UPLOAD_FAIL">
                            <span class="status_error">
                                {{statusMap[row.status]}}
                                <el-tooltip :content="`失败原因：${row.failReason}`" placement="top" effect="light">
                                  <i class="tip el-icon-warning"></i>
                                </el-tooltip>
                            </span>
                        </span>
                        <span v-else-if="row.status === STATUS_UPLOADED" class="status_success">{{statusMap[row.status]}}</span>
                        <span v-else-if="row.status === STATUS_UPLOADING_CACHING" class="status_cache">{{statusMap[row.status]}}</span>
                        <span v-else>{{statusMap[row.status]}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="86px">
                    <template slot-scope="{$index,row}">
                        <div style="display: flex; justify-content: flex-start;align-items: center;">
                          <el-button type="text" @click="retryOne($index,row)" style="min-width: 20px;"
                                     v-if="row.status === STATUS_UPLOAD_FAIL">重试</el-button>
                              <span class="line2" v-if="row.status === STATUS_UPLOAD_FAIL" style="height: 12px;"></span>
                          <el-button type="text" v-if="row.status === STATUS_UPLOADING" style="min-width: 20px;"
                                     @click="confirmCancelOne($index,row)">取消</el-button>
                          <el-button type="text" v-else @click="deleteOne($index,row)" style="min-width: 20px;">移除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
<!--            <Pager class="bs-pager" v-model="pager" style="width:960px;margin:0px 0"-->
<!--              :sizes="[5, 10, 20, 50, 100]"-->
<!--              @currentChange="handleCurrentChange"-->
<!--              @sizeChange="handleSizeChange"></Pager>-->
        </el-dialog>
        <input multiple="multiple"  type="file" style="display: none;" ref="fileInput" @change="selectFilesChange"></input>
    </div>
</template>

<script>
import UploadFile, {
  STATUS_WAIT_UPLOAD,
  STATUS_UPLOADING,
  STATUS_UPLOADED,
  STATUS_UPLOADING_CACHING,
  STATUS_UPLOAD_FAIL,
  STATUS_UPLOAD_CANCEL,
} from "./uploadFile";
import { S3 } from "@/utils/aws";
import store from "@/store";
const MAX_UPLOAD_FILES_COUNT = 1
export default {
  name: "UploadTask",
  data: () => {
    return {
        isShowEntrance: true,
      showDialog: false,
      uploading: false,
      uploadListenerList: [],
      files: [],
      STATUS_WAIT_UPLOAD,
      STATUS_UPLOADING,
      STATUS_UPLOADING_CACHING,
      STATUS_UPLOADED,
      STATUS_UPLOAD_FAIL,
      STATUS_UPLOAD_CANCEL,
      statusMap: {
        [STATUS_WAIT_UPLOAD]: "等待上传",
        [STATUS_UPLOADING]: "上传中",
        [STATUS_UPLOADING_CACHING]: "缓存中",
        [STATUS_UPLOADED]: "上传成功",
        [STATUS_UPLOAD_FAIL]: "上传失败",
        [STATUS_UPLOAD_CANCEL]: "取消上传",
      },
      pager: {
        current: 1,
        size: 10,
        total: 0,
      },
        host: '',
        ak: '',
        sk: ''
    };
  },
  computed: {
    uploadingList() {
      const status = [STATUS_UPLOADING];
      let list = this.files.filter((item) => status.indexOf(item.status) > -1);
      return list;
    },
    removableList(){
      const status = [STATUS_UPLOADED, STATUS_UPLOAD_CANCEL];
      let list = this.files.filter((item) => status.indexOf(item.status) > -1);
      return list;
    },
    cancelableList(){
      const status = [STATUS_UPLOADING];
      let list = this.files.filter((item) => status.indexOf(item.status) > -1);
      return list;
    },
    waitingList() {
      const status = [STATUS_WAIT_UPLOAD];
      let list = this.files.filter((item) => status.indexOf(item.status) > -1);
      return list;
    },
    fileTotal() {
      return this.files.length;
    },
    fileTotalLength() {
      let total = 0;
      const status = [
        STATUS_WAIT_UPLOAD,
        STATUS_UPLOADING,
        STATUS_UPLOADING_CACHING,
      ];
      let needUploadList = this.files.filter(
        (item) => status.indexOf(item.status) > -1
      );
      for (let i = 0; i < needUploadList.length; i++) {
        total = total + needUploadList[i].size;
      }
      return total;
    },
    uploadedTotal() {
      const status = [
        STATUS_WAIT_UPLOAD,
        STATUS_UPLOADING,
        STATUS_UPLOADING_CACHING,
      ];
      let needUploadList = this.files.filter(
        (item) => status.indexOf(item.status) > -1
      );
      return needUploadList.length;
    },
    uploadingTotalLength() {
      let total = 0;
      const status = [STATUS_UPLOADING];
      let uploadingList = this.files.filter(
        (item) => status.indexOf(item.status) > -1
      );
      for (let i = 0; i < uploadingList.length; i++) {
        total = total + uploadingList[i].uploaded;
      }
      return total;
    },
    progressStyle() {
      const { fileTotalLength, uploadingTotalLength } = this;
      if (fileTotalLength === 0) {
        return `0%`;
      }
      const ratio = (uploadingTotalLength / fileTotalLength) * 100;
      return `${ratio}%`;
    },
  },
  created() {
    const _this = this;
    window.onbeforeunload = function (e) {
      e = e || window.event;
      if (_this.uploading) {
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = "文件正在上传，重新加载会丢失正在上传的文件。";
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return "文件正在上传，重新加载会丢失正在上传的文件。";
      }
    };
    window.onunload = function (e) {
        _this.cancelAll()
    }
    window.registerEventListener("onFileUploaded", this.onFileUploaded.bind(this));
    window.registerEventListener("onUploadFail", this.onUploadFail.bind(this));
    this.s3 = null;
  },
  beforeDestroy() {
    window.onbeforeunload = null;
    window.onunload = null;
  },
  methods: {
      setIsShowEntrance (isShowEntrance) {
          this.isShowEntrance = isShowEntrance
      },
    getS3Client({xskyS3Host, accessKey, secretKey}){
        return new S3(xskyS3Host, accessKey, secretKey)
    },
    registerUploadListener(listener) {
      this.uploadListenerList.push(listener);
    },
    removeUploadListener(listener) {
     this.uploadListenerList.remove(listener);
    },
    push(fileList) {
      const _this = this;
      const { files } = this;
      for (let file of fileList) {
        let key = file.parent
          ? `${file.parent}/${file.fullPath}`
          : `${file.fullPath}`;
        if (file.type === "dir") {
          key = `${key}/`;
          this.createDir(file.bucket, key, file);
          continue;
        }
        const uploadFile = new UploadFile(file, files.length);
        this.files.push(uploadFile);
      }
      this.beginUpload();
    },
    onFileUploaded() {
      if(this.uploadingList.length === 0 && this.waitingList.length === 0){
          this.uploading = false;
      }
      this.moveUploadFile();
      this.notifyListener();
    },
    onUploadFail() {
      if(this.uploadingList.length === 0 && this.waitingList.length === 0){
          this.uploading = false;
      }
      this.moveUploadFile();
    },
    showList() {
      this.showDialog = true;
    },
      closeList () {
          this.showDialog = false
      },
    retryOne(index) {
      const { files } = this;
      const uploadFile = files[index];
      if (uploadFile) {
        uploadFile.reUpload();
      }
    },
    beginUpload() {
      this.uploading = true;
      this.moveUploadFile();
    },
    moveUploadFile() {
      if (this.waitingList.length <= 0) {
        return;
      }
      const { files, uploadingList} = this;
      let uploadTotal = uploadingList.length;
      for (let i = 0; i < files.length; i++) {
        if (files[i].status === STATUS_WAIT_UPLOAD) {
          if (uploadTotal < MAX_UPLOAD_FILES_COUNT) {
            files[i].upload();
            uploadTotal = uploadTotal + 1;
            break;
          }
        }
      }
      if (uploadTotal >= MAX_UPLOAD_FILES_COUNT) {
        return;
      } else {
        this.moveUploadFile();
      }
    },
    createDir(bucket, key, file) {
      const data = { bucket, key };
      this.getS3Client(file).createDir(data);
    },
    notifyListener() {
      const { uploadListenerList } = this;
      if (uploadListenerList && uploadListenerList.length > 0) {
        uploadListenerList.forEach((listener) => {
          listener && listener();
        });
      }
    },
    confirmCancelOne(index, row) {
      this.$confirm("此操作会将正在上传的任务取消， 是否继续?", "提示", {
        confirmButtonText: "继续",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.cancelOne(index, row);
        })
        .catch(() => {});
    },
    cancelAllConfirm() {
      // this.cancelAll();
      this.$confirm(
        "此操作会将等待上传的和正在上传的任务全部取消， 是否继续?",
        "提示",
        {
          confirmButtonText: "继续",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.cancelAll();
        })
        .catch(() => {});
    },
    cancelOne(index, task) {
      if (task.status === STATUS_UPLOADING) {
        task.cancel();
        task.status = STATUS_UPLOAD_CANCEL;
      } else if (task.status === STATUS_WAIT_UPLOAD) {
        task.cancel();
        task.status = STATUS_UPLOAD_CANCEL;
      }
      if(this.uploadingList.length == 0 && this.waitingList.length == 0){
        this.uploading = false;
      }
      this.moveUploadFile()
    },
    cancelAll() {
      const { files } = this;
      for (let i = 0; i < files.length; i++) {
        if (files[i].status === STATUS_UPLOADING) {
          files[i].cancel();
          files[i].status = STATUS_UPLOAD_CANCEL;
        } else if (files[i].status === STATUS_WAIT_UPLOAD) {
          files[i].cancel();
          files[i].status = STATUS_UPLOAD_CANCEL;
        }
      }
      this.uploading = false;
      this.moveUploadFile()
    },
    deleteUploaded() {
      this.files.removeIf(item => [STATUS_UPLOADED,STATUS_UPLOAD_CANCEL].indexOf(item.status) > -1)
      this.$forceUpdate()
    },
    deleteOne(index, row) {
      this.files.splice(index, 1);
      this.$forceUpdate()
    },
    selectFiles(){
      this.$refs['fileInput'].click()
    },
    selectFilesChange(event){
      const { target: { files }} = event;
      if (files && files.length > 0) {
        if (files.length > 100) {
          this.$message.error("文件个数超过100个，请使用SDK上传");
          return;
        }
        const uploadFiles = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          // uploadFiles.push({
          //   name: file.name,
          //   fullPath: file.fullPath,
          //   size: file.size,
          //   type: file.type,
          //   bucket: bucket,
          //   parent: fullPath,
          //   raw: file,
          // });
        }
        // this.push(uploadFiles)
        this.$refs.uploadInput.value = ""; //虽然file的value不能设为有字符的值，但是可以设置为空值
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ut-view {
  position: absolute;
  top: 40px;
    right: 45px;

}
.task-dialog {
  ::v-deep .cell {
    padding: 0 10px!important;
  }
}
.task-cell {
  padding: 0 10px;
}
.ut-status {
  position: relative;
  //width: 400px;
  height: 30px;
  line-height: 30px;
  text-align: right;
  cursor: pointer;
  z-index: 1900;
}
.ut-num {
  position: relative;
  font-size: 12px;
  color: #555555;
  z-index: 111;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.upload-btn-aside-part{
  display: flex;
  align-items: center;
  line-height: initial;
  margin-left: 4px;
    color: #7486D9;
    justify-content: flex-end;
}
.task-opts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}
::v-deep.el-dialog__wrapper{
    .el-dialog{
        min-height: 528px;
    }
}
.status_success{
    color: #10C038;
}
.status_cache{
    color: #337dff;
}
.status_error{
  color: #f04134;
  .tip{
      margin-left: 10px;
      font-size: 12px;
      color: #ff931d;
      cursor: pointer;
  }
}
.upload-btn-aside{
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-bottom: 1px;
}
</style>
