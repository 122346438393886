<template>
    <div @click.stop :class="{'dropdown': true, show: showDrop, hidden: !showDrop}" :style="`left: ${left}px;top: ${top}px`">
        <div v-for="item in list" class="item" @click="handleClick(item.value)">{{ item.text }}</div>
<!--        <div class="item" @click="handleClick('url')">生成URL</div>-->
<!--        <div class="item" @click="handleClick('history')">历史版本</div>-->
    </div>
</template>

<script>
import vm from '@/plugin/eventUtil'

const actions = {
    acl: {value: 'acl', text: '设置读写权限'},
    url: {value: 'url', text: '生成URL'},
    history: {value: 'history', text: '历史版本'}
}
export default {
    name: "dropdown",
    data () {
        return {
            left: -100,
            top: -100,
            showDrop: false,
            actionId: '',
            list: [
                {value: 'acl', text: '设置读写权限'},
                {value: 'url', text: '生成URL'},
                {value: 'history', text: '历史版本'}
            ]
        }
    },
    mounted() {
        vm.$on('body-click', () => {
            this.showDrop = false
        })
    },
    methods: {
        show () {
            this.showDrop =  true
        },
        toggle (top, left, id, list = []) {
            this.left = left - 30
            this.top = top + 30
            this.showDrop = !this.showDrop
            this.actionId = id
            if (list.length > 0) {
                this.list = list.map(i => actions[i])
            } else {
                this.list = [
                    {value: 'acl', text: '设置读写权限'},
                    {value: 'url', text: '生成URL'},
                    {value: 'history', text: '历史版本'}
                ]
            }
        },
        handleClick (action) {
            vm.$emit('dropdown-select', this.actionId, action)
            this.showDrop = false
        }
    }
}
</script>

<style scoped>
.dropdown {
    position: fixed;
    background: #fff;
    padding: 4px 0;
    width: 94px;
    box-shadow: 0px 0px 16px rgba(0,0,0,0.08);
    z-index: 5;
}

.dropdown .item {
    height: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
}

.dropdown .item:active {
    background: #FFF8EF;
}
.dropdown .item:hover {
    background: #FFF8EF;
}



@keyframes slide-down{
    0%{transform:scale(1,0);}
    100%{transform:scale(1,1);}
}

@-webkit-keyframes slide-down{
    0%{-webkit-transform:scale(1,0);}
    100%{-webkit-transform:scale(1,1);}
}

.show {
    max-height: 104px;
    transition: max-height .2s ease-in;
    transform-origin: 50% 0;
    animation: slide-down 0.2s ease-in;
    -webkit-animation: slide-down 0.2s ease-in;
}

.hidden {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    transition: max-height .2s ease-out;
}
</style>