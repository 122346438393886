import store from "@/store";
import Vue from "vue";

export const getUnreadCount = () => {
    // /api/message/unreadCount
    Vue.prototype.$postJson('/message/unreadCount')
        .then(res => {
            if (res.code === '200') {
                store.commit('user/SET_UNREAD_MESSAGE', res.data)
            }
        })

}