const STORAGE_KEY = "PRIVATE_BLUESTORE";
/**
 * 设置数据
 * @param {String} key 键值
 * @param {String} value value值，支持字符串和json对象
 */
export const set = (key, value) => {
  if (typeof value !== "string") {
    value = JSON.stringify(value);
  }
  localStorage.setItem(`${STORAGE_KEY}_${key.toUpperCase()}`, value);
};

/**
 * 获取数据
 * @param {String} key 键值
 * @param {String} format 返回数据格式化方式 默认string-字符串 number-数字 json-JSON对象
 */
export const get = (key, format = "string") => {
  const res = localStorage.getItem(`${STORAGE_KEY}_${key.toUpperCase()}`);
  if (res == null) {
    return res;
  } else {
    switch (format) {
      case "number":
        return Number(res);
      case "json":
        try {
          return JSON.parse(res);
        } catch (error) {
          return res;
        }
      default:
        return res;
    }
  }
};

/**
 * 移除数据
 * @param {String} key 键值
 */
export const remove = key => {
  localStorage.removeItem(`${STORAGE_KEY}_${key.toUpperCase()}`);
};

/**
 * 清空数据

 */
export const clear = key => {
  sessionStorage.clear();
  localStorage.clear();
};

/**
 * 设置token
 * @param {String} token
 */
export const setToken = token => {
  set("TOKEN", token);
};

/**
 * 获取token
 */
export const getToken = () => {
  return get("TOKEN");
};



export default {
  set,
  get,
  remove,
  clear,
  setToken,
  getToken,
};
