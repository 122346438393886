/**
 *     ONLINE('上线'),
 *     ONLINEING('申请上线'),
 *     OFFLINE('离线'),
 *     OFFLINEING('申请离线');
 * */

let  RfStatusList;
(function (RfStatusList) {
    RfStatusList[RfStatusList['ONLINE'] = '上线'] = 'ONLINE';
    RfStatusList[RfStatusList['ONLINEING'] = '上线中'] = 'ONLINEING';
    RfStatusList[RfStatusList['OFFLINE'] = '离线'] = 'OFFLINE';
    RfStatusList[RfStatusList['OFFLINEING'] = '离线中'] = 'OFFLINEING';
})(RfStatusList || (RfStatusList = {}));
export {
    RfStatusList
}